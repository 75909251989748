@import "global";

.BannerBox.discussionBanner.contactBanner {
  .sixteen-nine {
    &::before {
      background: url(../images/contactUs-Banner.jpg) center center no-repeat;
      background-size: cover;
      padding-top: 23%;
    }
  }
}

.commentBox.contactBox {
  h3 {
    font-family: $font-family;
    color: black;
  }
  .info {
    // border: 1px solid black;
    margin-left: 12rem;
    margin-right: 12rem;
    margin-bottom: 2rem;
    p {
      color: black;
      font-family: $font-family;
      text-align: justify;
      font-size: 0.8rem;
    }
    @include bp(md) {
      margin-left: 0;
      margin-right: 0;
      // margin-bottom: 0;
    }
  }
  .container {
    max-width: 95%;
    // border: 1px solid black;
  }
  h3 {
    color: $blue;
    margin-bottom: 1.25rem;
    line-height: 1.5;
    font-size: 1.5625rem;
  }
  h2 {
    font-size: 2.5rem;
    @include bp(md) {
      font-size: 1.625rem;
    }
  }
  p {
    color: #808285;
    font-size: 1.5625rem;
    @include bp(md) {
      font-size: 1.25rem;
    }
  }
  .mapBox {
    // min-height: 300px;
    // margin-bottom: 2rem;
    width: 100%;
    @include bp(md) {
      margin-top: 2.5rem;
      min-height: 300px;
    }
  }
  .supportBox {
    font-size: 2rem;
    @include bp(md) {
      font-size: 1.5rem;
    }
  }
}

.careerForm.feedBackForm.commentForm.contactForm {
  .MuiFilledInput-input {
    padding-top: 5px;
    padding-left: 1rem;
  }
  .MuiInputLabel-root {
    padding-top: 1px;
  }
}
.contactUsForm {
  // border: 1px solid black;

  .MuiFilledInput-input {
    padding-top: 25px;
  }
  .MuiInputLabel-root {
    padding-top: 1px;
  }
  .CheckButton {
    font-size: 0.8rem;
    background: #064e89;
    font-family: $font-family;
    color: white;
  }
  .CheckButton:hover {
    background: #064e89;
  }
  .textAreaInput {
    background: none;
    border: 1px solid #c3c3c3;
    height: 7rem !important;
    min-height: 7rem;
  }
}
.addressBox {
  margin-top: 2rem;
  margin-bottom: 4rem;

  .row {
    display: flex;

    justify-content: center;
    align-items: center;

    @media (min-width: 300px) and (max-width: 750px) {
      display: block;
      justify-content: left;
    }
  }
  .col {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    @media (min-width: 300px) and (max-width: 750px) {
      justify-content: flex-start;
      margin-bottom: 0.5rem;
    }
    h3 {
      color: $blue;
      font-family: $font-family;
      margin-bottom: 1rem;
      @media (min-width: 300px) and (max-width: 750px) {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  font-family: $font-family;

  @media (min-width: 300px) and (max-width: 750px) {
    padding-top: 2rem;
    margin-top: 1rem;
  }

  .textBox {
    // border: 1px solid black;

    p {
      font-size: 1rem;
      font-family: $font-family;
      @media (min-width: 300px) and (max-width: 750px) {
        font-size: 0.8rem;
        margin-bottom: -0.5rem;
      }
    }
  }
}
