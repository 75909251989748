@import "global";

//  @font-face {
//     font-family: 'gotham rounded';
//     font-weight: 600;
//     src: local('gotham rounded'), url(../fonts/gothamrounded-book.otf) format('opentype');
//   }

//   @font-face {
//       font-family: 'gotham rounded';
//       src: local('gotham rounded'), url(../fonts/gothamrounded-light.otf) format('opentype');
//   }

//   @font-face {
//       font-family: 'gotham rounded';
//       font-weight: normal;
//       src: local('gotham rounded'), url(../fonts/gothamrounded-medium.otf) format('opentype');
//   }

.SecondExample {
  // width: 500px;
  width: 100%;
  overflow: hidden;
  .CarouselItem {
    @include bp(md) {
      min-height: 468px;
    }
  }
  .Project {
    position: relative;
    height: auto;
    overflow: hidden;
    // padding: 20px;
    border-radius: 0px;
    img {
      width: 100%;
      max-width: 100%;
      position: relative;

      @include bp(md) {
        min-height: 390px;
      }
    }

    * {
      color: white;
      font-family: "gotham rounded";
    }

    // .ContentBox{
    //     position: absolute;
    //     z-index: 1;
    //     // top: 30%;
    //     // top:60%;
    //     // left: 15%;
    //     // right: 0rem;
    //     height: 100%;
    //     width: 50%;

    //     h2{font-size:$head-size;}
    //     h3{font-size:$head-size;color:#035eab;font-family:$font-family;}
    //     p{font-size:1.375rem;margin-top:1rem;font-family:$font-family;}

    //     @include bp(md) {
    //         left:5%;
    //         width:95%;
    //         top:20%;

    //         h2{font-size:1.5rem;}
    //     h3{font-size:1.5rem;color:#035eab;}
    //     p{font-size:1rem;}

    //      }

    // }

    .ContentBox {
      position: absolute;
      z-index: 1;
      // top: 30%;
      // top:60%;
      // left: 15%;
      // right: 0rem;
      height: 100%;
      width: auto;

      h2 {
        font-size: $head-size;
      }
      h3 {
        font-size: $head-size;
        color: #035eab;
        font-family: $font-family;
      }
      p {
        font-size: 1.375rem;
        margin-top: 1rem;
        font-family: $font-family;
      }

      @include bp(md) {
        left: 5%;
        width: 95%;
        top: 20%;

        h2 {
          font-size: 1.5rem;
        }
        h3 {
          font-size: 1.5rem;
          color: #035eab;
        }
        p {
          font-size: 1rem;
        }
      }
    }

    .ContentBox.slide1Content {
      top: 20%;
    }

    .CheckButton {
      margin-top: 40px;
      color: white;
      font-size: $header-font-size;
      text-transform: capitalize;
      background-image: linear-gradient(-90deg, #3933e9, #4c6ce8);
      padding: 0.7rem 2rem;
      border-radius: 35px;
      // transition: 200ms;
      font-family: "gotham rounded";

      @include bp(md) {
        padding: 0.2rem 1rem;
        font-size: 0.875rem;
        margin-top: 0rem;
      }
    }
  }

  // .Carousel-indicators-11{position:absolute;bottom:25%;z-index:2;}
  // .Carousel-indicator-12{color:$white;width:1.5rem;height:1.5rem;}
  // .Carousel-active-13{color:$blue;}

  // .Carousel-indicators-24{position:absolute;bottom:25%;z-index:2;}
  // .Carousel-indicator-25{color:$white;width:1.5rem;height:1.5rem;}
  // .Carousel-active-26{color:$blue;}
}

//

.SecondExample-1 {
  // width: 500px;
  width: 100%;
  overflow: hidden;
  margin-top: -20px;
  .CarouselItem {
    @include bp(md) {
      min-height: 468px;
    }
  }
  .carousel-indicators {
    bottom: 2.5rem;
  }

  .carousel-indicators li {
    width: 16px;
    height: 16px;
    top: 0rem;
    border-radius: 100%;
    background: $white;
  }

  .carousel-indicators .active {
    background: $blue;
  }

  .Project1 {
    margin-top: 0.2rem;
    // position: relative;
    // height: auto;
    // overflow: hidden;
    // // padding: 20px;
    // border-radius:0px;
    // img{
    //     width:100%;max-width:100%;position:relative;

    //     @include bp(md) {
    //        min-height:390px;

    //     }
    // }

    * {
      color: white;
      font-family: "gotham rounded";
    }
    z-index: 1;

    @include bp(sm) {
      // img{min-height:320px;}
      img {
        margin-top: 1rem;
      }
    }

    // .ContentBox{
    //     position: absolute;
    //     z-index: 1;
    //     // top: 30%;
    //     // top:65%;
    //     // left: 15%;
    //     left:20%;
    //     right: 0rem;
    //     height: 100%;
    //     width: 50%;
    //     text-align:left;
    //     h2{font-size:$head-size;}
    //     h3{font-size:$head-size;color:#035eab;font-family:$font-family;}
    //     p{font-size:1.375rem;margin-top:1rem;font-family:$font-family;}

    //     @include bp(md) {
    //         left:5%;
    //         width:95%;
    //         // top:20%;
    //         top:70%;
    //         // display: none;

    //     h2{font-size:1.5rem;}
    //     h3{font-size:1.5rem;color:#035eab;}
    //     p{font-size:1rem;}

    //      }

    // }

    .ContentBox {
      position: absolute;
      z-index: 1;
      left: 0rem;
      right: 0rem;
      height: 100%;
      top: 26.5rem;
      bottom: 0rem;
      width: auto;
      text-align: left;
      h2 {
        font-size: $head-size;
      }
      h3 {
        font-size: $head-size;
        color: #035eab;
        font-family: $font-family;
      }
      p {
        font-size: 1.375rem;
        margin-top: 1rem;
        font-family: $font-family;
      }

      @include bp(md) {
        left: 5%;
        width: 95%;
        // top:20%;
        top: 70%;
        // display: none;

        h2 {
          font-size: 1.5rem;
        }
        h3 {
          font-size: 1.5rem;
          color: #035eab;
        }
        p {
          font-size: 1rem;
        }
      }
    }

    .ContentBox.slide1Content {
      top: 40%;
      width: 100%;
      left: 45%;
    }
    .ContentBox.slide2Content {
      top: 21%;
      width: 100%;
      left: 3%;
      // display: none;
      .CheckButton {
        border-radius: 10px;
        background: #1b25a9;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0.2rem 0.8rem;
        box-shadow: 0px 5px 2px #3d3a81;
        margin-top: 40px;

        @include bp(md) {
          font-size: 1rem;
        }
      }
    }

    .ContentBox.slide3Content {
      top: 65%;
      width: 100%;
      left: 5%;
      .CheckButton {
        border-radius: 10px;
        background: #fcd791;
        font-size: 1.5rem;
        padding: 0.3rem 0.8rem;
        box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);
        * {
          color: #94648b;
          font-weight: bolder;
        }
        @include bp(md) {
          font-size: 1rem;
        }
      }
    }

    .ContentBox.slide6Content {
      top: 59%;
      width: 100%;
      left: 5.5rem;
      @media (min-width: 300px) and (max-width: 350px) {
        top: 50%;
        left: 0.8rem;
      }
      @media (min-width: 351px) and (max-width: 400px) {
        top: 46%;
        left: 1rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        top: 45%;
        left: 1rem;
      }
      @media (min-width: 451px) and (max-width: 500px) {
        top: 44%;
        left: 1rem;
      }
      @media (min-width: 501px) and (max-width: 600px) {
        top: 44%;
        left: 1.5rem;
      }
      @media (min-width: 601px) and (max-width: 700px) {
        top: 40%;
        left: 1.9rem;
      }
      @media (min-width: 701px) and (max-width: 800px) {
        top: 40%;
        left: 2rem;
      }
      @media (min-width: 801px) and (max-width: 960px) {
        top: 40%;
        left: 2.5rem;
      }
      @media (min-width: 961px) and (max-width: 1024px) {
        top: 51%;
        left: 3.8rem;
      }
      @media (min-width: 1025px) and (max-width: 1100px) {
        top: 52%;
        left: 4rem;
      }
      @media (min-width: 1101px) and (max-width: 1200px) {
        top: 55%;
        left: 4.5rem;
      }
      @media (min-width: 1201px) and (max-width: 1300px) {
        top: 57%;
        left: 5rem;
      }
      .CheckButton {
        border-radius: 10px;
        background: #b10011;
        font-size: 1rem;
        padding: 0rem 0.8rem;
        // box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);
        margin-top: 6rem;

        * {
          color: #fff;
          font-weight: bolder;
        }

        @include bp(sm) {
          margin-top: 0% !important;
          position: absolute;
          top: 50%;
          transform: translate(10%, -20px);
          font-size: 0.4rem  !important;
          padding: 0rem 0.8rem;
        }
        @include bp(md) {
          font-size: 0.6rem;
          margin-top: 0% !important;
          position: absolute;
          top: 50%;
          transform: translate(10%, -20px);
        }
        //  @include bp(lg) {
        //   margin-top: 5rem;
        //   font-size: 1.2rem;
        // }
      }
    }

    //  .ContentBox.slide4Content{
    //     top:14rem;
    //     width:100%;
    //     left:82%;
    //         @include bp(md){
    //             left:5%;
    //             display: none;
    //         }
    //     h6{font-family:$font-family;font-size:1.2rem;margin-top:2.3rem;}
    //     .playStore{
    //         .MuiButton-root{padding: 0.5rem 0.2rem 1.2rem; height:2rem;
    //             margin-right: 0.5rem;background:$black;border-radius:6px;
    //             img{min-height:auto;}
    //          .googleStore{font-size:0.675rem;line-height:5px;}
    //          .small{font-size:0.475rem;line-height:0.1px;}
    //          svg{font-size:1.5rem;margin-right:0.2rem;margin-top:0.5rem;}
    //         }
    //     }
    //     .CheckButton
    //     {
    //         border-radius:10px;
    //         background:$white;
    //         font-size:1.5rem;font-weight:bold;
    //         padding:0.2rem 0.8rem;
    //         box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);

    //         *{
    //             color:#FF6750;
    //         }

    //         @include bp(md){
    //             font-size:1rem;
    //         }
    //     }

    //  }

    .slide4Content {
      top: 14rem;
      // width:100%;
      background: none;
      @include bp(md) {
        left: 5%;
        display: none;
      }
      h6 {
        font-family: $font-family;
        font-size: 1.2rem;
        margin-top: 2.3rem;
      }
      .playStore {
        .MuiButton-root {
          // padding: 0.5rem 0.2rem 1.2rem;
          padding: 0.625rem 0.5rem 1.625rem;
          height: 2rem;
          margin-right: 0.5rem;
          background: $black;
          border-radius: 6px;
          img {
            min-height: auto;
          }
          //  .googleStore{font-size:0.675rem;line-height:5px;}
          .googleStore {
            font-size: 1rem;
            line-height: 5px;
            text-transform: capitalize;
          }
          .small {
            font-size: 0.475rem;
            line-height: 0.1px;
          }
          svg {
            font-size: 1.625rem;
            margin-right: 0.2rem;
            margin-top: 0.7rem;
          }
        }
      }
      .CheckButton {
        border-radius: 10px;
        background: $white;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0.2rem 0.8rem;
        box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);

        * {
          color: #ff6750;
        }

        @include bp(md) {
          font-size: 1rem;
        }
      }
    }

    //     .CheckButton
    //     {
    //         margin-top: 120px;
    //         color: white;
    //         font-size: $header-font-size;
    //         text-transform: capitalize;
    //         background-image: linear-gradient(-90deg, #3933e9, #4c6ce8);
    //         padding:0.7rem 2rem;border-radius:35px;
    //         // transition: 200ms;
    //         font-family: 'gotham rounded';

    //         @include bp(md) {
    //             padding:0.2rem 1rem;
    //             font-size:0.875rem;
    //             margin-top:0rem;
    //          }
    //         &:focus{
    //             border:none;outline:none;
    //         }
    //     }
    // }

    .CheckButton {
      color: white;
      font-size: 1.5rem;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      // background-image: linear-gradient(-90deg, #3933e9, #4c6ce8);
      background: #0e4a80;
      padding: 0rem 1rem;
      border-radius: 10px;
      text-transform: uppercase;
      box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.3);
      // transition: 200ms;
      // font-family: $font-family;

      * {
        // font-family: $font-family;
      }

      @include bp(md) {
        padding: 0.2rem 1rem;
        font-size: 0.875rem;
        margin-top: 0rem;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  // .Carousel-indicators-11{position:absolute;bottom:25%;z-index:2;}
  // .Carousel-indicator-12{color:$white;width:1.5rem;height:1.5rem;}
  // .Carousel-active-13{color:$blue;}

  // .Carousel-indicators-24{position:absolute;bottom:25%;z-index:2;}
  // .Carousel-indicator-25{color:$white;width:1.5rem;height:1.5rem;}
  // .Carousel-active-26{color:$blue;}

  .carousel-control-prev {
    z-index: 999;
    width: 5%;
  }
  .carousel-control-next {
    z-index: 999;
    width: 5%;
  }
}
