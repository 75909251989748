/*.MuiFormHelperText-root.Mui-error { 
    margin-top: -13px;
}*/

.logInBox .mainBox .MuiInputAdornment-root {
  display: inherit;
}

.signUpBtn:disabled {
  color: #ffffffc3 !important;
}

.cursor-pointer {
  cursor: pointer;
}