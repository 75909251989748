@import 'global';


.BannerBox.TrainingBox.termBoxBanner {
    .sixteen-nine{
       &::before{
        background: url(../images/termcondition.png) center center no-repeat;background-size:cover;
        padding-top: 39.25%;
       }
    }
}

.policy-text{
    text-transform: lowercase!important;
    a{
        text-transform: lowercase!important;
        font-size: 1rem;
        overflow-wrap: break-word;
    }
}

.BannerBox.privacyBanner{
    .sixteen-nine:before {  
        background:url(../images/privacy-policy.jpg) center center no-repeat;   
        padding-top: 39.25%;
        background-size:cover;  
      }
}



.termBox{
    padding-top:5rem;padding-bottom:5rem;
    background:url(../images/team.jpg) bottom center no-repeat;
    background-size:contain;
    @include bp(md){
        padding-top:2rem;
        padding-bottom:2rem;
    }
    *{
        font-family:$font-family;
        font-size:1.5rem;
       @include bp(md){
           font-size:1.125rem;
       }
    }
    h2{
        display:inline-block;
    }
    .policy-text{
        text-transform: capitalize;
    }
    ul > li{
        list-style: decimal;
        list-style-position: inside ;
        margin-top:2rem;
        &> {
            ul { counter-reset: list; li{
                
                list-style: none;
                position: relative;
                padding-left:2rem;
                &:first-of-type{
                    margin-top:0rem;
                }
                &::before{
                    counter-increment: list;
                    content: counter(list, lower-alpha) ") ";
                    position: absolute;
                    left:0rem;font-size:1.125rem;
                }
            
            }
        }
        }
    }
    p{
        color:#808285;font-size:1.125rem;  
    }
}