@import "global";
.pastWeb {
  .container {
    // border: 1px solid black;
    max-width: 98%;
    @include bp(md) {
      max-width: none;
    }
  }
}
.formControl {
  min-width: 200px;
  // margin-right: var(
  //   --theme-spacing-2
  // ); /* Adjust this as per your theme spacing */
  position: relative;
  display: flex;
  align-items: center;

  @include bp(md) {
    min-width: 90px;
    // border: 1px solid black;
  }
}

.select {
  width: 100%;
  padding: 10px 30px 10px 10px;

  border-color: #064e89;
  border-width: 2px;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: #064e89;
  @include bp(md) {
    font-size: 10px;
    padding: 10px 60px 10px 60px;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .select{
    padding: 10px 82px 10px 10px;
  }
}


.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #064e89;
  @include bp(md) {
    right: 2px;
  }
}

.filter {
  // position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  font-size: 1rem;
}
.yearFilter {
  // margin-right: 0.1rem;
  // // margin-left: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  @include bp(md) {
    margin-right: 1rem;
    margin-left: 0;
    padding: 2px;
  }

  svg {
    @include bp(md) {
      font-size: 1.2rem;
    }
  }
  span {
    margin-left: 2rem;
    text-align: center;
    @media (min-width: 300px) and (max-width: 349px) {
      font-size: 0.8rem;
      margin-left: 1rem;
    }
    @media (min-width: 350px) and (max-width: 960px) {
      font-size: 0.8rem;
      margin-left: 1.5rem;
    }
  }
}
.languageFilter {
  // margin-left: 0.6rem;

  .langSvg {
    font-size: 1.8rem;
    @include bp(md) {
      font-size: 1.4rem;
    }
  }
  svg {
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1.2rem;
    }
  }
  span {
    margin-left: 0.3rem;
    @include bp(md) {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      margin-right: 1rem;
      margin-left: 0.1rem;

      // margin-right: 2rem;
    }
  }
}
.PastTraing {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .custom-col-spacing {
    margin-bottom: -1rem;
    @include bp(md) {
      margin-bottom: 1rem;
    }
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 18px;
    padding-left: 3.8rem;
    margin-top: 3rem;
    @include bp(md) {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 2rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
    @include bp(sm) {
      margin-top: 1rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 95%;
    @include bp(md) {
      width: 100%;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;
      // border: 1px solid black;
      // background-color: #ececec;
      margin-top: 1.3rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }

      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;
        @media (min-width: 300px) and (max-width: 349px) {
          width: 70px;
          height: 70px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }
        @include bp(md) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 48px;
        font-size: 0.9rem;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 400px) {
          font-size: 0.7rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 0.2rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.8rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    @include bp(md) {
      font-size: 0.8rem;
    }
    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;
      height: 3.5em;
      padding-right: 2px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.8rem;
        width: 5%;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
        // padding: 2px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 500px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
  }

  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      left: 24vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 350px) and (max-width: 359px) {
        padding: 0rem;
        margin-left: 5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 360px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        // padding: 0rem;
        margin-left: 50%;
        // font-size: 0rem;
        // margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: 55%;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.5rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 6.8rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
    }
  }

  .boxFeedback {
    margin-top: 1rem;
    left: 0;
    right: 0;
    .readmore {
      margin: -2rem auto 4rem auto;
      margin-left: 5rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: white;
      color: $black;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.75rem;
        padding: 0.2rem 1rem;
        margin-left: 2rem;
      }
    }
    .CheckButton {
      margin: -2rem auto 4rem auto;
      margin-right: 3rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: #035eab;
      color: $white;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.8rem;
        padding: 0.2rem 1rem;
        margin-right: 2rem;
      }
    }
  }
}

.Blogbox.careerBox.scheduleBox {
  .headBox {
    h2 {
      font-size: 2.125rem;
      @include bp(md) {
        font-size: 1.75rem;
      }
    }
  }
  .CheckButton {
    margin-top: 4rem;
    font-size: 1.25rem;
    svg {
      font-size: 32px;
    }

    @include bp(sm) {
      font-size: 1rem;
      svg {
        font-size: 36px;
      }
    }

    @include bp(md) {
      margin-bottom: 2rem;
      margin-top: 0rem;
    }
  }
  .checkBtnSchedule {
    font-size: 1.5rem;
    border-radius: 45px;
    padding: 0.7rem 3rem;
    @include bp(md) {
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @include bp(sm) {
      font-size: 1.25rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}
