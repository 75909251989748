
@import 'style';    
*{margin:0rem;padding:0rem;}
body{line-height:1.5; font-family:'gotham rounded';

   
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color:#414042; font-family:'gotham rounded';
}


}
h1, h2, h3, h4, h5{color:#414042; font-family:'gotham rounded' }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color:#414042; font-family:'gotham rounded';
}

p{font-family:'gotham rounded'}
button:focus{outline:none;}
ul li {list-style:none;}
a:hover{text-decoration:none;color:inherit;}
a{color:inherit;}   
.link{color:inherit;}
.mainAll{
  a:hover{text-decoration:none;color:inherit;}
a{color:inherit; text-decoration:none;}  
button:focus{outline:none;border:none;}
textarea:focus{outline:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #ebebeb inset;
  border-radius:40px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}

}
textarea:focus{outline:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #ebebeb inset;
  border-radius:40px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}

.noFound{
  margin-top:8rem;
}

.footerForm{
  textarea:focus{outline:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: none;
  border-radius:0px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}

.MuiInputBase-input{
  color: #414042;
  font-family: "gotham rounded medium";    
  
}
.textAreaBox{
  color: #414042;
  font-family: "gotham rounded medium";         
}

::-webkit-input-placeholder { /* WebKit browsers */
 
   opacity: 1 !important;
}
::-moz-input-placeholder { /* WebKit browsers */
 
  opacity: 1 !important;
}


::-webkit-textarea-placeholder { /* WebKit browsers */
 
  opacity: 1 !important;
}
::-moz-textarea-placeholder { /* WebKit browsers */

 opacity: 1 !important;
}

// ::placeholder {
//   color: red !important;
// }

.MuiOutlinedInput-input {
  padding: 12.5px 14px;
}
   
}


.createThread{
  textarea:focus{outline:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: none;
  border-radius:0px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}
}


@font-face {
    font-family: 'gotham rounded';  
    src: local('gotham rounded'), url(../fonts/gothamrounded-light.otf) format('opentype');
}

@font-face {
          font-family: 'gotham rounded medium';
          font-weight: normal;
          src: local('gotham rounded medium'), url(../fonts/gothamrounded-medium.otf) format('opentype');
      }


$blue: #035eab;     
$blue-hover: rgb(63, 97, 189);
$white-blue: rgb(90, 123, 212);
$whiter-blue: rgb(109, 136, 216);

$grey-blue: #557a95;
$white:#fff;  
$menucolor:#1c629d;
$fontColor:#363636;
$h2color:#414042;
$black:#000000;
$light-blue:#3e46e9;
$font-family:'gotham rounded medium'; 
$head-size:2.25rem;
$head-small:1.75rem;
$head-md:2.125rem;

$mui-primary: #3f51b5;
$mui-secondary: #f50057;

$orange: orange;

$red: rgb(196, 37, 37);
$red-dim: rgb(207, 71, 71);
$red-hover: rgb(177, 33, 33);
$red-active: rgb(158, 29, 29);

$dark-red: rgb(119, 24, 24);
$dark-red-hover: rgb(104, 20, 20);
$dark-red-active: rgb(87, 17, 17);

$green: #159c2b;

$not-white: rgb(250, 250, 250);
$almost-white: rgb(241, 241, 241);
$basically-white: rgb(235, 235, 235);
$less-white: rgb(230, 230, 230);
$lesser-white: rgb(216, 216, 216);
$grey: rgb(175, 175, 175);
$graphite-hover: rgb(87, 87, 87);
$graphite: rgb(73, 73, 73);

$font: Ubuntu, Helvetica, Arial, sans-serif;

$smaller-font-size: 12px;
$small-font-size: 15px;
$normal-font-size: 18px;
$bigger-font-size: 21px;
$header-font-size: 1rem;
$big-header-font-size: 30px;
$huge-font-size: 40px;





@mixin bp($point) {
  
    // $bp-sm: "(max-width: 575px) ";
    // $bp-md: "(max-width: 768px)";
    // $bp-lg: "(max-width: 992px)"; 
    // $bp-xl: "(max-width: 1200px)";    
    
    
    $bp-sm: "(max-width: 600px) ";
    $bp-md: "(max-width: 960px)";
    $bp-lg: "(max-width: 1280px)"; 
    $bp-lg16: "(min-width: 1500px)"; 
  
    @if $point == sm {
      @media #{$bp-sm} { @content; }
    }
    @else if $point == md {
      @media #{$bp-md} { @content; }
    }
    @else if $point == lg {
      @media #{$bp-lg}  { @content; }      
    }
    @else if $point == lg16 {
      @media #{$bp-lg16}  { @content; }      
    }
    @else if $point == xl {
        @media #{$bp-xl}  { @content; }         
      }
  
  }



//   @include bp(md) {  
//     padding-top: 60px;
//     padding-bottom: 40px;           
//     h2{
//        font-size:1.5rem;
//     }  
   
//  }


