@import "global";

.BannerBox.discussionBanner.faqBanner {
  .sixteen-nine {
    &::before {
      background: url(../images/FAQbanner.jpg) center center no-repeat;
      padding-top: 23%;
      background-size: cover;
    }
  }
}

.faqBoxMain {
  margin-top: 5rem;
  @include bp(md) {
    margin-top: 2rem;
  }
  .askeBox {
    h4 {
      color: $blue;
      font-family: $font-family;
      font-weight: 400;
      font-size: 1.5625rem;
    }
    h2 {
      font-family: $font-family;
      margin: 2rem 0rem 1.5rem;
      font-size: 2.25rem;

      @include bp(md) {
        margin-top: 1rem;
        font-size: $head-md;
      }
      @include bp(sm) {
        font-size: $head-small;
      }
    }
    p {
      font-family: $font-family;
      color: #6d6e71;
    }
  }

  .CheckButtonFaq {
    * {
      color: $white;
    }
    margin-top: 40px;

    font-size: $header-font-size;
    text-transform: capitalize;
    background: $blue;
    padding: 0.7rem 2rem;
    border-radius: 35px;
    // transition: 200ms;
    font-family: $font-family;
    margin: 2rem 0rem;

    &:hover {
      background: $light-blue;
    }
    @include bp(md) {
      // padding:0.2rem 1rem;
      // font-size:0.875rem;
    }
  }
}

.accordionBox {
  .accordion {
    width: 100%;
    max-width: 625px;
    padding-left: 2rem;
    * {
      font-family: $font-family;
    }
  }
  .panel {
    // background-color: #f0ebe1;
  }
  .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 10px 0px 10px 25px;
    font-weight: 500;
    // font-size: 17px;
    font-family: inherit;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
    cursor: pointer;
    @include bp(md) {
      h4 {
        font-size: 1.25rem;
      }
    }
  }
  .panel__label:focus {
    outline: none;
  }
  .iconBox {
    width: 30px;
    height: 30px;
    background: $blue;
    position: absolute;
    border-radius: 100%;
    display: inline-block;
    margin-right: 1rem;
    top: 10px;
    left: -20px;
  }
  .panel__label .iconBox:after,
  .panel__label .iconBox:before {
    content: "";
    position: absolute;
    left: 0%;
    right: 0%;
    top: 50%;
    margin: 0 auto;
    width: 12px;
    height: 2px;
    background-color: $white;
  }
  .panel__label .iconBox:before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: -webkit-transform 0.35s
      cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1),
      -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel[aria-expanded="true"] .panel__content {
    opacity: 1;
  }
  .panel[aria-expanded="true"] .panel__label {
    color: #957029;
  }
  .panel[aria-expanded="true"] .panel__label .iconBox:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .panel__inner {
    overflow: hidden;
    will-change: height;
    -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel__content {
    margin: 5px 25px 25px;
    // font-size: 14px;
    color: #6d6e71;
    opacity: 0;
    -webkit-transition: opacity 0.3s linear 0.18s;
    transition: opacity 0.3s linear 0.18s;
  }
  .panel:not(:last-child) {
    margin-bottom: 3px;
  }
}

.accordionBox .panel[aria-expanded="true"] .panel__label {
  color: $h2color;
}
.mainHeaderFAQ {
  .container {
    max-width: 98%;
    margin-top: 1rem;
    @include bp(md) {
      max-width: none;
    }
  }
  p {
    margin-left: 8rem;
    margin-right: 8rem;
    text-align: center;
    font-family: $font-family;
    @include bp(md) {
      margin-left: 0;
      margin-right: 0;
      font-size: 0.8rem;
    }
  }
  h2 {
    text-align: center;
    font-family: $font-family;
    color: #064e89;
    @include bp(md) {
      font-size: 0.8rem;
    }
  }
  .formControlFaq {
    min-width: 200px;
    // margin-right: var(
    //   --theme-spacing-2
    // ); /* Adjust this as per your theme spacing */
    position: relative;
    display: flex;
    align-items: end;
    // margin-right: 4.5rem;

    @include bp(md) {
      min-width: 80px;

      margin-right: 0rem;
      align-items: flex-end;
    }
  }

  .selectFaq {
    width: 100%;
    padding: 10px 30px 10px 10px;

    border-color: #064e89;
    border-width: 2px;
    border-radius: 4px;
    appearance: none;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    color: #064e89;
    @include bp(md) {
      font-size: 8px;
      padding: 10px 60px 10px 60px;
    }
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #064e89;
    @include bp(md) {
      right: 2px;
    }
  }

  .filter {
    // position: absolute;

    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2rem;
    width: 100%;
    font-size: 1rem;
  }
  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 5px;
    color: #064e89;
    font-weight: bold;
    transition: 0.2s;
    pointer-events: none;
    transform-origin: top left;
  }

  .label.float {
    top: -10px;
    transform: scale(0.75);
  }
  .languageFaqFilter {
    // margin-left: 0.6rem;
    display: flex;
    align-items: flex-end;
    .langSvg {
      font-size: 1.8rem;
      @include bp(md) {
        font-size: 1.4rem;
      }
    }
    svg {
      font-size: 1.5rem;
      @include bp(md) {
        font-size: 1.2rem;
      }
    }
    span {
      margin-left: 0.3rem;
      @include bp(md) {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        margin-right: 1rem;
        margin-left: 0.1rem;

        // margin-right: 2rem;
      }
    }
  }
}
.faqSection {
  margin-top: 1rem;
  margin-bottom: 3rem;

  @include bp(md) {
    margin-top: 1.5rem;
  }

  .container {
    h2 {
      text-align: center;
      color: #064e89;
      font-family: $font-family;
      margin-bottom: 2.5rem;

      @include bp(md) {
        font-size: 0.9rem;
      }
    }

    .faqContent {
      display: flex;
      width: 100%;
      padding: 15px;
      align-items: center;
      @media (min-width: 300px) and (max-width: 400px) {
        padding: 1px;
        width: 88%;
      }
      @media (min-width: 400px) and (max-width: 500px) {
        width: 95%;
      }
    }
  }

  .questionList {
    margin-right: -1.5rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1rem;
    @include bp(md) {
      // margin-right: -1rem;
    }

    .questionCard {
      border: none;
      width: 100%;
      max-width: 450px;
      min-width: 450px;
      @media (min-width: 300px) and (max-width: 330px) {
        margin-left: -1.2rem;
        max-width: 130px;
        min-width: 130px;
      }
      @media (min-width: 330px) and (max-width: 350px) {
        margin-left: -1.2rem;
        max-width: 150px;
        min-width: 150px;
      }
      @media (min-width: 350px) and (max-width: 380px) {
        margin-left: -1.2rem;
        max-width: 180px;
        min-width: 180px;
      }
      @media (min-width: 380px) and (max-width: 400px) {
        margin-left: -1.8rem;
        max-width: 205px;
        min-width: 205px;
      }
      @media (min-width: 400px) and (max-width: 430px) {
        margin-left: -2rem;
        max-width: 205px;
        min-width: 205px;
      }
      @media (min-width: 420px) and (max-width: 500px) {
        margin-left: -2.2rem;
        max-width: 220px;
        min-width: 220px;
      }
      @media (min-width: 500px) and (max-width: 700px) {
        margin-left: -1.8rem;
        max-width: 220px;
        min-width: 220px;
      }
      @media (min-width: 700px) and (max-width: 1000px) {
        margin-left: -2rem;
        max-width: 250px;
        min-width: 250px;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .questionLabel {
            font-size: 0.9rem;
            @include bp(md) {
              font-size: 0.6rem;
            }
          }
          @include bp(md) {
            font-size: 0.6rem;

            svg {
              font-size: 0.6rem;
            }
          }
          .labelList {
            padding: 8px;
            display: flex;
            font-family: $font-family;
            svg {
              margin-right: 0.2rem;
            }
          }

          .MuiFormControlLabel-root {
            margin-left: 0.2rem;
            margin-top: 0.1rem;
          }

          @include bp(md) {
            .MuiFormControlLabel-label {
              font-size: 0.6rem;
              margin-top: 0.2rem;

              svg {
                font-size: 0.5rem;
              }
            }
          }
        }

        li.selected {
          background-color: #064e89;
          color: white;

          .MuiFormControlLabel-label {
            color: white;

            @include bp(md) {
              font-size: 0.6rem;
            }
          }

          .MuiRadio-root {
            color: white;

            @include bp(md) {
              font-size: 0.6rem;
            }
          }
        }

        .formControlLabel {
          .MuiRadio-root {
            &.Mui-checked {
              color: white;
            }
          }

          @include bp(md) {
            .MuiRadio-root {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }

  .answerList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;

    @include bp(md) {
      // width: 60%;
      margin-left: 0.5rem;
    }
    .answerCard {
      width: 100%;
      padding: 1rem;
      background-color: #064e89;
      color: white;
      // margin-right: 2rem;
      border-radius: 10px;
      display: flex;
      min-height: 350px;
      max-height: auto;
      box-sizing: border-box;
      // overflow-y: auto;
      // overflow-x: hidden;
      @media (min-width: 300px) and (max-width: 330px) {
        max-width: 200px;
        min-width: 200px;
        min-height: 450px;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      @media (min-width: 330px) and (max-width: 350px) {
        max-width: 200px;
        min-width: 200px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      @media (min-width: 350px) and (max-width: 380px) {
        max-width: 190px;
        min-width: 190px;
      }
      @media (min-width: 380px) and (max-width: 420px) {
        max-width: 202px;
        min-width: 202px;
      }
      @media (min-width: 420px) and (max-width: 500px) {
        max-width: 215px;
        min-width: 215px;
      }
      @media (min-width: 500px) and (max-width: 700px) {
        max-width: 300px;
        min-width: 300px;
      }
      @media (min-width: 700px) and (max-width: 1000px) {
        max-width: 450px;
        min-width: 450px;
      }

      .faqAnswer {
        width: 95%;
        margin-left: 3rem;
        padding-right: 10px;

        @media (min-width: 300px) and (max-width: 700px) {
          margin-left: 1.5rem;
        }
        @media (min-width: 700px) and (max-width: 1000px) {
          margin-left: 1.8rem;
        }

        .question {
          font-weight: bold;
          margin-bottom: 1rem;

          @include bp(md) {
            font-size: 0.6rem;
          }
        }

        .answer {
          ul,
          li {
            list-style: circle;
          }
          @include bp(md) {
            font-size: 0.6rem;
            p {
              font-size: 0.6rem;
            }
            ul li {
              margin-left: -0.2rem;
              list-style: circle;
              font-size: 0.6rem;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}

.subjectFaq {
  h2 {
    font-family: $font-family;
    color: #064e89;
    text-align: center;
    margin-bottom: 2.5rem;
    @include bp(md) {
      font-size: 0.9rem;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .button {
      min-width: 20px;
      font-weight: bold;
      margin: 0 4px;
      // border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      background-color: white;
      color: black;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      font-size: 0.7rem;
      font-family: $font-family;
      @include bp(md) {
        padding: 5px 13px;
        font-size: 0.6rem;
      }
    }
    .active {
      background-color: #064e89;
      color: white;
    }
  }
}
.askMore {
  display: flex;
  justify-content: center;
  .askMoreContainer {
    button {
      // border: 1px solid #6d6e71;
      padding: 20px 80px;
      margin-top: 10px;
      font-size: 1rem;
      color: #6d6e71;
      font-family: $font-family;
      text-transform: none;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      @include bp(md) {
        padding: 10px 40px;
      }
    }
  }
}
.askMoreForm {
  .input-field {
    input {
      // padding: 12px 10px;
    }

    .MuiOutlinedInput-root {
      & fieldset {
        border-color: #064e89;
      }
      &:hover fieldset {
        border-color: #064e89;
      }
      &.Mui-focused fieldset {
        border-color: #064e89;
      }
    }

    .MuiSelect-icon,
    .MuiInputLabel-root {
      color: #064e89;
    }

    .MuiInputLabel-asterisk {
      color: red;
    }
  }

  .question-field {
    .MuiOutlinedInput-root {
      & fieldset {
        border-color: white;
      }
      &:hover fieldset {
        border-color: white;
      }
      &.Mui-focused fieldset {
        border-color: white;
      }
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Box-shadow for the field
    }
  }
}

.dialogAction {
  button {
    background-color: #064e89;
    color: white;
    padding: 5px 10px;

    &:hover {
      background-color: #064e89;
      color: white;
    }
  }
}
