@import "global";
.mainOther {
  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 4rem;
    @include bp(md) {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
  }
  .container {
    // border: 1px solid black;
    position: relative;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-left: 7px;

    @include bp(md) {
      margin-left: 1.5rem;
    }
  }
  .prevButton {
    position: absolute;
    top: 50;
    left: 0;
    margin-left: 1rem;
    margin-top: -9rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-left: 1.2rem;
      margin-top: -8rem;
    }
  }
  .singlePrevButton {
    position: absolute;
    top: 50;
    left: 18rem;

    margin-top: -9rem;
    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 2px;
      margin-top: -8rem;
      left: 0.17rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 2px;
      margin-top: -8rem;
      left: 0.85rem;
    }
    @media (min-width: 401px) and (max-width: 500px) {
      padding: 3px;
      margin-top: -8rem;
      left: 0.45rem;
    }
    @media (min-width: 501px) and (max-width: 600px) {
      padding: 4px;
      margin-top: -8rem;
      left: 0.46rem;
    }
    @media (min-width: 601px) and (max-width: 700px) {
      margin-top: -9rem;
      left: 0.9rem;
    }
    @media (min-width: 701px) and (max-width: 900px) {
      left: 2.2rem;
      margin-top: -8rem;
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      left: 2.3rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50;
    right: 0;
    margin-top: -9rem;
    margin-right: -0.1rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-right: 0.4rem;
      margin-top: -8rem;
    }
  }
  .singleNextButton {
    position: absolute;
    top: 50;
    right: 18rem;
    margin-left: 1rem;
    margin-top: -9rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 2px;
      margin-top: -8rem;
      right: 0rem;
      margin-left: 0;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 2px;
      margin-top: -8rem;
      right: 0.6rem;
    }
    @media (min-width: 401px) and (max-width: 500px) {
      padding: 3px;
      margin-top: -8rem;
      right: 0.6rem;
    }
    @media (min-width: 501px) and (max-width: 600px) {
      padding: 4px;
      margin-top: -8rem;
      right: 0.7rem;
    }
    @media (min-width: 601px) and (max-width: 700px) {
      margin-top: -9rem;
      // margin-right: 1rem;
      right: 0.2rem;
    }
    @media (min-width: 701px) and (max-width: 900px) {
      right: -2rem;
      margin-top: -8rem;
    }

    @media (min-width: 901px) and (max-width: 1000px) {
      right: 0.2rem;
    }
  }
}

.otherArticle {
  // margin-top: 0.8rem;

  @include bp(md) {
    margin-left: 0rem;
  }

  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    // border: 1px solid black;
    padding-right: 28px;
    padding-left: 4rem;
    margin-top: 4rem;
    margin-bottom: 0.2rem;

    @media (min-width: 300px) and (max-width: 349px) {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 350px) and (max-width: 450px) {
      padding-right: 20px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 451px) and (max-width: 500px) {
      padding-right: 23px;
      padding-left: 23px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 501px) and (max-width: 700px) {
      padding-right: 26px;
      padding-left: 1.8rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  // }

  .card {
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
    overflow: visible;
    // width: 98%;
  }

  .card-body {
    display: flex;
    align-items: flex-start;
  }

  .mainImage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    width: 140px;
    height: 140px;
    img {
      border: 3px solid #064e89;
      border-radius: 5%;
      height: 110%;
      width: 110%;
      object-fit: cover;
      position: relative;
      transform: translate(0, 1%);
      left: -30%;
    }
    @media (min-width: 300px) and (max-width: 349px) {
      img {
        height: 65%;
        width: 65%;
        left: -38%;
      }
    }
    @media (min-width: 350px) and (max-width: 400px) {
      img {
        height: 75%;
        width: 75%;
        left: -38%;
      }
    }
    @media (min-width: 400px) and (max-width: 700px) {
      img {
        height: 85%;
        width: 85%;
        left: -32%;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: -1.8rem;

    .topic {
      min-height: 6.2rem;
      display: flex;
      // align-items: center;
      flex-direction: column;
      h4 {
        font-family: $font-family;
        font-size: 1.2rem;
        color: #064e89;
        font-weight: bold;
        text-align: left;
        @include bp(md) {
          font-size: 1rem;
        }
      }
    }
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -4.5rem;
      max-width: 110%;
      // border: 1px solid black;
      .topic {
        min-height: 4rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 0.8rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }
    @media (min-width: 350px) and (max-width: 400px) {
      margin-left: -4rem;
      max-width: 110%;
      // border: 1px solid black;
      .topic {
        min-height: 4rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 0.9rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }
    @media (min-width: 400px) and (max-width: 700px) {
      margin-left: -3rem;
      max-width: 110%;
      // border: 1px solid black;
      .topic {
        min-height: 4.5rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 1rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }
    @media (min-width: 701px) and (max-width: 900px) {
      .topic {
        min-height: 6rem;
      }
    }
    .date {
      h4 {
        font-family: $font-family;
        font-size: 1rem;
        color: gray;
        text-align: left;
        font-weight: normal;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.7rem;
        }
        @media (min-width: 350px) and (max-width: 400px) {
          font-size: 0.8rem;
        }
        @media (min-width: 400px) and (max-width: 700px) {
          font-size: 0.9rem;
        }
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    margin-left: 8.2rem;
    margin-top: -4.52rem;
    margin-bottom: 2.5em;
    width: 70%;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-bottom: 2em;
      margin-top: -7.1rem;
      margin-left: 5.3rem;
      width: 62%;
    }
    @media (min-width: 350px) and (max-width: 400px) {
      margin-bottom: 2em;
      margin-top: -6.9rem;
      margin-left: 6rem;
      width: 62%;
    }
    @media (min-width: 400px) and (max-width: 700px) {
      margin-bottom: 2em;
      margin-top: -6.5rem;
      margin-left: 7rem;
      width: 62%;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 350px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      // @media (min-width: 371px) and (max-width: 400px) {
      //   padding-top: 0.38rem;
      //   padding-bottom: 0.38rem;
      //   padding-left: 0.5rem;
      //   padding-right: 0.5rem;
      //   font-size: 0.54rem;
      // }
      @media (min-width: 750px) and (max-width: 900px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      @media (min-width: 501px) and (max-width: 700px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .bookmark {
      border: 2px solid #064e89;
      padding: 1.5px;
      // margin-left: 16rem;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;

      @media (min-width: 300px) and (max-width: 330px) {
        padding: 0rem;
        // margin-left: 2.8rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding: 0rem;
        // margin-left: 3.8rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 361px) {
        padding: 0rem;
        // margin-left: 2.5rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }
      @media (min-width: 362px) and (max-width: 400px) {
        padding: 0rem;
        // margin-left: 3.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        // margin-left: 19rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        // margin-left: 18.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        // margin-left: 7rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        padding: 0rem;
        // margin-left: 4rem;
        font-size: 0rem;
        margin-top: -0.1rem;
        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
      @media (min-width: 501px) and (max-width: 700px) {
        padding: 0rem;
        // margin-left: 11rem;
        font-size: 0rem;
        margin-top: -0.1rem;
        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
      @media (min-width: 700px) and (max-width: 750px) {
        padding: 0rem;
        // margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: -0.1rem;
        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
