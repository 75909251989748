body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  line-height: 1.5;
  font-family: 'gotham rounded';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-device-width: 768px) {
  body::-webkit-scrollbar {
    display: none;
  }

  body {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

#root {
  /* max-height: 100dvh; */
  padding-bottom: env(safe-area-inset-bottom);
}

.mainAll::-webkit-scrollbar {
  display: none;
}

body .h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #414042;
  font-family: 'gotham rounded';
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #414042;
  font-family: 'gotham rounded';
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 20px;
}