@import "global";

.full-width-image {
  width: 100%;
  height: auto;
}

.visitingCard {
  margin-top: 2.5vw;

  position: fixed;
  top: 40vw;
  bottom: 3vw;
  right: 2vw;
  z-index: 1000;
  display: flex;

  justify-content: flex-end;
  align-items: center;
  transition: opacity 0.3s, visibility 0.3s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  button {
    padding: 0.2vw 1vw;
    font-size: 1vw;
    font-family: $font-family;
    background: #064e89;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background 0.3s;
    margin-right: 1vw;
    margin-bottom: 1vw;

    &:hover {
      background: darken(#064e89, 10%);
    }

    span {
      margin-left: 0.1vw;
      display: flex;
      align-items: center;

      svg {
        font-size: 2vw;
      }
    }
  }

  @media (min-width: 300px) and (max-width: 349px) {
    height: 5%;
    margin-top: 3.5rem;
    margin-bottom: 0;

    button {
      font-size: 3vw;
      margin-right: 5.2vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 5vw;
        }
      }
    }
  }

  @media (min-width: 350px) and (max-width: 370px) {
    height: 5%;
    margin-top: 3.5rem;
    margin-bottom: 0;

    button {
      font-size: 3vw;
      margin-right: 5vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 5vw;
        }
      }
    }
  }

  @media (min-width: 371px) and (max-width: 400px) {
    height: 5%;
    margin-top: 3.5rem;
    margin-bottom: 0;

    button {
      font-size: 3vw;
      margin-right: 4.5vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 5vw;
        }
      }
    }
  }

  @media (min-width: 750px) and (max-width: 900px) {
    height: 5%;
    margin-top: 2rem;
    margin-bottom: 0;

    button {
      font-size: 1.5vw;
      margin-right: 1vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 3vw;
        }
      }
    }
  }

  @media (min-width: 901px) and (max-width: 1000px) {
    height: 5%;
    margin-top: 1rem;
    margin-bottom: 0;

    button {
      font-size: 1.5vw;
      margin-right: 0vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 3vw;
        }
      }
    }
  }

  @media (min-width: 1001px) and (max-width: 1050px) {
    height: 5%;
    margin-top: 1rem;
    margin-bottom: 0;

    button {
      font-size: 1.2vw;
      margin-right: 1.8vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 2.8vw;
        }
      }
    }
  }

  @media (min-width: 401px) and (max-width: 450px) {
    height: 5%;
    margin-top: 3.5rem;
    margin-bottom: 0;

    button {
      font-size: 3vw;
      margin-right: 4.5vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 5vw;
        }
      }
    }
  }

  @media (min-width: 451px) and (max-width: 749px) {
    height: 5%;
    margin-top: 3.5rem;
    margin-bottom: 0;

    button {
      font-size: 2vw;
      margin-right: 2vw;
      margin-bottom: 0;

      span {
        svg {
          font-size: 4vw;
        }
      }
    }
  }
}

.information {
  margin-top: 6rem;

  .container {
    max-width: 95%;

    // margin-left: 1rem;
    // margin-right: 1rem;
    @include bp(md) {
      max-width: 100%;
    }

    .first-row {
      p {
        text-align: justify;
        font-family: $font-family;
      }

      .player-wrapper {
        margin-left: 3rem;
        position: relative;
        border: 10px solid rgba(214, 143, 11, 0.824);
        overflow: hidden;

        .visitingCard {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 1000;
          transition: opacity 0.3s;

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }

          button {
            padding-left: 1px;
            padding-right: 1px;
            width: auto;
            font-size: 0.8rem;
            font-family: $font-family;
            background: #064e89;
            color: white;

            &:hover {
              background: #064e89;
            }

            span {
              margin-left: 0.2rem;

              svg {
                font-size: 2rem;
              }
            }
          }
        }

        width: 95%;
        aspect-ratio: 2/1;

        @include bp(md) {
          margin-left: 0;
          width: 100%;
        }
      }

      // img {
      //   width: 30%;
      // }
    }
  }
}

.card-form {}

.form {
  margin-top: 2rem;

  h2 {
    color: #064e89;
    text-align: center;
    font-family: $font-family;

    @include bp(md) {
      font-size: 1.5rem;
    }
  }

  p {
    text-align: center;
    font-family: $font-family;

    @media (min-width: 300px) and (max-width: 400px) {
      font-size: 0.7rem;
    }

    @media (min-width: 401px) and (max-width: 1000px) {
      font-size: 0.8rem;
    }
  }

  .container {
    max-width: 95%;

    @include bp(md) {
      max-width: 100%;
    }

    .user-form {
      max-width: 85%;
      border: 1px solid rgb(76, 183, 225);
      border-radius: 5%;
      margin-left: -0.8rem;
      background: rgb(76, 183, 225);

      @include bp(md) {
        max-width: 100%;
        margin-left: 0;
        margin-bottom: 2rem;
      }

      p {
        margin-top: 1rem;
        font-weight: bold;
      }

      .form-group {
        margin-top: 1rem;
        margin-left: 5rem;
        margin-bottom: 1.5rem;
        color: white;
        font-family: $font-family;

        @media (min-width: 300px) and (max-width: 370px) {
          margin-left: 3.5rem;
          font-size: 0.8rem;
        }

        @media (min-width: 371px) and (max-width: 400px) {
          margin-left: 3.8rem;
          font-size: 0.9rem;
        }

        @media (min-width: 401px) and (max-width: 450px) {
          margin-left: 4rem;
          font-size: 0.9rem;
        }

        @media (min-width: 750px) and (max-width: 1050px) {
          margin-left: 3rem;
          font-size: 0.9rem;
        }
      }

      label {
        margin-bottom: 5px;
      }

      input {
        width: 80%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
    }

    .maincard {
      margin-left: 3rem;

      @include bp(md) {
        margin-left: 0;
      }
    }

    .player-wrapper {
      position: relative;
      height: 100%;
      padding-top: 60.25%;

      overflow: hidden;

      @include bp(sm) {
        padding-top: 70%;
      }
    }

    // img {
    //   width: 30%;
    // }
    .card-preview {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-content {
      position: absolute;
      top: 80px;
      left: 50px;
      width: 80%;
      color: white;
      font-family: "Chalkboard", sans-serif;

      h3 {
        max-width: 80%;

        white-space: nowrap;
      }

      @media (min-width: 300px) and (max-width: 349px) {
        // top: 20px;
        // left: 10px;
        left: 15px;
        top: 20px;
      }

      @media (min-width: 350px) and (max-width: 370px) {
        // top: 20px;
        // left: 10px;
        left: 15px;
        top: 25px;
      }

      @media (min-width: 371px) and (max-width: 400px) {
        left: 15px;
        top: 25px;
      }

      @media (min-width: 401px) and (max-width: 450px) {
        left: 15px;
        top: 35px;
      }

      @media (min-width: 451px) and (max-width: 650px) {
        left: 15px;
        top: 70px;
      }

      @media (min-width: 651px) and (max-width: 900px) {
        left: 18px;
        top: 35px;
      }

      @media (min-width: 901px) and (max-width: 1000px) {
        left: 20px;
        top: 50px;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        left: 20px;
        top: 50px;
      }
    }

    .user-input {
      // border-left: 4px solid white;
      // border-right: 4px solid white;
      margin-top: 5%;
      margin-left: 10%;
    }

    .card-content h3 {
      font-size: 1.75rem;
      font-family: "gotham rounded medium" !important;
      margin-top: 1rem;
      margin-left: 2.5rem;
      color: white;
      text-align: center;
      white-space: nowrap;

      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.8rem;
        margin-left: 2.2rem;
      }

      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.9rem;
        margin-left: 2.2rem;
      }

      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.9rem;
        margin-left: 2.8rem;
      }

      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: 3rem;
      }

      @media (min-width: 451px) and (max-width: 650px) {
        font-size: 1.4rem;
        margin-left: 4.2rem;
      }

      @media (min-width: 651px) and (max-width: 900px) {
        font-size: 1.1rem;
        margin-left: 3.5rem;
      }

      @media (min-width: 901px) and (max-width: 1000px) {
        font-size: 1.3rem;
        margin-left: 3.5rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1.3rem;
        margin-left: 3.5rem;
      }
    }

    // .tel {
    //   p {
    //     font-family: Times, " Times New Roman ", Georgia, serif;
    //   }
    //   display: flex;
    //   text-align: justify;
    //   margin-left: 1.6rem;
    //   margin-top: -0.5rem;
    //   text-decoration: none;
    //   @media (min-width: 300px) and (max-width: 349px) {
    //     margin-top: -0.8rem;
    //     margin-left: -0.4rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 350px) and (max-width: 370px) {
    //     margin-left: -0.4rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 371px) and (max-width: 400px) {
    //     margin-left: -0.2rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 401px) and (max-width: 450px) {
    //     margin-left: -0.2rem;

    //     p {
    //       font-size: 0.7rem;
    //     }
    //   }
    //   @media (min-width: 451px) and (max-width: 650px) {
    //     margin-left: 0.8rem;
    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    //   @media (min-width: 651px) and (max-width: 900px) {
    //     margin-left: 0.2rem;
    //     margin-top: -0.8rem;
    //     p {
    //       font-size: 0.7rem;
    //     }
    //   }
    //   @media (min-width: 901px) and (max-width: 1000px) {
    //     margin-left: 0.2rem;

    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    //   @media (min-width: 1001px) and (max-width: 1050px) {
    //     margin-left: 0.2rem;
    //     margin-top: -0.6rem;
    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    // }
    // .email {
    //   p {
    //     font-family: Times, " Times New Roman ", Georgia, serif;
    //     text-decoration: none;
    //   }

    //   display: flex;
    //   // text-align: justify;
    //   margin-left: 1.6rem;
    //   margin-top: -0.5rem;
    //   @media (min-width: 300px) and (max-width: 349px) {
    //     margin-left: -0.4rem;
    //     margin-top: -0.8rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 350px) and (max-width: 370px) {
    //     margin-left: -0.4rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 371px) and (max-width: 400px) {
    //     margin-left: -0.2rem;
    //     p {
    //       font-size: 0.6rem;
    //     }
    //   }
    //   @media (min-width: 401px) and (max-width: 450px) {
    //     margin-left: -0.2rem;
    //     p {
    //       font-size: 0.7rem;
    //     }
    //   }
    //   @media (min-width: 451px) and (max-width: 650px) {
    //     margin-left: 0.8rem;
    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    //   @media (min-width: 651px) and (max-width: 900px) {
    //     margin-left: 0.2rem;
    //     margin-top: -0.8rem;
    //     p {
    //       font-size: 0.7rem;
    //     }
    //   }
    //   @media (min-width: 901px) and (max-width: 1000px) {
    //     margin-left: 0.2rem;

    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    //   @media (min-width: 1001px) and (max-width: 1050px) {
    //     margin-left: 0.2rem;
    //     margin-top: -0.6rem;
    //     p {
    //       font-size: 0.8rem;
    //     }
    //   }
    // }
    // .card-content .address {
    //   font-family: Times, " Times New Roman ", Georgia, serif;
    //   margin-left: 1.6rem;
    //   margin-top: 1rem;
    //   text-align: left;
    //   max-width: 90%;
    //   text-decoration: none;
    //   @media (min-width: 300px) and (max-width: 349px) {
    //     font-size: 0.6rem;
    //     max-width: 100%;
    //     margin-left: -0.4rem;
    //     margin-top: -0.7rem;
    //   }
    //   @media (min-width: 350px) and (max-width: 370px) {
    //     font-size: 0.6rem;
    //     max-width: 100%;
    //     margin-left: -0.4rem;
    //   }
    //   @media (min-width: 371px) and (max-width: 400px) {
    //     font-size: 0.6rem;
    //     max-width: 100%;
    //     margin-left: -0.2rem;
    //   }
    //   @media (min-width: 401px) and (max-width: 450px) {
    //     font-size: 0.7rem;
    //     max-width: 100%;
    //     margin-left: -0.1rem;
    //   }
    //   @media (min-width: 451px) and (max-width: 650px) {
    //     font-size: 0.8rem;
    //     max-width: 100%;
    //     margin-left: 0.8rem;
    //   }
    //   @media (min-width: 651px) and (max-width: 900px) {
    //     font-size: 0.7rem;
    //     max-width: 100%;
    //     margin-left: 0.2rem;
    //     margin-top: -0.5rem;
    //   }
    //   @media (min-width: 901px) and (max-width: 1000px) {
    //     font-size: 0.8rem;
    //     max-width: 100%;
    //     margin-left: 0.2rem;
    //   }
    //   @media (min-width: 1001px) and (max-width: 1050px) {
    //     font-size: 0.8rem;
    //     max-width: 100%;
    //     margin-left: 0.2rem;
    //     margin-top: -0.2rem;
    //   }
    // }
    // .card-content .contact {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-left: 5.5rem;

    //   .email {
    //     margin-right: 4rem;
    //   }
    // }

    .text,
    .tel,
    .email,
    .address {
      p {
        text-align: left;
        margin:10px 0px;
        font-family: Times, " Times New Roman ", Georgia, serif;
      }

      @include bp(md) {
        font-size: 2rem;
      }
    }


    .action {
      margin-left: 3rem;
      display: flex;
      justify-content: center;

      button {
        border: 4px solid #064e89;
        color: #064e89;
        font-family: $font-family;

        span {
          margin-left: 1rem;

          svg {
            font-size: 2rem;
          }
        }
      }

      @include bp(md) {
        margin-left: 0rem;

        button {
          font-size: 0.8rem;

          span {
            svg {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}