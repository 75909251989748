@import "global";

.mainSection {
  .container {
    max-width: 98%;

    // border: 1px solid black;
    @include bp(md) {
      max-width: none;
    }
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: -0.5rem;
    font-family: $font-family;
    color: #064e89;
    font-size: 2rem;

    @include bp(md) {
      font-size: 1.5rem;
    }
  }

  .formControlArticle {
    min-width: 200px;
    // margin-right: var(
    //   --theme-spacing-2
    // ); /* Adjust this as per your theme spacing */
    position: relative;
    display: flex;
    align-items: end;
    // margin-right: 4.5rem;

    @include bp(md) {
      min-width: 90px;
      margin-right: 0rem;
      align-items: center;
    }
  }

  .selectArticle {
    width: 100%;
    padding: 10px 30px 10px 10px;

    border-color: #064e89;
    border-width: 2px;
    border-radius: 4px;
    appearance: none;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    color: #064e89;

    @include bp(md) {
      font-size: 10px;
      padding: 10px 60px 10px 60px;
    }
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #064e89;

    @include bp(md) {
      right: 2px;
    }
  }

  .filter {
    // position: absolute;

    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2rem;
    width: 100%;
    font-size: 1rem;

    @include bp(md) {
      justify-content: center;
      align-items: center;
    }
  }

  .languageArticleFilter {
    // margin-left: 0.6rem;
    display: flex;
    align-items: flex-end;

    .langSvg {
      font-size: 1.8rem;

      @include bp(md) {
        font-size: 1.4rem;
      }
    }

    svg {
      font-size: 1.5rem;

      @include bp(md) {
        font-size: 1.2rem;
      }
    }

    span {
      margin-left: 0.3rem;

      @include bp(md) {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        margin-right: 1rem;
        margin-left: 0.1rem;

        // margin-right: 2rem;
      }
    }
  }
}

.articleBanner {
  .sixteen-nine:before {
    //background:url(../images/articles.png) center center no-repeat;
    background: url(../images/articles.jpg) center center no-repeat;
    //padding-top: 39.25%;
    padding-top: 23%;
    background-size: cover;
  }
}

.articleMain {
  .row {
    // display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .custom-col-spacing {
    margin-bottom: -1rem;

    @include bp(md) {
      margin-bottom: 1rem;
    }
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    // border: 1px solid black;
    padding-right: 18px;
    padding-left: 3.5rem;
    margin-top: 4rem;

    @media (min-width: 350px) and (max-width: 400px) {}

    @include bp(md) {
      padding-right: 15px;
      padding-left: 30px;
      margin-top: 2rem;
      margin-left: 5rem;
    }
  }

  .card {
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
    overflow: visible;
    /* Ensure the image can overflow */
    height: 100%;
    display: flex;
    flex-direction: column;

    // @media (min-width: 350px) and (max-width: 380px) {
    //   width: 100%;
    // }

    // @media (min-width: 381px) and (max-width: 400px) {
    //   width: 75%;
    // }
    // @media (min-width: 401px) and (max-width: 450px) {
    //   width: 100%;
    // }
  }

  .card-body {
    display: flex;
    align-items: flex-start;

    @media (min-width: 300px) and (max-width: 349px) {}
  }

  .mainImage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    width: 140px;
    height: 140px;

    img {
      border: 3px solid #064e89;
      border-radius: 5%;
      height: auto;
      /* Adjust as needed */
      height: 110%;
      width: 110%;
      object-fit: cover;
      position: relative;
      transform: translate(0, 1%);
      left: -30%;
      /* Move image 20% to the left */
    }

    @media (min-width: 300px) and (max-width: 349px) {
      img {
        height: 65%;
        width: 65%;
        left: -28%;
      }
    }

    @media (min-width: 350px) and (max-width: 400px) {
      img {
        height: 75%;
        width: 75%;
        // left: -22%;
      }
    }

    @media (min-width: 400px) and (max-width: 700px) {
      img {
        height: 85%;
        width: 85%;
        // left: -22%;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: -1.8rem;

    .topic {
      min-height: 6rem;
      display: flex;
      flex-direction: column;
      flex: 3;

      // border: 1px solid black;
      h4 {
        font-family: $font-family;
        font-size: 1.1rem;
        color: #064e89;
        font-weight: bold;
      }
    }

    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -4.8rem;

      .topic {
        min-height: 3rem;
        // border: 1px solid black;
        max-width: 100% !important;
        word-break: break-word !important;

        h4 {
          // text-overflow: ellipsis;
          font-size: 0.7rem;
          flex: 2;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    @media (min-width: 350px) and (max-width: 400px) {
      margin-left: -4rem;

      .topic {
        min-height: 3.5rem;
        max-width: 100% !important;
        word-break: break-word !important;

        h4 {
          font-size: 0.8rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    @media (min-width: 400px) and (max-width: 500px) {
      margin-left: -3rem;

      // border: 1px solid black;
      .topic {
        min-height: 4.5rem;
        max-width: 100% !important;
        word-break: break-word !important;

        h4 {
          font-size: 0.87rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    @media (min-width: 501px) and (max-width: 700px) {
      margin-left: -3rem;

      // border: 1px solid black;
      .topic {
        max-width: 100% !important;
        word-break: break-word !important;

        h4 {
          font-size: 1rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    @media (min-width: 701px) and (max-width: 900px) {
      margin-left: -1.9rem;

      // border: 1px solid black;
      .topic {
        min-height: 6rem;
      }
    }

    @media (min-width: 901px) and (max-width: 1020px) {
      margin-left: -1.9rem;

      // border: 1px solid black;
      .topic {
        min-height: 6rem;
      }
    }

    .date {

      // margin-top: 1rem;
      h4 {
        font-family: $font-family;
        font-size: 1rem;
        color: gray !important;
        font-weight: normal !important;

        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.5rem;
        }

        @media (min-width: 350px) and (max-width: 400px) {
          font-size: 0.6rem;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 0.7rem;
        }

        @media (min-width: 501px) and (max-width: 700px) {
          font-size: 0.8rem;
        }
      }
    }
  }

  .actionArtMain {
    display: flex;
    //   justify-content: space-between;
    align-items: center;
    margin-left: 8.2rem;
    margin-top: -4.52rem;
    margin-bottom: 2.5em;

    @media (min-width: 300px) and (max-width: 349px) {
      margin-bottom: 2em;
      margin-top: -8rem;
      margin-left: 5.2rem;
    }

    @media (min-width: 350px) and (max-width: 400px) {
      margin-bottom: 2em;
      margin-top: -7.4rem;
      margin-left: 6rem;
    }

    @media (min-width: 400px) and (max-width: 700px) {
      margin-bottom: 2em;
      margin-top: -6.5rem;
      margin-left: 7rem;
    }

    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;

      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }

      @media (min-width: 350px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }

      // @media (min-width: 371px) and (max-width: 400px) {
      //   padding-top: 0.38rem;
      //   padding-bottom: 0.38rem;
      //   padding-left: 0.5rem;
      //   padding-right: 0.5rem;
      //   font-size: 0.54rem;
      // }
      @media (min-width: 750px) and (max-width: 900px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }

      @media (min-width: 901px) and (max-width: 1000px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }

      @media (min-width: 401px) and (max-width: 500px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }

      @media (min-width: 501px) and (max-width: 700px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
    }

    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;

      padding: 1.5px;
      margin-left: 21vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;

      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 330px) {
        padding: 0rem;
        margin-left: 4.5rem;
        font-size: 0rem;
        margin-top: 0rem;

        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }

      @media (min-width: 331px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;

        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }

      @media (min-width: 350px) and (max-width: 361px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;

        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }

      @media (min-width: 362px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 6.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        margin-left: 29rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        margin-left: 36rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: 9.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 401px) and (max-width: 500px) {
        padding: 0rem;
        margin-left: 7rem;
        font-size: 0rem;
        margin-top: -0.1rem;

        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }

      @media (min-width: 501px) and (max-width: 700px) {
        padding: 0rem;
        margin-left: 14.5rem;
        font-size: 0rem;
        margin-top: -0.1rem;

        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.articleSingle {
  margin-top: 5rem;

  @include bp(md) {
    margin-top: 2rem;
  }

  h1 {
    background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 2.625rem;
    font-weight: 700;
    padding-top: 0.5rem;

    @include bp(md) {
      font-size: 1.5rem;
      margin-top: 1rem;
    }

    @include bp(sm) {
      margin-top: 2rem;
    }
  }

  .articleList {
    .list-inline-item:not(:last-child) {
      margin-right: 0rem;
    }

    ul {
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
      height: 15px;
      margin: 1rem 0;

      li {
        width: 15px;
        height: 2px;
        background: #056aff;
        position: relative;
        display: inline-block;
        margin-left: -5px;

        &:nth-child(odd) {
          transform: rotate(-45deg);

          &::before {
            left: 5px;
          }
        }

        &:nth-child(even) {
          transform: rotate(45deg);

          &::before {
            right: 5px;
          }

          &::after {
            left: 5px;
          }
        }

        &::before {
          content: "";
          width: 15px;
          height: 2px;
          position: absolute;
          top: -5px;
          background: #056aff;
        }

        &::after {
          content: "";
          width: 15px;
          height: 2px;
          position: absolute;
          // top: -5px;
          // background: #056aff;
        }
      }
    }
  }

  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;

    text-align: justify;

    // font-family: Poppins;
    @include bp(md) {
      font-size: 1rem;
    }
  }

  .MuiPaper-rounded {
    border-radius: 20px;
  }
}

.articleAction {
  .actionArtSingle {
    display: flex;

    @media (min-width: 300px) and (max-width: 400px) {
      // justify-content: space-between;
    }

    .bookmark {
      padding: 8px;
      // border: 3px solid #064e89;
      color: #064e89;
      margin-right: 1.2rem;

      @media (min-width: 300px) and (max-width: 330px) {
        margin-right: 0.5rem;
        padding: 1.2px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.2px;

          svg {
            font-size: 18px;
          }
        }
      }

      @media (min-width: 331px) and (max-width: 400px) {
        margin-right: 0.5rem;
        padding: 1.4px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.4px;

          svg {
            font-size: 20px;
          }
        }
      }

      @media (min-width: 401px) and (max-width: 700px) {
        margin-right: 0.5rem;
        padding: 1.2px;

        button {
          padding: 1.2px;

          svg {
            font-size: 0.1rem;
          }
        }
      }

      @media (min-width: 701px) and (max-width: 1050px) {
        margin-right: 0.5rem;

        button {
          padding: -5px;

          svg {
            font-size: 0.1rem;
          }
        }
      }
    }
  }

  .rating {
    font-size: 1rem;
    text-transform: uppercase;
    border: 2px solid black;
    padding: 0.4rem 0.8rem;
    border-radius: 5px;
    font-family: "gotham rounded medium";

    color: $black;

    @media (min-width: 300px) and (max-width: 330px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;
      display: flex;
      align-items: center;

      svg {
        font-size: 1rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 331px) and (max-width: 365px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 366px) and (max-width: 400px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 401px) and (max-width: 700px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.4rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}

.share {
  margin-top: -2.1rem;
  // margin-left: 5.8rem;
  // margin-right: 1rem;

  @media (min-width: 300px) and (max-width: 330px) {
    margin-top: -1.8rem;
    margin-right: 0.7rem;
  }

  @media (min-width: 331px) and (max-width: 400px) {
    margin-right: 0.7rem;
  }

  @media (min-width: 401px) and (max-width: 500px) {
    margin-left: 0;
    margin-right: 0.5rem;
    padding-bottom: 2rem;

    svg {
      font-size: 0.9rem;
      width: 28px;
    }
  }

  @media (min-width: 701px) and (max-width: 1050px) {
    margin-right: 1rem;
    margin-left: 0;
    padding-bottom: 2rem;

    svg {
      font-size: 0.4rem;
    }
  }
}

.comments {
  margin-top: 4rem;

  @media (min-width: 401px) and (max-width: 500px) {
    margin-top: 3rem;
  }

  h2 {
    color: #064e89;
    text-align: center;
    font-family: $font-family;

    @include bp(md) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .container {
    width: 50%;

    @include bp(md) {
      width: 100%;
    }
  }

  .card {
    height: auto;
    border: none;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);

    .information {
      padding: 8px;
      margin-left: 1.5rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      h5 {
        color: #064e89;
        font-family: $font-family;

        @media (min-width: 300px) and (max-width: 700px) {
          font-size: 1rem;
        }
      }

      p {
        font-family: $font-family;
        color: rgb(157, 157, 157);
        font-size: 0.8rem;

        @media (min-width: 300px) and (max-width: 701px) {
          font-size: 0.6rem;
        }
      }

      @include bp(md) {
        margin-left: 1rem;
      }
    }

    .comment {
      padding: 8px;
      margin-left: 1.5rem;

      @include bp(md) {
        margin-left: 1rem;
      }

      p {
        font-size: 0.9rem;
        font-family: $font-family;
      }
    }
  }

  .view-more-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    span {
      font-size: 0.7rem;
      font-family: $font-family;
      color: #064e89;

      .MuiSvgIcon-root {
        margin-left: 0.4rem;
        font-size: 1rem;
      }
    }
  }
}

.addComment {
  margin-top: 2rem;

  .container {
    width: 50%;

    @include bp(md) {
      width: 100%;
    }
  }

  .card {
    height: auto;
    border: none;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);

    .form {
      padding: 8px;
      display: flex;
      margin-bottom: 2rem;
      margin-left: 1.5rem;

      @media (min-width: 300px) and (max-width: 701px) {
        margin-left: 0.8rem;
        padding: 5px;
      }

      input {
        width: 24rem;

        @media (min-width: 300px) and (max-width: 330px) {
          width: 9rem;
        }

        @media (min-width: 331px) and (max-width: 350px) {
          width: 11rem;
        }

        @media (min-width: 350px) and (max-width: 400px) {
          width: 12rem;
        }

        @media (min-width: 401px) and (max-width: 500px) {
          width: 15rem;
        }

        @media (min-width: 501px) and (max-width: 700px) {
          width: 20rem;
        }
      }

      button {
        background-color: #064e89;
        font-family: $font-family;
        color: white;
        margin-left: 1rem;
        border-radius: 5px;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        width: 2rem;
        height: 2.2rem;
        padding: 10px 12px;
      }

      button:disabled {
        background-color: #205784;
        color: #d3d3d3;
        cursor: not-allowed;
        opacity: 0.6;
      }

      @media (min-width: 300px) and (max-width: 500px) {
        button {
          padding: 6px 8px;
          height: 2rem;
        }
      }
    }
  }
}

.grSingle {
  background-image: url(../images/webinar-bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 6.5rem;
  position: relative;

  @include bp(md) {
    padding-top: 1rem;
  }

  .table {
    width: 100%;
    // margin-top: 1rem;
    margin-left: -1rem;
    // font-family: $font-family;

    @include bp(md) {
      font-size: 0.8rem;
    }
  }

  td {
    border: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    // margin-bottom: 1rem;
    padding-top: 0.5rem;
    // font-weight: 500;
    text-align: left;

    // line-height: 1;
    @include bp(md) {
      font-size: 0.9rem;
    }
  }

  @include bp(sm) {
    font-size: 0.8rem;
  }

  // .firstcard {
  //   @include bp(md) {
  //     margin-top: 1rem;
  //   }
  //   .table {
  //     width: 100%;

  //     // font-family: $font-family;
  //     font-size: 1rem;
  //     @include bp(md) {
  //       font-size: 1rem;
  //     }
  //   }
  //   th {
  //     // font-weight: 100;
  //   }
  //   th,
  //   td {
  //     border: none;
  //     padding: 7px;
  //     text-align: left;
  //     line-height: 1;
  //   }

  //   th:first-child {
  //     width: 10%;
  //     padding-right: 5px;
  //   }

  //   td:first-child {
  //     padding-right: 10px;
  //   }

  //   @include bp(sm) {
  //     font-size: 0.8rem;
  //   }
  //   .topic-row {
  //     td {
  //       // padding-bottom: 1rem; // Adjust this value as needed
  //     }
  //   }
  // }
  .fixed-content {
    font-size: 10px;
  }

  .secondcard {
    border: none;
    width: 50%;

    @include bp(md) {
      width: 100%;
    }
  }

  h1 {
    background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 2.625rem;
    font-weight: 700;
    padding-top: 0.5rem;

    @include bp(md) {
      font-size: 2rem;
      margin-top: 1rem;
      text-align: center;
      font-size: 1.5rem;
    }

    @include bp(sm) {
      margin-top: 4rem;
    }
  }

  h5 {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    font-weight: 500;
  }

  h4,
  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;

    max-width: 90%;
    text-align: justify;
    // font-family: Poppins;

    a:link,
    a:visited {
      word-break: break-all;
    }

    @include bp(md) {
      max-width: 100%;
    }

    @media (min-width: 300px) and (max-width: 750px) {
      font-size: 1rem;
    }
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0rem;
  }

  ul {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    height: 15px;
    margin: 1rem 0;

    @include bp(md) {
      display: flex;
      justify-content: center;
    }

    li {
      width: 15px;
      height: 2px;
      background: #056aff;
      position: relative;
      display: inline-block;
      margin-left: -5px;

      &:nth-child(odd) {
        transform: rotate(-45deg);

        &::before {
          left: 5px;
        }
      }

      &:nth-child(even) {
        transform: rotate(45deg);

        &::before {
          right: 5px;
        }

        &::after {
          left: 5px;
        }
      }

      &::before {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        top: -5px;
        background: #056aff;
      }

      &::after {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        // top: -5px;
        // background: #056aff;
      }
    }
  }

  .svgBox {
    // background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .webArticle {
    .player-wrapper {
      position: relative;
      // height: 50%;
      padding-top: 60.25%;
      overflow: hidden;

      @include bp(sm) {
        padding-top: 56.25%;
      }
    }

    img {
      border-radius: 25px;
    }
  }

  .timeBox {
    padding-bottom: 5rem;

    @include bp(md) {
      padding-bottom: 2rem;
    }

    * {
      font-family: $font-family;
    }

    [class*="col-"] {
      padding-top: 5rem;

      @include bp(md) {
        padding-top: 2rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      color: #000;
      font-weight: bold;
      margin-top: 3.75rem;
      margin-bottom: 1.5625rem;

      @include bp(md) {
        margin-top: 2rem;
      }
    }

    h4 {
      color: #39374e;
      font-size: 1.125rem;
    }
  }
}

.buttonGRContainer {
  .actionGrSingle {
    display: flex;

    .bookmark {
      padding: 8px;
      // border: 3px solid #064e89;
      color: #064e89;
      margin-right: 1.2rem;

      @media (min-width: 300px) and (max-width: 400px) {
        padding: 1px;
      }

      @media (min-width: 401px) and (max-width: 750px) {
        padding: 3px;
      }

      @media (min-width: 751px) and (max-width: 900px) {
        padding: 4px;
        margin-top: 0rem;
      }
    }

    .view {
      font-size: 1rem;

      background: #064e89;
      color: white;
      padding: 0.4rem 0.8rem;
      border-radius: 5px;
      font-family: $font-family;

      button {
        color: white;
      }

      @media (min-width: 300px) and (max-width: 330px) {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        margin-right: 0.8rem;
        text-align: center;
        display: flex;
        align-items: center;

        button {
          font-size: 0.5rem;
          margin-bottom: 0.1rem;
        }
      }

      @media (min-width: 331px) and (max-width: 365px) {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        margin-right: 0.8rem;
        text-align: center;

        button {
          font-size: 0.5rem;
          margin-bottom: 0.1rem;
        }
      }

      @media (min-width: 366px) and (max-width: 400px) {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        margin-right: 0.8rem;
        text-align: center;

        button {
          font-size: 0.5rem;
          margin-bottom: 0.1rem;
        }
      }

      @media (min-width: 401px) and (max-width: 700px) {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        margin-right: 0.8rem;
        text-align: center;

        button {
          font-size: 0.7rem;
          margin-bottom: 0.1rem;
        }
      }
    }
  }

  .grShare {
    margin-top: -2.1rem;
    // margin-left: 5.8rem;
    // margin-right: 1rem;

    @media (min-width: 300px) and (max-width: 330px) {
      margin-top: -2rem;
      margin-right: 0.7rem;
    }

    @media (min-width: 331px) and (max-width: 400px) {
      margin-right: 0.7rem;
    }

    @media (min-width: 401px) and (max-width: 500px) {
      margin-left: 0;
      margin-right: 0.5rem;
      padding-bottom: 2rem;

      svg {
        font-size: 0.9rem;
        width: 28px;
      }
    }

    @media (min-width: 701px) and (max-width: 1050px) {
      margin-right: 1rem;
      margin-left: 0;
      padding-bottom: 2rem;

      svg {
        font-size: 0.4rem;
      }
    }
  }

  .grBack {
    margin-top: 3.5rem;

    button {
      font-size: 1rem;
      padding: 0.3rem 1rem;
      border: 1px solid black;
      background: #035eab;
      color: $white;

      &:hover {
        background: #035eab;
        color: $white;
      }
    }

    @media (min-width: 300px) and (max-width: 400px) {
      margin-top: 3rem;

      button {
        font-size: 0.7rem;
        padding: 0.2rem 0.2rem;
      }
    }

    @media (min-width: 401px) and (max-width: 500px) {
      margin-top: 1rem;

      button {
        font-size: 0.7rem;
        padding: 0.2rem 0.2rem;
      }
    }

    @media (min-width: 501px) and (max-width: 700px) {
      margin-top: 2.8 rem;

      button {
        font-size: 0.7rem;
        padding: 0.3rem 0.3rem;
      }
    }
  }
}

.articleBack {
  margin-top: 2rem;

  @include bp(md) {
    margin-top: 1.5rem;
  }

  button {
    font-size: 1rem;
    padding: 0.3rem 1rem;
    border: 1px solid black;
    background: #035eab;
    color: $white;

    &:hover {
      background: #035eab;
      color: $white;
    }
  }

  @include bp(md) {
    button {
      font-size: 0.8rem;
      padding: 0.2rem;
    }
  }
}

.article-main-noresult-section {
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

.fixed-content-container-image img {
  max-width: 100% !important;
}

@media (max-width: 960px){
  .grSingle{
    padding-top: 6.5rem;
  }
}

@media (max-width: 600px){
  .grSingle{
    padding-top: 1rem;
  }
}