@import "global";
@import "node_modules/react-modal-video/scss/modal-video.scss";

.imageGridItem {
  display: inline-block;
  box-sizing: border-box;
  float: left;
  padding: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s linear;
  transition-delay: 0.1s;
}

.imageWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.5s linear;

  cursor: pointer;
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.imageGridItem:hover .imageWrapper {
  background-position: right;
}

.imageGridItem a {
  display: none;
  font-size: 100%;
  color: #ffffff !important;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  cursor: pointer;
  text-decoration: none;
}

.imageGridItem:hover a,
.imageGridItem:focus a {
  display: block;
}

.imageGridItem:hover .imageWrapper:before,
.imageGridItem:focus .imageWrapper:before {
  display: block;
}

.imageWrapper:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(29, 38, 41, 0.65);
}

.column1 {
  width: 100%;
  transition: all 0.5s linear;
}

.column1Image {
  float: left;
  width: 30%;
  margin-right: 10px;
  padding-bottom: 30%;
  transition: all 0.5s linear;
}

.lightbox {
  /** Default lightbox to hidden */
  /*display: block;*/
  opacity: 1;
  /** Position and style */
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.88);
  visibility: visible;
  /*transition: visibility .3s, opacity .3s linear;*/
  transition: all 0.5s;
}

.lightbox img {
  /** Pad the lightbox image */
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  /*padding: 10px;*/
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  pointer-events: none;
}

.lightbox img:global(.opaque) {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1);
  pointer-events: auto;
}

.hide {
  /*display:none;*/
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s linear;
  /*transition: all .5s;*/
}

.buttons {
  margin: 0 auto;
  width: 100%;
  display: inline;
}

.buttons a {
  margin: 10px;
  /*box-shadow:0 0 10px rgba(0, 0, 0, 0.5);;*/
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.buttons a:link,
.buttons a:visited {
  background-color: white;
  color: black;
  /*border: 2px solid green;*/
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
}

.buttons a:hover,
.buttons a:active {
  background-color: rgba(0, 0, 0, 0.078);
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;

  /*color: white;*/
}

.secondaryButton a:link,
.secondaryButton a:visited {
  background-color: #00bcd4;
  color: white;
}

.secondaryButton a:hover,
.secondaryButton a:active {
  background-color: rgb(0, 161, 183);
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.defaultButton a:hover,
.defaultButton a:active {
  background-color: rgba(0, 0, 0, 0.078);
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.defaultButtonSelected {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.secondaryButtonSelected {
  background-color: rgb(0, 139, 161) !important;
}

.info {
  transition: all 5s;
  transition-delay: 2s;
}

.aricleBox {
  .MuiDivider-root {
    margin: 0rem -1rem;
    background-color: #a2a2a2;
    height: 1px;
    margin-top: 0.5rem;

    @include bp(md) {
      margin: 1.5rem 0rem 0rem;
    }
  }

  // .mainBox{
  //          &:nth-last-child(0){
  //         .MuiDivider-root{
  //             display:none;
  //         }
  //     }
  //     &:nth-last-child(1){
  //         .MuiDivider-root{
  //             display:none;
  //             @include bp(md){
  //                 display:block;
  //             }
  //         }
  //     }

  // }
  .media {
    @include bp(md) {
      flex-direction: column;
      height: auto !important;
    }

    .media-body {
      h5 {
        font-family: $font-family;
        color: $h2color;
        font-size: 1rem;
      }

      h6 {
        margin-top: 1rem;
      }

      @include bp(md) {
        h5 {
          font-size: 1.125rem;
        }

        margin-top: 1rem;
      }
    }
  }

  .imgWidth {
    width: 5rem;
    height: auto;

    img {
      width: 100%;
      height: 100%;
    }

    @include bp(md) {
      width: 50%;
    }
  }
}

.categoryMenu {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;

  @include bp(md) {
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  input {
    display: none;
  }

  label {
    // background: #faa7b7;
    // color: #fff;
    // font-weight: 700;
    padding: 5px 15px;
    border-radius: 25px;
    transition: 0.3s;
    cursor: pointer;
    color: #131313;
    font-family: $font-family;

    &:hover {
      background: $blue;
      color: $white;
    }
  }

  input:checked~label {
    background: $blue;
    padding: 5px 15px;
    border-radius: 25px;
    color: $white;
    // box-shadow: 5px 2px 15px $blue;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;

    @include bp(md) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      margin-bottom: 0rem;
    }
  }

  li {
    margin: 0px 10px;
  }
}

.categoryMenu1 {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;
  white-space: nowrap;

  @include bp(md) {
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;

    @include bp(md) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      margin-bottom: 0rem;
    }
  }

  li {
    margin: 0px 5px;
    padding: 5px 5px;
    border-radius: 25px;
    transition: 0.3s;
    cursor: pointer;
    color: #131313;
    font-family: $font-family;

    &:hover {
      background: $blue;
      color: $white;
    }

    &.active {
      background: $blue;
      color: $white;
    }
  }
}

.boxMain {
  .dialouge {
    padding: 0rem;
    overflow: hidden;

    &:nth-child(1) {
      padding-top: 0rem;
    }

    .player-wrapper {
      position: relative;
      padding-top: 76.25%;
      /* 720 / 1280 = 0.5625 */
      overflow: hidden;

      @include bp(sm) {
        padding-top: 56.25%;
      }
    }

    .react-player {
      position: absolute;
      top: 0rem;
      left: 0;
    }
  }
}

.videoLightbox {
  margin-top: 5rem;

  // .container {
  //     display: flex;
  //     align-items: stretch;
  //   }
  @include bp(sm) {
    margin-top: 2rem;
  }

  .imgBox {
    .MuiButton-label {
      width: 100%;
      height: 340px;

      img {
        width: 100%;
        height: 100%;
      }

      @include bp(md) {
        height: 270px;
      }
    }
  }

  .boxIcon {
    border: 3px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    border-radius: 100%;

    .playIconBox {
      position: relative;
      z-index: 1;
      background: $white;
      color: #ed1d22;
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
      padding: 10px;
    }
  }

  .cardText {
    font-family: $font-family;
    font-size: 1.125rem;
    color: $h2color;
    text-align: left;
  }

  // .card-deck{
  //     .card{
  //         flex:initial;margin-left:5px;margin-right:5px;
  //         &.MuiGrid-grid-md-4{
  //             max-width: 32.333333%;
  //             flex-basis: 32.333333%;
  //         }
  //     }
  // }
}

@include bp(sm) {
  .VideoGlimses-dialogPaper-1 {
    width: 85%;
    max-height: 19vh;
  }
}

.BannerBox.videoBanner {
  .sixteen-nine:before {
    //background:url(../images/articles.png) center center no-repeat;
    background: url(../images/videoBanner.png) center center no-repeat;
    //padding-top: 39.25%;
    padding-top: 23%;
    background-size: cover;
  }
}

.boxFeedback {
  left: 0;
  right: 0;
}

.videoMain {
  .container {
    // border: 1px solid black;
    max-width: 98%;

    @include bp(sm) {
      max-width: none;
    }

    .filter {
      display: flex;
      justify-content: end;

      @include bp(md) {
        justify-content: center;
      }

      .languageWebFilter {
        // margin-left: 0.6rem;
        margin-right: 3.5rem;

        .langSvg {
          font-size: 1.8rem;
        }

        span {
          margin-left: 0.1rem;
        }

        @include bp(md) {
          margin-right: 0;
        }
      }
    }

    .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 8px;
      margin-left: 8px;

      // border: 1px solid black;
      @include bp(md) {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  }
}

.formControlVideo {
  min-width: 200px;
  // margin-right: var(
  //   --theme-spacing-2
  // ); /* Adjust this as per your theme spacing */
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-right: 4.5rem;

  @include bp(md) {
    min-width: 90px;
    margin-right: 0rem;
    align-items: center;
  }
}

.selectVideo {
  width: 100%;
  padding: 10px 30px 10px 10px;

  border-color: #064e89;
  border-width: 2px;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: #064e89;

  @include bp(md) {
    font-size: 10px;
    padding: 10px 60px 10px 60px;
  }
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #064e89;

  @include bp(md) {
    right: 2px;
  }
}

.filter {
  // position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  font-size: 1rem;
}

.languageVideoFilter {
  // margin-left: 0.6rem;

  .langSvg {
    font-size: 1.8rem;

    @include bp(md) {
      font-size: 1.4rem;
    }
  }

  svg {
    font-size: 1.5rem;

    @include bp(md) {
      font-size: 1.2rem;
    }
  }

  span {
    margin-left: 0.3rem;

    @include bp(md) {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      margin-right: 1rem;
      margin-left: 0.1rem;

      // margin-right: 2rem;
    }
  }
}

.videolist {
  .custom-col-spacing {
    margin-bottom: -1rem;

    @include bp(md) {
      margin-bottom: 1rem;
    }
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-right: 5px;
    // padding-left: 3rem;
    // border: 1px solid black;
    margin-top: 3rem;

    @include bp(md) {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 2rem;

      &:first-of-type {
        margin-top: 2rem;
      }
    }

    @include bp(sm) {
      margin-top: 1rem;

      &:first-of-type {
        margin-top: 2rem;
      }
    }
  }

  padding-bottom: 0rem;
  margin-top: 0rem;

  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;

    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }

    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 85%;
    padding-bottom: 2rem;

    @media (min-width: 300px) and (max-width: 700px) {
      width: 100%;
    }

    @media (min-width: 701px) and (max-width: 1000px) {
      width: 80%;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      width: 100%;
    }

    // @media(min-width : 1050px) {
    //   // padding-bottom: 2rem;
    // }

    .card-body {
      height: 100%;
    }

    .Speaker {
      position: relative;
      height: max-content;
      padding-bottom: 2rem;



      @media (min-width:400px) and (max-width: 500px) {
        padding: 0;
      }

      @media (min-width: 700px) and (max-width : 1050px) {
        padding: 0;
      }

      h4 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 20%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 0.8rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }

      .imgBox {
        width: 100%;
        // height: 200px;
        // position: absolute;

        @include bp(md) {
          object-fit: cover;
          width: 100%;
          // height: 50%;
        }

        img {
          height: 300px;
          width: 100%;

          @media (min-width: 300px) and (max-width: 700px) {
            height: 200px;
          }
        }
      }

      p {
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 0.9rem;
        overflow: hidden;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }

      .playButton {
        position: absolute;
        margin-top: -2.75rem;
        margin-left: 87%;

        @media (min-width: 300px) and (max-width: 349px) {
          margin-left: 82.8%;
          margin-top: -2.5rem;
        }

        @media (min-width: 350px) and (max-width: 400px) {
          margin-left: 83.5%;
          margin-top: -2.5rem;
        }

        @media (min-width: 401px) and (max-width: 500px) {
          margin-left: 84.5%;
          margin-top: -2.5rem;
        }

        @media (min-width: 501px) and (max-width: 650px) {
          margin-left: 86%;
          margin-top: -2.5rem;
        }

        @media (min-width: 651px) and (max-width: 1000px) {
          // margin-left: 86%;
          margin-top: -2.5rem;
        }

        @media (min-width: 1001px) and (max-width: 1050px) {
          margin-left: 85%;
          margin-top: -2.7rem;
        }

        .playButtonIcon {
          background: white;
          color: red;
          padding: 10px;
          // border: 1px solid white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          svg {
            font-size: 2rem;

            @include bp(md) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;
      height: 2.5em;
      padding-right: 2px;
      max-width: 80%;
      margin-left: 0.8rem;

      @media (min-width: 300px) and (max-width: 700px) {
        font-size: 0.9rem;
      }
    }
  }

  .actionVideo {
    display: flex;
    margin-left: 0.8rem;

    @media (min-width: 300px) and (max-width: 400px) {
      // justify-content: space-between;
    }

    .bookmark {
      padding: 2px;
      border: 3px solid #064e89;
      color: #064e89;
      margin-right: 1.2rem;

      @media (min-width: 300px) and (max-width: 330px) {
        margin-right: 0.5rem;
        padding: 1.2px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.2px;

          svg {
            font-size: 18px;
          }
        }
      }

      @media (min-width: 331px) and (max-width: 400px) {
        margin-right: 0.5rem;
        padding: 1.4px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.4px;

          svg {
            font-size: 20px;
          }
        }
      }

      @media (min-width: 401px) and (max-width: 1050px) {
        margin-right: 0.5rem;

        button {
          padding: -5px;

          svg {
            font-size: 0.1rem;
          }
        }
      }
    }
  }

  // .RatingDialog-ratingContainer-33 {
  //   border: 2px solid black;
  //   border-radius: 5px;
  //   padding: 0.2rem 0.2rem;
  //   font-family: "gotham rounded medium";
  //   color: $black;
  //   font-size: 1rem;
  //   @media (min-width: 300px) and (max-width: 330px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;
  //     display: flex;
  //     align-items: center;
  //     svg {
  //       font-size: 1rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 331px) and (max-width: 365px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.2rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 366px) and (max-width: 400px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.25rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 401px) and (max-width: 700px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.4rem;
  //     }
  //   }
  // }
  .rating {
    border: 2px solid black;
    border-radius: 5px;
    padding: 0.2rem 0.2rem;
    font-family: "gotham rounded medium";
    color: $black;
    font-size: 1rem;

    @media (min-width: 300px) and (max-width: 330px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;
      display: flex;
      align-items: center;

      svg {
        font-size: 1rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 331px) and (max-width: 365px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 366px) and (max-width: 400px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
      }
    }

    @media (min-width: 401px) and (max-width: 700px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.4rem;
      }
    }
  }

  .share {
    margin-top: -2.1rem;
    margin-left: 5.8rem;
    margin-right: 1rem;

    @media (min-width: 300px) and (max-width: 330px) {
      margin-top: -2rem;
      margin-right: 0.7rem;
    }

    @media (min-width: 331px) and (max-width: 400px) {
      margin-right: 0.7rem;
    }

    @media (min-width: 401px) and (max-width: 500px) {
      margin-left: 0;
      margin-right: 0.5rem;
      padding-bottom: 2rem;

      svg {
        font-size: 0.9rem;
        width: 28px;
      }
    }

    @media (min-width: 701px) and (max-width: 1050px) {
      margin-right: 1rem;
      margin-left: 0;
      padding-bottom: 2rem;

      svg {
        font-size: 0.4rem;
      }
    }
  }
}