.offline-screen {
    height: 100dvh;
    position: relative;
    width: 100%;
    margin: auto;
}

.offline-content {
    margin: auto;
    text-align: center;
    width: 450px;
    // top: 30%;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    // background-color: red;

    .btn {
        background-color: #ECECEC;
        border: none;
        border-radius: 30px;
        margin: 10px;
        padding: 2px 10px;
        font-weight: 600;
    }

    .icon-retry {
        width: 1rem;
        height: 1rem;
        margin: 3px 5px 5px 3px;
    }

    .offline-text {
        font-size: 24px;
        font-weight: 600;
        margin: 20px 0 10px 0;
    }
}

.wifi-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
}


/* Add this to Offline.scss */
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (min-width: 100px) and (max-width: 700px) {

    .offline-content {
        background-color: #FAFAFA;
        width: 95%;
        padding-top: 1rem;

        .offline-text {
            font-size: 18px;
        }

        span {
            font-size: smaller;
        }

    }

    .wifi-bg {
        display: none;
    }
}