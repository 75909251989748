@import "global";

.sticky-top.navbar-light {
  // background:#D980FA;
  // background:#e5eaf5 ;
  // background: url(../images/wave.png) top left no-repeat;
  background-size: cover;
  //  padding-bottom:1.5rem;
  transition: 0.7s;
  // padding-bottom:1rem;
  // top:6rem;
  @include bp(lg) {
    padding-bottom: 0rem;
  }
  @include bp(md) {
    background: none;
  }
}

.MuiTooltip-popper {
  margin-top: -7px;
}

.NavBox {
  background: none;
  z-index: 9999;
  padding-top: 1.4rem !important;
  transition: 0.7s;
  @include bp(md) {
    // margin-top:1rem;
  }


  button {
    // box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    padding-left: 0rem;
  }

  .NavbarBox {
    @include bp(md) {
      // margin-top:1rem;
      margin: 0px !important;
      background-color: white !important;
      height: 100% !important;
      min-height: 100vh !important;
      width: 75% !important;
      padding: 15px 10px 10px 10px !important;
    }

    .nav-item {
      // background:$white;
      border-radius: 12px;
      margin-left: 4.5px;
      margin-right: 4.5px;

      .dropdown-menu {
        border-radius: 15px;
        padding: 0rem;
        .dropdown-divider {
          margin: 0rem;
        }
        span {
          &:first-child {
            .nav-link {
              border-radius: 15px 15px 0px 0px;
            }
          }
          &:last-child {
            .dropdown-divider {
              border: none;
            }
            .nav-link {
              border-radius: 0px 0px 15px 15px;
            }
          }
          &:hover {
            .nav-link {
              background: $light-blue;
              color: $white;
            }

            &:nth-child(1) {
              .nav-link {
                border-radius: 15px 15px 0px 0px;
              }
            }
            &:last-child {
              .nav-link {
                border-radius: 0px 0px 15px 15px;
              }
            }
          }
        }
        .nav-link {
          color: #363636;
          cursor: pointer;
          font-size: 0.875rem;
          font-weight: 600;

          @include bp(md) {
            padding-left: 20px;
          }
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .active_class {
          background: $light-blue;
          color: $white;
          span {
            color: $white !important;
          }
          &:last-child {
            border-radius: 0 0 15px 15px;
          }
          &:hover {
            background: $light-blue;
            color: $white !important;
          }
        }
      }

      @include bp(md) {
        margin-bottom: 0.3rem;
      }
      .nav-link.linkBox {
        background: $white;
        color: $blue;
        font-family: "gotham rounded medium";
        padding-bottom: 0rem;
        font-size: 14px;
        border-radius: 12px;
        padding: 10px 10px;
        transition: 0.3s;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        line-height: 1;
        // &:hover {
        //   background: $light-blue;
        //   color: $white;
        //   span {
        //     color: $white;
        //   }
        // }
        @include bp(md) {
          padding: 15px 10px 15px 10px;
          font-size: 15px;
          box-shadow: none;
          display: flex;
          align-items: center;
        }

        &.active_class {
          background: $light-blue;
          color: $white;
          border-radius: 12px;
          span {
            color: $white !important;
          }
        }
        span {
          color: #363636;
          font-family: $font-family;
          font-size: 10px;
        }
      }
    }
  }

  @include bp(md) {
    position: fixed;
    top: 0rem;
    width: 100%;
    padding: 0rem;
    .navbar-toggler {
      right: 7rem;
      top: 30px;
      background: $white;
      border: none;
      position: fixed;
      z-index: 9999;
      font-size: 0.975rem;
      transform: translateX(-100);
    }
    .navbar-collapse {
      transform: translateX(-100%);
      transition: transform 0.2s linear;
      top: 4.5rem;
      left: 0rem;
      height: 100%;
      bottom: 0rem;
      position: fixed;
      padding: 0px !important;
      width: 100%;
      &.collapsing {
        transform: translateX(-100%) !important;
        transition: transform 0.2s linear !important;
        top: 4.5rem;
        left: 0rem;
        bottom: 0rem;
        height: 100%;
        padding: 1rem;
        background: rgba(249, 249, 249, 0);
        position: fixed;
        width: 75%;
      }
      //  &.collapse:not(.show){
      //    transform: translateX(-100%) !important;
      //    transition: transform 0.5s ease-in-out !important;
      //    top:4rem;
      //    display:block;
      //  }
    }
    .navbar-collapse.show {
      transform: translateX(0);
      width: 100%;
      // background:$black;
      background: white;
      padding: 0px !important;
      position: fixed;
      height: 100%;
      left: 0rem;
      top: 4.5rem;
      bottom: 0rem;
      overflow-y: auto;
      background: rgba(40, 39, 39, 0.747);

      //   transition: transform 0.3s ease-in-out;
    }
  }

  @include bp(md) {
    .navbar-toggler {
      right: inherit;
      // top: 16px;
      left: 1rem;
    }
  }

  @include bp(sm) {
    .navbar-toggler {
      right: inherit;
      top: 1rem;
      left: 1rem;
    }
  }
}

.mobileNav {
  .navbar {
    @include bp(md) {
      padding: 0rem;
    }
  }
}

.mainAll {
  .navbar {
    @include bp(md) {
      padding: 0rem;
      position: fixed;
      top: 0rem;
      transition: 0.1s ease-in-out;
    }
  }
}

@include bp(md) {
  .no-scroll {
    overflow: hidden;
    height: 100vh;
  }
}


.menu-icon {
  display: none;
}

@include bp(md) {
  .menu-icon {
    display: inline-block; 
    margin-right: 10px !important;
    font-size: 1rem !important;
    color: #246eabc1 !important;
  }
}

.navbar-close-icon-mobile{
  display: none;
}

@include bp(md) {
  .navbar-close-icon-mobile {
    display: flex; 
    justify-content: end;
    width: 100%;
    color: black;
    margin-bottom: 10px !important;
    margin-right: 15px !important;
  }
}

.navmenu-main-icons-image {
  width: 25px !important;
  height: 25px !important;
}
.navmenu-sub-icons-image{
  width: 20px !important;
  height: 20px !important;
}