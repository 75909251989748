@import "global";
$size: 22rem;
$size1: 24rem;
.BannerBox.profileBanner {
  .sixteen-nine:before {
    //background:url(../images/articles.png) center center no-repeat;
    background: url(../images/profile_banner.jpg) center center no-repeat;
    //padding-top: 39.25%;
    padding-top: 23%;
    background-size: cover;
  }
}
.profile {
  margin: 2rem auto 0rem;
  .profileUpdate {
    @include bp(md) {
      max-width: 98%;
    }
    .headingUpdate {
      h2 {
        font-family: $font-family;
        color: #064e89;
        margin-top: 2rem;
        font-size: 1.5rem;
        @include bp(md) {
          font-size: 1rem;
        }
      }
    }
    .skipNow {
      display: flex;
      justify-content: flex-end;
      button {
        text-transform: none;
        background: #064e89;
        color: white;
        font-family: $font-family;
        padding: 5px 25px;
        @include bp(md) {
          padding: 5px 20px;
          font-size: 0.8rem;
        }
      }
    }

    .updateProfileRow {
      padding: 0;
      .col {
        min-width: 100%;
        padding: 0;
        .form-update-row {
          margin-bottom: 20px;

          .label {
            font-family: "gotham rounded medium";
          }
          .input-field {
            padding: 5px;
            input {
              padding: 12px 10px;
            }
            .MuiOutlinedInput-input {
              padding: 12px 10px;
              text-decoration: none;
              border: none;
            }
            .MuiSelect-filled.MuiSelect-filled {
              padding: 12px 10px;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 1rem;
              text-decoration: none;
            }
          }
          // .single-select-field {
          //   margin-top: 1rem;
          // }

          .input-email-field {
            padding: 5px;
            width: 100%;
            min-width: 100.8%;
            input {
              padding: 12px 10px;
            }
            .MuiOutlinedInput-input {
              padding: 12px 10px;
              text-decoration: none;
              border: none;
            }
            .MuiSelect-filled.MuiSelect-filled {
              padding: 12px 10px;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 1rem;
              text-decoration: none;
            }
          }
          .select-field {
            // margin-top: 1.5rem;

            width: 99.2%;
            margin: 5px;
            @media (min-width: 300px) and (max-width: 500px) {
              width: 97.5%;
            }
            @media (min-width: 500px) and (max-width: 700px) {
              width: 98.2%;
            }
            // @media (min-width: 700px) and (max-width: 1000px) {
            //   width: 98%;
            // }
            select {
              padding: 12px 10px;
              width: 100%;
            }
            .MuiSelect-filled.MuiSelect-filled {
              padding: 12px 10px;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 1rem;
              text-decoration: none;
            }
          }
          .select-subject-field {
            // margin-top: 1.5rem;

            width: 99.4%;
            margin: 5px;
            @media (min-width: 300px) and (max-width: 500px) {
              width: 97.5%;
            }
            @media (min-width: 500px) and (max-width: 700px) {
              width: 98.2%;
            }
            // @media (min-width: 700px) and (max-width: 1000px) {
            //   width: 98%;
            // }
            select {
              padding: 12px 10px;
              width: 100%;
            }
            .MuiSelect-filled.MuiSelect-filled {
              padding: 12px 10px;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 1rem;
              text-decoration: none;
            }
          }
          .MuiFilledInput-underline:before {
            display: none;
            border-bottom: none;
          }
          .select-field .dropButton {
            margin-top: 0.1rem;
            margin-left: 90%;
          }
        }
      }
      .button-update-container {
        display: flex;

        min-width: 101%;
        width: 100%;
        justify-content: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
        @include bp(md) {
          width: 100%;
        }
        button {
          font-size: 0.9rem;
          background-color: #064e89;
          padding: 5px 25px;
          color: white;
          font-weight: bold;
          text-transform: none;

          &:hover {
            background-color: #064e89;
            color: white;
          }
          @include bp(md) {
            padding: 5px 20px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@include bp(md) {
  .logInBox {
    margin-top: 2rem;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    .outLine {
      &::before {
        width: 100%;
        left: 0rem;
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .mainBox {
      &.outLine {
        padding-left: 1rem;
      }
      .tabBox {
        padding-left: 1.4rem;
        .formMainBox {
          padding: 0rem 15px 2rem 15px;
          margin-top: 0rem;
        }
      }
      .top {
        width: 100%;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        .signMainbox {
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
.mainProfile {
  margin-top: 3rem;
  @include bp(md) {
    max-width: 98%;
  }

  // .editForm {
  //   display: flex;
  //   flex-direction: column;
  //   margin-left: 5px;
  // }
  // .form-row {
  //   margin-top: 0;
  //   display: flex;
  //   align-items: baseline;
  //   margin-bottom: 0;
  // }
  // .label {
  //   min-width: 274px;
  //   font-size: 1rem;
  //   margin-right: 5px;
  //   line-height: 1.5;
  // }
  // .mandatory {
  //   color: red;
  //   margin-left: 2px;
  // }
  // .input-field {
  //   flex: 1;
  //   margin-right: 10px;
  // }
  // .single-select-field {
  //   flex: 1;
  //   margin-right: 10px;
  //   min-width: 120px;
  //   margin-top: 20px;
  //   margin-bottom: 5px;
  // }

  // .select-field {
  //   flex: 1;
  //   margin-right: 10px;
  //   min-width: 120px;
  //   margin-top: 20px;
  //   margin-bottom: 5px;
  // }
  // .select-field .dropButton {
  //   margin-top: 0.1rem;
  //   margin-left: 90%;
  // }
  // .button-container {
  //   display: flex;
  //   justify-content: flex-end;
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  // }
}

.securityHeader {
  border-top: 2px solid #064e89;

  margin-top: 0.2rem;
  .heading {
    margin-left: 1rem;
    background: #064e89;
    height: 50%;
    width: 12%;

    padding: 3px;

    h5 {
      color: white;
      font-size: 1.2rem;
      text-align: center;
      font-family: $font-family;
      margin-top: 0.1rem;
    }
    @media (min-width: 300px) and (max-width: 350px) {
      margin-left: 0;
      width: 28%;
      padding: 0;
      margin-top: 0;
      h5 {
        font-size: 0.9rem;
        margin-top: 0.5rem;
      }
    }
    @media (min-width: 351px) and (max-width: 700px) {
      margin-left: 0;
      width: 25%;
      padding: 0;
      margin-top: 0;
      h5 {
        font-size: 0.9rem;
        margin-top: 0.5rem;
      }
    }
    @media (min-width: 701px) and (max-width: 1050px) {
      margin-left: 0;
      width: 15%;
      padding: 0;
      margin-top: 0;
      h5 {
        font-size: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}
.securityContainer {
  margin-left: 1rem;
  width: 100%;
  // display: flex;
  // margin-bottom: 20px;
  // align-items: center;
  // margin-top: 2rem;

  .securityLabel {
    font-family: $font-family;
  }
  .securityPin {
    display: flex;

    .pinValue {
      // border: 1px solid black;
      padding: 5px;
      p {
        position: relative;
        top: 25%;
        font-size: 1.2rem;
      }
      @include bp(md) {
        margin-left: 0.2rem;
      }
    }
  }
}

.securityContainer button {
  margin-left: auto;
  font-size: 0.9rem;
  background-color: #064e89;
  padding: 5px 8px;
  color: white;
  font-weight: bold;
  text-transform: none;
}
.securityContainer button:hover {
  background-color: #064e89;
  color: white;
}

.action {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  margin-top: 2rem;
  width: 100%;
  @include bp(md) {
    margin-left: 0;
  }
  .delButton {
    button {
      font-size: 0.9rem;
      background-color: #064e89;
      padding: 5px 8px;
      color: white;
      font-weight: bold;
      text-transform: none;
    }
  }

  .logoutButton {
    button {
      font-size: 0.9rem;
      background-color: #064e89;
      padding: 5px 8px;
      color: white;
      font-weight: bold;
      text-transform: none;
      svg {
        margin-right: 0.2rem;
        font-size: 1.2rem;
      }
    }
  }
}

.profileInfo {
  margin-top: 1rem;
  .divContainer {
    // margin-top: 20px;
    // display: flex;
    margin-bottom: 30px;
    @include bp(md) {
      margin-bottom: 20px;
    }
  }
  .textLabel {
    // margin-right: 5px;
    // flex-basis: 30%;
    font-family: "gotham rounded medium";
  }
  .textValue {
    // flex-basis: 70%;
    // font-family: "gotham rounded medium";
    padding: 5px;
  }
}
.userNameRow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px;
  display: flex;
  background: linear-gradient(to right, #064e89, rgb(146, 197, 224));

  .userName {
    h5 {
      font-family: $font-family;
      font-size: 1.3rem;
      color: white;
      @include bp(md) {
        font-size: 1rem;
      }
    }
  }
}
.editButtonRow {
  margin-top: 1rem;
  .editButton {
    background: #064e89;
    color: white;
    font-size: 0.9rem;
    text-transform: none;
    font-family: $font-family;
    padding: 5px 8px;

    &:hover {
      background: #064e89;
      color: white;
    }
  }
}
.editProfile {
  margin-top: 2rem;
}
.form-row {
  margin-bottom: 30px;

  .label {
    font-family: "gotham rounded medium";
  }
  .input-field {
    padding: 5px;
    input {
      padding: 12px 10px;
    }
    .MuiOutlinedInput-input {
      padding: 12px 10px;
      text-decoration: none;
      border: none;
    }
    .MuiSelect-filled.MuiSelect-filled {
      padding: 12px 10px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 1rem;
      text-decoration: none;
    }
  }
  // .single-select-field {
  //   margin-top: 1rem;
  // }
  .select-field {
    // margin-top: 1.5rem;
    margin: 5px;
    select {
      padding: 12px 10px;
    }
    .MuiSelect-filled.MuiSelect-filled {
      padding: 12px 10px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 1rem;
      text-decoration: none;
    }
  }
  .MuiFilledInput-underline:before {
    display: none;
    border-bottom: none;
  }
  .select-field .dropButton {
    margin-top: 0.1rem;
    margin-left: 90%;
  }
}
.button-container {
  display: flex;

  width: 98%;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  @include bp(md) {
    width: 100%;
  }
  button {
    font-size: 0.9rem;
    background-color: #064e89;
    padding: 5px 8px;
    color: white;
    font-weight: bold;
    text-transform: none;

    &:hover {
      background-color: #064e89;
      color: white;
    }
  }
}
.changePin {
  .pin-label {
    font-family: "gotham rounded medium";
  }
  .pin-input {
    padding: 5px;
    margin-top: 4px;
    margin-bottom: 1rem;
    input {
      padding: 10px 10px;
    }
  }
  .pin-button {
    display: flex;
    justify-content: center;
    button {
      font-size: 0.9rem;
      background-color: #064e89;
      padding: 5px 20px;
      color: white;
      font-weight: bold;
    }
  }
}
