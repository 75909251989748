@import "global";

.mainEveSection {
  margin-top: 3rem;

  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 4rem;
    @include bp(md) {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  }
  .container {
    // border: 1px solid black;
    position: relative;
    @include bp(md) {
      // border: 1px solid black;
    }
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 20px;
    // border: 1px solid black;
    @include bp(md) {
      // border: 1px solid black;
    }
  }
  .prevButton {
    position: absolute;
    top: 50;
    left: 0;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -9rem;
    }

    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8.5rem;
      margin-left: 0.2rem;
    }
  }
  .singlePrevButton {
    position: absolute;
    top: 50%;
    left: 18rem;

    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -1rem;
      left: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -1rem;
      left: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -1rem;
      left: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      left: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      left: 14rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50;
    right: 0;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);

    @media (min-width: 300px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -9rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8.5rem;
      margin-right: 0.2rem;
    }
  }
  .singleNextButton {
    position: absolute;
    top: 50%;
    right: 18rem;
    margin-left: 1rem;

    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -1rem;
      right: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -1rem;
      right: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;

      right: 0.3rem;
      margin-top: -1rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      right: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      right: 14rem;
    }
  }
}
.upcomingWebHome {
  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 22px;
    padding-left: 22px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (min-width: 300px) and (max-width: 500px) {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      height: 100%;
    }

    .Speaker {
      position: relative;

      // background-color: #ececec;
      margin-top: 2.2rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }
      .mode {
        position: absolute;
        margin-top: -3.5rem;
        margin-bottom: 1.8rem;
        margin-left: -0.1rem;
        width: 100%;
        max-width: 84%;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        // border-radius: 2%;
        color: white;
        font-weight: bold;
        border-top-left-radius: 4%;
        border-bottom-left-radius: 4%;
        @media (min-width: 300px) and (max-width: 349px) {
          // margin-left: 1rem;
          margin-left: -0.5rem;
          max-width: 100%;
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          max-width: 90%;
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 450px) {
          max-width: 88%;
          font-size: 0.7rem;
        }
        @media (min-width: 451px) and (max-width: 550px) {
          font-size: 0.7rem;
        }
        @media (min-width: 750px) and (max-width: 900px) {
          max-width: 78%;
        }
        @media (min-width: 901px) and (max-width: 1000px) {
          max-width: 75%;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
        }
      }
      h4 {
        text-align: center;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 30%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 1rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;

        @include bp(md) {
          width: 90px;
          height: 90px;
        }
        @media (min-width: 300px) and (max-width: 349px) {
          width: 70px;
          height: 70px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 1rem;
        overflow: hidden;
        @include bp(sm) {
          font-size: 0.6rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: -0.7rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 330px) {
        font-size: 0.6rem;
      }
      @media (min-width: 331px) and (max-width: 349px) {
        font-size: 0.7rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.7rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.7rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    .topic {
      font-size: 1.1rem;
      line-height: 1.2;
      word-break: break-word;
      font-weight: bold;
      height: 3.5em;
      padding-right: 2px;
      @media (min-width: 300px) and (max-width: 330px) {
        padding: 1px;
        font-size: 0.6rem;
        width: 5%;
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding: 1px;
        font-size: 0.7rem;
        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.7rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.7rem;
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
  }
    @media (min-width: 300px) and (max-width: 500px) {
      .topic {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      .topic:hover {
        overflow: visible;
        white-space: normal;
        background-color: #f9f9f9;
        position: relative;
        z-index: 1;
      }
    }


  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1rem;
    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmoreContainer {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }
    .registered {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      // padding-left: 1rem; // Default larger size
      // padding-right: 1rem; // Default larger size

      span {
        margin-left: 0.2rem;
        svg {
          font-size: 1.4rem;
          color: rgb(88, 198, 88);
          font-weight: bold;
        }
      }
      @media (min-width: 300px) and (max-width: 330px) {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 0.9rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 359px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
    
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      // padding-left: 1rem; // Default larger size
      // padding-right: 1rem; // Default larger size
      @media (min-width: 300px) and (max-width: 330px) {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-size: 0.5rem;
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .readmore:disabled {
      background-color: #2f5d82; 
      color: #e0e0e0; 
      cursor: not-allowed;
      border-color: #b0b0b0; 
      opacity: 0.6; 
    }
    .bookmarkContainer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      // left: 24vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 330px) {
        padding: 0rem;
        margin-left: 2.8rem;
        font-size: 0rem;
        margin-top: 0.1rem;

        span {
          svg {
            font-size: 1.2rem;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 3.4rem;
        font-size: 0rem;
        margin-top: 0.3rem;

        span {
          svg {
            font-size: 1.2rem;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 365px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 366px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 4.8rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }
      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        margin-left: 12rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 5.8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
    }
  }
}
.trainWebHome {
  margin-top: 2rem;
  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 22px;
    padding-left: 22px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (min-width: 300px) and (max-width: 500px) {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;
      // border: 1px solid black;
      // background-color: #ececec;
      margin-top: 1.3rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }

      h4 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 20%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 0.8rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;

        @media (min-width: 300px) and (max-width: 349px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 0.9rem;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 450px) {
          font-size: 0.7rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 0.2rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      height: 70%;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      vertical-align: top;
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.6rem;
        line-height: 1;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    @include bp(md) {
      font-size: 0.8rem;
    }
    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;

      height: 3.2em;
      padding-right: 2px !important;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.7rem;
        width: 5%;
      }
      @media (min-width: 300px) and (max-width: 500px) {
            word-break: break-word !important;
            font-weight: bold !important;
            font-size: 0.7rem !important;
            max-width: 100px !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;      
          .topic:hover {
            overflow: visible;
            white-space: normal;
            background-color: #f9f9f9;
            position: relative;
            z-index: 1;
          }
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
    // img {position:absolute; z-index:0;}
  }

  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1rem;
    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmoreContainer {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      // padding-left: 1rem; // Default larger size
      // padding-right: 1rem; // Default larger size
      @media (min-width: 300px) and (max-width: 330px) {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-size: 0.5rem !important;
        span {
          font-size: 0.5rem;
        }
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem !important;
        span {
          font-size: 0.5rem;
        }
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem !important;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem !important;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        font-size: 0.5rem !important;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .bookmarkContainer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      // left: 24vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 330px) {
        padding: 0rem;
        margin-left: 2.8rem;
        font-size: 0rem;
        margin-top: 0.1rem;

        span {
          svg {
            font-size: 1.2rem;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 3.4rem;
        font-size: 0rem;
        margin-top: 0.3rem;

        span {
          svg {
            font-size: 1.2rem;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 365px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 366px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 4.8rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }
      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        margin-left: 12rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 5.8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
    }
  }
}
.articleGrHome {
  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 2rem;
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1rem;
    }
  }
  .container {
    position: relative;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 12px;
    margin-left: 12px;
    // border: 1px solid black;
  }
  .prevButton {
    position: absolute;
    top: 50%;
    left: 0;

    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
  }
  .singlePrevButton {
    position: absolute;
    top: 50%;
    left: 18rem;

    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -1rem;
      left: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -1rem;
      left: 0.2rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -1rem;
      left: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      left: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      left: 14rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50%;
    right: 0;
    margin-left: 1rem;

    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .singleNextButton {
    position: absolute;
    top: 50%;
    right: 18rem;
    margin-left: 1rem;

    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -1rem;
      right: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -1rem;
      right: 0.2rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -1rem;
      right: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      margin-top: 0;
      right: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      right: 14rem;
    }
  }
}
.articleGrMainHome {
  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    // border: 1px solid black;
    padding-right: 28px;
    padding-left: 28px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 300px) and (max-width: 349px) {
      padding-right: 30px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @media (min-width: 350px) and (max-width: 400px) {
      padding-right: 35px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 400px) and (max-width: 700px) {
      padding-right: 34px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 701px) and (max-width: 1000px) {
      padding-right: 10px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 96%;
    margin-left: 1.2rem;
    .card-body {
      display: flex;
      align-items: flex-start;
    }

    .mainImage {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
      width: 140px;
      height: 140px;
      img {
        border: 3px solid #064e89;
        border-radius: 5%;
        height: auto; /* Adjust as needed */
        height: 110%;
        width: 110%;
        object-fit: cover;
        position: relative;
        transform: translate(0, 1%);
        left: -30%; /* Move image 20% to the left */
      }
      @media (min-width: 300px) and (max-width: 349px) {
        img {
          height: 75%;
          width: 65%;
          left: -42%;
        }
      }
      @media (min-width: 350px) and (max-width: 400px) {
        img {
          height: 80%;
          width: 75%;
          left: -40%;
        }
      }
      @media (min-width: 400px) and (max-width: 700px) {
        img {
          height: 85%;
          width: 85%;
          left: -40%;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      margin-left: -1.8rem;
      .topic {
        min-height: 6rem;
        display: flex;
        // flex: 3;
        text-align: justify;
        flex-direction: column;
        // border: 1px solid black;
        h4 {
          font-family: $font-family;
          font-size: 1.1rem;
          color: #064e89;
          font-weight: bold;

          // flex: 3;
          @include bp(md) {
            font-size: 1rem;
          }
        }
      }
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -4.6rem;
      
        .topic {
          min-height: 3rem;
          max-width: 100% !important;
          word-break: break-word !important;
          // border: 1px solid black;

          h4 {
            // text-overflow: ellipsis;
            font-size: 0.63rem;
            word-break: break-word !important;
            font-weight: bold !important;
            max-width: 100% !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 400px) {
        margin-left: -4rem;
        
        .topic {
          min-height: 4.3rem;
          max-width: 100% !important;
          word-break: break-word !important;
          
          h4 {
            font-size: 0.65rem;
            font-weight: bold !important;
            max-width: 100% !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }
      }
      
      @media (min-width: 400px) and (max-width: 500px) {
        margin-left: -3.8rem;
        // border: 1px solid black;
        .topic {
          min-height: 4.5rem;
          max-width: 100% !important;
          word-break: break-word !important;
          h4 {
            font-size: 0.7rem;
            font-weight: bold !important;
            max-width: 100% !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }
      }
      @media (min-width: 501px) and (max-width: 700px) {
        margin-left: -3rem;

        // border: 1px solid black;
        .topic {
          min-height: 4.5rem;
          max-width: 100% !important;
          word-break: break-word !important;
          h4 {
            font-size: 1rem;
            font-weight: bold !important;
            max-width: 100% !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        .topic {
          min-height: 6.5rem;

          h4 {
            font-size: 1rem;
          }
        }
      }
      .date {
        // margin-top: 1rem;
        h4 {
          font-family: $font-family;
          font-size: 1rem;
          color: gray;
          font-weight: normal;
          @media (min-width: 300px) and (max-width: 349px) {
            font-size: 0.5rem;
          }
          @media (min-width: 350px) and (max-width: 400px) {
            font-size: 0.56rem;
          }
          @media (min-width: 400px) and (max-width: 500px) {
            font-size: 0.6rem;
          }
          @media (min-width: 501px) and (max-width: 700px) {
            font-size: 0.8rem;
          }
          @media (min-width: 1001px) and (max-width: 1050px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      margin-left: 8.2rem;
      margin-top: -4.52rem;
      margin-bottom: 2.5em;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-bottom: 2em;
        margin-top: -7.1rem;
        margin-left: 5.3rem;
      }
      @media (min-width: 350px) and (max-width: 400px) {
        margin-bottom: 2em;
        margin-top: -6.9rem;
        margin-left: 6rem;
      }
      @media (min-width: 400px) and (max-width: 700px) {
        margin-bottom: 2em;
        margin-top: -6.5rem;
        margin-left: 6rem;
      }
      .readmore {
        border: 1px solid #064e89;
        color: white;
        font-family: $font-family;
        background: #064e89;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        @media (min-width: 300px) and (max-width: 349px) {
          padding-top: 0.38rem;
          padding-bottom: 0.38rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.5rem;
        }
        @media (min-width: 350px) and (max-width: 400px) {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.5rem;
        }
        @media (min-width: 401px) and (max-width: 500px) {
          font-size: 0.5rem;
        }
        // @media (min-width: 371px) and (max-width: 400px) {
        //   padding-top: 0.38rem;
        //   padding-bottom: 0.38rem;
        //   padding-left: 0.5rem;
        //   padding-right: 0.5rem;
        //   font-size: 0.54rem;
        // }
        @media (min-width: 750px) and (max-width: 900px) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.6rem;
        }
        @media (min-width: 901px) and (max-width: 1000px) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.6rem;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.6rem;
        }
        @media (min-width: 401px) and (max-width: 500px) {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: 0.58rem;
        }
        @media (min-width: 501px) and (max-width: 700px) {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: 0.58rem;
        }
        //   @include bp(md) {
        //     padding-top: -1rem;
        //     padding-bottom: -1rem;
        //     padding-left: 1rem;
        //     padding-right: 1rem;
        //     font-size: 0.4rem;
        //   }
      }
      .bookmark {
        border: 2px solid #064e89;
        padding: 1.5px;
        margin-left: 16rem;
        font-size: 1px;
        margin-top: 0.4rem;
        color: #064e89;

        @media (min-width: 300px) and (max-width: 330px) {
          padding: 0rem;
          margin-left: 3rem;
          font-size: 0rem;
          margin-top: 0rem;

          span {
            svg {
              font-size: 1.2rem;
            }
          }
        }
        @media (min-width: 331px) and (max-width: 349px) {
          padding: 0rem;
          margin-left: 4.5rem;
          font-size: 0rem;
          margin-top: 0.3rem;

          span {
            svg {
              font-size: 1.2rem;
            }
          }
        }
        @media (min-width: 350px) and (max-width: 370px) {
          padding: 0rem;
          margin-left: 3.8rem;
          font-size: 0rem;
          margin-top: 0rem;
        }
        @media (min-width: 371px) and (max-width: 380px) {
          padding: 0rem;
          margin-left: 5rem;
          font-size: 0rem;
          margin-top: 0rem;
        }
        @media (min-width: 381px) and (max-width: 400px) {
          padding: 0rem;
          margin-left: 5.5rem;
          font-size: 0rem;
          margin-top: 0rem;
          span {
            svg {
              font-size: 1.4rem;
            }
          }
        }
        @media (min-width: 750px) and (max-width: 900px) {
          padding: 0rem;
          margin-left: 21.5rem;
          font-size: 0rem;
          margin-top: 0rem;
        }
        @media (min-width: 901px) and (max-width: 1000px) {
          padding: 0rem;
          margin-left: 21rem;
          font-size: 0rem;
          margin-top: 0rem;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          padding: 0rem;
          margin-left: 8.5rem;
          font-size: 0rem;
          margin-top: 0rem;
        }
        @media (min-width: 401px) and (max-width: 420px) {
          padding: 0rem;
          margin-left: 5.8rem;
          font-size: 0rem;
          margin-top: -0.1rem;
        }
        @media (min-width: 421px) and (max-width: 450px) {
          padding: 0rem;
          margin-left: 6.2rem;
          font-size: 0rem;
          margin-top: -0.1rem;
        }
        @media (min-width: 451px) and (max-width: 550px) {
          padding: 0rem;
          margin-left: 12rem;
          font-size: 0rem;
          margin-top: -0.1rem;
        }
      }
    }
  }
}
.newsHome {
  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    // border: 1px solid black;
    padding-right: 15px;
    padding-left: 18px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 300px) and (max-width: 349px) {
      padding-right: 15px;
      padding-left: 5px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @media (min-width: 350px) and (max-width: 400px) {
      padding-right: 18px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 400px) and (max-width: 700px) {
      padding-right: 18px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 701px) and (max-width: 1000px) {
      padding-right: 10px;
      padding-left: 20px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  h2 {
    margin-top: 1rem;
    font-family: $font-family;
    color: #064e89;
    @include bp(md) {
      font-size: 1.5rem;
    }
  }
  .card {
    // box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
    overflow: visible;
    height: 100%;
    // margin-bottom: 1rem;
    // border: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    background: transparent;

    .mainInfo {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      position: relative;
      padding: 2%;
      @include bp(md) {
        padding-right: 1px;
        padding-left: 1px;
        margin-bottom: -1.5rem;
      }
      .mainImage {
        width: 160px;
        height: 160px;
        img {
          //   border-radius: 5%;
          height: auto;
          height: 100%;
          width: 100%;
        }
        @media (min-width: 300px) and (max-width: 349px) {
          width: 120px;
          height: 120px;
          margin-left: -1rem;
          img {
            height: 60%;
            width: 60%;
            // left: -28%;
          }
        }
        @media (min-width: 350px) and (max-width: 400px) {
          width: 100px;
          height: 100px;
          margin-left: -0.5rem;
          img {
            height: 70%;
            width: 70%;
            // left: -22%;
          }
        }
        @media (min-width: 400px) and (max-width: 700px) {
          width: 150px;
          height: 120px;
          margin-left: -1rem;
          img {
            height: 75%;
            width: 75%;
            // left: -22%;
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-left: 0.5rem;
        // margin-top: -2rem;
        .topic {
          height: 5rem;
          display: flex;

          flex: 3;
          h4 {
            font-family: $font-family;
            font-size: 1rem;
            text-align: justify;
            font-weight: bolder;

            span {
              font-size: 0.8rem;
              color: #064e89;
            }
          }
        }
        @media (min-width: 300px) and (max-width: 349px) {
          margin-left: -1rem;
          max-width: 100%;
          // border: 1px solid black;
          .topic {
            flex: 0.8;
            height: 1rem;
            h4 {
              font-size: 0.6rem;
              span {
                font-size: 0.6rem;
              }
            }
          }
        }
        @media (min-width: 350px) and (max-width: 400px) {
          margin-left: 0.1rem;
          max-width: 100%;
          // border: 1px solid black;
          .topic {
            flex: 1;
            h4 {
              font-size: 0.7rem;
              span {
                font-size: 0.5rem;
              }
            }
          }
        }
        @media (min-width: 400px) and (max-width: 700px) {
          margin-left: -0.2rem;

          max-height: 70%;
          .topic {
            flex: 4.4;

            h4 {
              font-size: 0.7rem;
              span {
                font-size: 0.6rem;
              }
            }
          }
        }
        .date {
          flex: 1;
          //   position: absolute;
          h4 {
            font-family: $font-family;
            font-size: 1rem;
            color: gray;
            @media (min-width: 300px) and (max-width: 349px) {
              font-size: 0.5rem;
            }
            @media (min-width: 350px) and (max-width: 400px) {
              font-size: 0.6rem;
            }
            @media (min-width: 401px) and (max-width: 700px) {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }
}
.register-webinar-modal-home-header-mobile{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.register-webinar-modal-home-close-btn{
  width: 24px !important;
  height: 17px !important;
  display: flex !important;
  margin-right: 10px !important;
  cursor: pointer !important;
}

@media (min-width: 320px) and (max-width: 500px) {
  .register-webinar-modal-home-header-mobile{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .register-webinar-modal-home-close-btn{
    width: 24px !important;
    height: 17px !important;
    display: flex !important;
    margin-right: 10px !important;
  }
  .register-webinar-modal-home-header-txt-mobile{
    width: 80% !important;
  }
}

