@import "global";

body {
  padding-bottom: 0rem;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(($height / $width) * 100%);
  }

  >.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.top-110px{
  margin-top: 110px;
}

.NavBar {
  //    background: url(../images/wave.png) center center no-repeat;
  background: #e5e9f5;
  top: 6rem;
}

.BannerBox {
  //  display:none;
  margin-top: 95px;

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;

    @include bp(md) {
      font-size: 1rem;
    }
  }

  .sixteen-nine {
    @include aspect-ratio(16, 9);
    margin-top: 0px;
  }

  .sixteen-nine {
    position: relative;
    z-index: 1;
  }

  .sixteen-nine:before {
    display: block;
    content: "";
    background: url(../images/aboutus_banner.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    // padding-top: 56.25%;
    //padding-top:40.25%;
    padding-top: 23%;
  }

  .sixteen-nine>.content {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;

    @include bp(sm) {
      top: 20%;
    }

    h2 {
      font-size: 3.3125rem;
      color: $white;
      font-weight: 600;

      @include bp(md) {
        font-size: 2rem;
      }

      @include bp(sm) {
        font-size: 1.5rem;
      }
    }
  }
}

.stats {
  h4 {
    font-family: $font-family;
    color: $blue;
    font-weight: bold;
    text-align: center;

    @include bp(md) {
      font-size: 1.2rem;
    }
  }

  p {
    font-family: $font-family;
  }

  .workshop {
    margin-right: 3rem;

    @media (min-width: 300px) and (max-width: 400px) {
      margin-right: 1rem;
      font-size: 0.5rem;
      margin-left: 2rem;
    }

    @media (min-width: 401px) and (max-width: 500px) {
      margin-right: 1rem;
      font-size: 0.6rem;
      margin-left: 2.8rem;
    }

    @media (min-width: 501px) and (max-width: 750px) {
      margin-right: 1.2rem;
      font-size: 0.8rem;
      margin-left: 3.4rem;
    }

    @media (min-width: 751px) and (max-width: 1000px) {
      font-size: 0.9rem;
      margin-left: 3.9rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      font-size: 0.9rem;
      margin-left: 1rem;
    }
  }

  .beneficiary {
    margin-left: 3rem;

    @media (min-width: 300px) and (max-width: 400px) {
      margin-left: 2rem;
      font-size: 0.5rem;
    }

    @media (min-width: 401px) and (max-width: 539px) {
      margin-left: 2.5rem;
      font-size: 0.6rem;
    }

    @media (min-width: 501px) and (max-width: 750px) {
      margin-left: 3.2rem;
      font-size: 0.8rem;
    }

    @media (min-width: 751px) and (max-width: 1000px) {
      margin-left: 3.2rem;
      font-size: 0.9rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      font-size: 0.9rem;
      margin-left: 2rem;
    }
  }

  .graph {
    // border: 1px solid black;
    width: 100%;
    display: flex;

    img {
      margin-top: 0.5rem;
      margin-right: 2rem;
      width: 90%;

      @include bp(md) {
        // margin-right: 3.8rem;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .impNote {
    @include bp(md) {
      font-size: 0.9rem;
    }
  }

  .note {
    // border: 1px solid black;

    p {
      text-align: justify;
    }

    @include bp(md) {
      font-size: 1rem;
    }
  }
}

.Overview {
  margin-top: 3rem;

  @include bp(md) {
    margin-top: 0.8rem;
  }

  h2 {
    span.blueHead {
      color: $blue;
    }

    font-family: $font-family;
    color: $blue;
    font-weight:normal // span{font-family:$font-family;color:$blue;font-weight:normal;}

    span {
      font-family: $font-family;
      color: $blue;
      font-weight: normal;
    }

    @include bp(md) {
      font-size: $head-md;
      text-align: center;
    }

    @include bp(sm) {
      font-size: $head-small;
    }
  }

  h5 {
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: $font-family;
    margin-left: 1rem;

    @include bp(md) {
      margin-left: 0;
      text-align: center;
    }
  }

  p {
    // font-size:1.375rem;
    font-family: $font-family;
    text-align: justify;

    // margin-top: 2.5rem;margin-bottom:0rem;
    @include bp(md) {
      margin-top: 1.5rem;
    }
  }

  .image1 {
    margin-top: -1.3rem;
    // border: 1px solid black;
    width: 100%;

    img {
      width: 100%;
    }

    @include bp(md) {
      margin-top: 2rem;
    }
  }

  .image2 {

    // margin-top: 7rem;
    @include bp(md) {
      margin-top: 1rem;
    }

    width: 100%;

    img {
      width: 100%;
    }
  }
}

.AboutBox {
  margin-top: 5rem;

  @include bp(md) {
    margin-top: 2rem;
  }

  img {
    height: 90%;
  }

  h2 {
    span.blueHead {
      color: $blue;
    }

    font-family: $font-family;
    color: $blue;
    font-weight:normal // span{font-family:$font-family;color:$blue;font-weight:normal;}

    span {
      font-family: $font-family;
      color: $blue;
      font-weight: normal;
    }

    @include bp(md) {
      font-size: $head-md;
    }

    @include bp(sm) {
      font-size: $head-small;
    }

    @include bp(md) {
      margin-top: 2rem;
    }
  }

  p {
    // font-size:1.375rem;
    font-family: $font-family;

    // margin-top: 2.5rem;margin-bottom:0rem;
    @include bp(md) {
      margin-top: 1rem;

      text-align: justify;
    }

    @media (min-width: 300px) and (max-width: 400px) {
      margin-top: 0.8rem;
      font-size: 0.7rem;
    }

    @media (min-width: 401px) and (max-width: 500px) {
      font-size: 0.76rem;
    }

    @media (min-width: 501px) and (max-width: 700px) {
      font-size: 0.8rem;
    }
  }

  ul li {
    font-family: $font-family;
  }

  .aboutList {
    .MuiListItemIcon-root {
      min-width: 2rem;
    }

    @include bp(md) {
      .w-50 {
        width: 100% !important;
      }
    }

    svg {
      font-size: 1rem;
      fill: #3e46e9;
    }

    .MuiTypography-displayBlock {
      font-family: $font-family;
    }
  }

  .aboutList.trainingList {
    .MuiListItem-root {
      align-items: inherit;
    }
  }

  .aboutList.trainingList.smalltraining {
    .MuiListItem-root {
      align-items: inherit;

      @include bp(md) {
        display: block;
      }
    }
  }

  .tableAbout {
    white-space: nowrap;

    .MuiTableCell-head {
      font-size: 1.25rem;

      @include bp(md) {
        font-size: 1rem;
      }
    }

    .MuiTableCell-root {
      // font-size:0.975rem;
      font-family: $font-family;
      font-size: 1.25rem;

      @include bp(md) {
        font-size: 1rem;
      }
    }
  }
}

.aboutInfo {
  margin-top: 1rem;

  .container {
    max-width: 100%;
    min-width: 100%;
    margin-right: 1rem;

    // border: 1px solid black;
    @include bp(md) {
      max-width: none;
      min-width: none;
      margin-right: 0;
    }
  }

  .rightContainer {
    margin-left: 20%;
    margin-top: 1rem;

    @include bp(md) {
      margin-left: 0.2rem;
    }

    .rightCard {
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;

      overflow: hidden;
      padding: 20px;

      box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);

      @media (min-width: 300px) and (max-width: 400px) {
        padding: 0px 8px;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        padding: 0px 8px;
      }

      @media (min-width: 500px) and (max-width: 700px) {
        padding: 5px 8px;
      }
    }

    .rightCircle {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: #003366;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;
      margin-right: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      @media (min-width: 300px) and (max-width: 400px) {
        width: 105px;
        height: 105px;
        margin-right: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }

      @media (min-width: 401px) and (max-width: 500px) {
        width: 120px;
        height: 120px;
        margin-right: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }

      @media (min-width: 501px) and (max-width: 700px) {
        width: 130px;
        height: 130px;
        margin-right: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }
    }

    .rightInfo {
      flex: 1;
      padding: 10px 20px;

      @include bp(md) {
        padding: 0px 5px 0px 0px;
      }

      p {
        margin-top: 1rem;
        padding: 10px;
        font-size: 0.9rem;
        font-family: $font-family;
        text-align: justify;

        @media (min-width: 300px) and (max-width: 400px) {
          padding: 0px;
          font-size: 0.6rem;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          padding: 0px;
          font-size: 0.6rem;
        }

        @media (min-width: 501px) and (max-width: 700px) {
          padding: 0px;
          font-size: 0.7rem;
        }
      }
    }
  }

  .leftContainer {
    margin-right: 20%;
    margin-left: -1.2rem;
    margin-top: 1rem;

    @include bp(md) {
      margin-right: 0.8rem;
      margin-left: -0.5rem;
    }

    .leftCard {
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;

      overflow: hidden;
      padding: 20px;

      box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);

      @media (min-width: 300px) and (max-width: 400px) {
        padding: 0px 8px;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        padding: 0px 8px;
      }

      @media (min-width: 500px) and (max-width: 700px) {
        padding: 5px 8px;
      }
    }

    .leftCircle {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: #4898e8;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      margin-left: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      @media (min-width: 300px) and (max-width: 400px) {
        width: 105px;
        height: 105px;
        margin-left: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }

      @media (min-width: 401px) and (max-width: 500px) {
        width: 120px;
        height: 120px;
        margin-left: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }

      @media (min-width: 501px) and (max-width: 700px) {
        width: 130px;
        height: 130px;
        margin-left: 5px;
        text-align: center;
        padding: 0px 5px;
        font-size: 0.7rem;
      }
    }

    .leftInfo {
      padding: 10px 20px;
      margin-top: 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include bp(md) {
        padding: 0px 0px 0px 4px;
      }

      p {
        padding: 10px;
        font-size: 0.9rem;
        font-family: $font-family;
        text-align: justify;

        @media (min-width: 300px) and (max-width: 400px) {
          padding: 0px;
          font-size: 0.6rem;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          padding: 0px;
          font-size: 0.6rem;
        }

        @media (min-width: 501px) and (max-width: 700px) {
          padding: 0px;
          font-size: 0.7rem;
        }
      }
    }
  }
}

.addtionalInfo {
  margin-top: 2rem;

  .additionalData {
    text-align: center;

    @include bp(md) {
      text-align: justify;
    }

    p {
      font-size: 0.8rem;
      text-align: center;
      font-family: $font-family;
    }
  }
}

.MeetTemBox {
  margin-top: 5rem;
  position: relative;

  @include bp(md) {
    margin-top: 2rem;
    margin-bottom: -1px;
  }

  h2 {
    font-family: $font-family;
    text-align: center;
    color: $blue;
    margin-bottom: 0rem;
    font-size: $head-size;

    @include bp(md) {
      font-size: $head-md;
    }

    @include bp(sm) {
      font-size: $head-small;
    }
  }

  &::before {
    content: "";
    background: url("../images/team.jpg") bottom left no-repeat;
    position: absolute;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}

.team-block {
  position: relative;
  cursor: pointer;
}

.team-block .inner-box {
  position: relative;
  padding: 40px 70px 28px 0px;
}

.team-block .inner-box:before {
  position: absolute;
  content: "";
  right: 0px;
  top: 0px;
  left: 70px;
  bottom: 0px;
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}

.team-block .inner-box::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 70px;
  bottom: 0px;
  width: 0%;
  border-radius: 10px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  background-image: -ms-linear-gradient(top, #0800b5 0%, #f700f1 100%);
  background-image: -moz-linear-gradient(top, #0800b5 0%, #f700f1 100%);
  background-image: -o-linear-gradient(top, #0800b5 0%, #f700f1 100%);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #0800b5),
      color-stop(100, #f700f1));
  background-image: -webkit-linear-gradient(top, #0800b5 0%, #f700f1 100%);
  background-image: linear-gradient(to bottom, #0800b5 0%, #f700f1 100%);
}

.team-block .inner-box:hover::after {
  width: 81%;
}

.team-block .inner-box .social-icons {
  position: absolute;
  right: 25px;
  top: 100px;
  z-index: 1;
}

.team-block .inner-box .social-icons li {
  position: relative;
  margin-bottom: 20px;
}

.team-block .inner-box .social-icons li a {
  position: relative;
  color: #e400e1;
  font-size: 18px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team-block .inner-box:hover .lower-content h3 a,
.team-block .inner-box:hover .social-icons li a,
.team-block .inner-box:hover .lower-content .designation {
  color: #ffffff;
}

.team-block .inner-box .image {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: inset 0px 0px 35px rgba(0, 0, 0, 0.2);
}

.team-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.team-block .inner-box .lower-content {
  position: relative;
  text-align: left;
  z-index: 1;
  padding: 30px 15px 0px 110px;
}

.team-block .inner-box .lower-content h3 {
  position: relative;
  color: #2200bd;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3em;
}

.team-block .inner-box .lower-content h3 a {
  position: relative;
  color: #2200bd;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team-block .inner-box .lower-content .designation {
  position: relative;
  margin-top: 5px;
  color: #777777;
  font-size: 14px;
}

.team-block.style-two .inner-box .lower-content h3 a,
.team-block.style-two .inner-box .social-icons li a {
  color: #ff9600;
}

.team-block.style-two .inner-box:hover .lower-content h3 a,
.team-block.style-two .inner-box:hover .social-icons li a {
  color: #ffffff;
}

.team-block.style-two .inner-box::after {
  background-image: -ms-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -moz-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -o-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #ffeb3c),
      color-stop(100, #ff9600));
  background-image: -webkit-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: linear-gradient(to bottom, #ffeb3c 0%, #ff9600 100%);
}

//
.aboutVideobox {
  padding-top: 4.9rem;
  background: #fdf3ff;

  @include bp(sm) {
    padding-top: 2.5rem;
  }
}

.BestInstructor {
  margin-top: 4rem;
  position: relative;

  @include bp(md) {
    margin-top: 2rem;
  }

  .imgBox {
    width: 70%;
    margin-left: 6rem;

    @include bp(md) {
      width: 36%;
    }

    @include bp(sm) {
      width: 70%;
    }
  }

  &::before {
    content: "";
    background: url("../images/instructor-bg.png") left top no-repeat;
    // height:1157px;
    height: 950px;
    width: 100%;
    background-size: contain;
    position: relative;
    left: 0rem;
    top: 0rem;
    z-index: 1;
    display: inline-block;

    @include bp(md) {
      height: 163vh;
    }

    @include bp(sm) {
      height: 90vh;
    }
  }

  .teacherBoxMain {
    .teacherImage {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 1rem;
      top: 0rem;

      @include bp(md) {
        left: 0rem;
      }
    }

    .instructBox {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      right: 1rem;
      top: 0rem;

      @include bp(md) {
        position: static;
      }
    }
  }

  .InstructorHead {
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 6rem;

    @include bp(md) {
      margin-top: 0rem;
    }

    h3 {
      font-family: $font-family;
      font-size: 2.25rem;
      font-weight: 600;

      @include bp(md) {
        font-size: 1.5rem;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 5rem;
      height: 2px;
      background: $blue;
      bottom: 0rem;
      left: 0rem;
    }

    p {
      font-size: 0.975rem;
      color: $h2color;
      font-family: $font-family;
    }
  }

  .Instructor {
    li {
      margin-bottom: 2.625rem;
      background: url("../images/instructorbg.png") left top 5px no-repeat;
      padding-left: 40px;
      background-size: 25px;
      font-size: 1.3125rem;
      font-family: $font-family;
      color: $h2color;

      @include bp(md) {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.Activities {
  position: relative;
  padding-bottom: 3rem;

  .carousel-indicators {
    bottom: -5rem;

    @include bp(sm) {
      bottom: -4rem;
    }

    .active {
      background: $blue;
    }

    li {
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #9d9d9d;
    }
  }

  .Blogbox {
    h2 {
      color: $blue;
      font-size: $head-size;

      @include bp(md) {
        font-size: $head-md;
      }

      @include bp(sm) {
        font-size: $head-small;
      }
    }

    .imgBox {
      .MuiButton-label {
        width: 100%;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
        }

        // @include bp(md){
        //     height:270px;
        // }
      }
    }

    .boxIcon {
      border: 3px solid rgba(255, 255, 255, 0.3);
      position: absolute;
      border-radius: 100%;

      .playIconBox {
        position: relative;
        z-index: 1;
        background: $white;
        color: #ed1d22;
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        padding: 10px;
      }
    }

    .card {
      background: #e5eaf5;
      padding: 1rem;
      border: 1px solid #787878;
      box-shadow: none;

      .card-body {
        padding: 1.25rem 0rem;
      }
    }
  }
}

.FooterAbout {
  margin-top: -5rem;
}

.quoteBox {
  // font-size:1.5rem;
  font-style: italic;
  font-size: 1.125rem;
  font-weight: 600;
}


@media (min-width:300px) and (max-width:1050px) {
  .BannerBox, .top-110px {
    margin-top: 74px;
  }
}

@media (max-width : 300px) {
  .BannerBox, .top-110px {
    margin-top: 74px;
  }
}