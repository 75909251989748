.table-container {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

td {
    padding: 8px;
    vertical-align: middle;
}

.bar {
    height: 25px;
    border-radius: 10px;
    text-align: center;
    color: black;
    /* font-weight: bold; */
    display: flex;
    align-items: center;
}

.bar.small {
    justify-content: flex-end;
    padding-right: 10px;
    background-color: '#2E82C8';
    font-size: 16px;
}

.imp {
    text-align: -webkit-right;
    font-weight: bold;
}

.bar.large {
    justify-content: flex-start;
    padding-left: 10px;
}

.year {
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    width: 80px;
}

@media (max-width:700px) {
    .bar.small {
        font-size: 12px !important;
        padding: 0px 5px;
    }

    .bar.large {
        font-size: 12px !important;
        padding: 0px 5px;
    }
}