@import "global";

.BannerBox.TrainingBox {

  .sixteen-nine {
    &::before {
      content: "";
      background: url(../images/past-training.jpg) center center no-repeat;
      background-size: cover;
    }
  }
}

.BannerBox.TrainingBox.upcoming {

  .sixteen-nine {
    &::before {
      content: "";
      //background: url(../images/overview.png) center center no-repeat;
      background: url(../images/overview.jpg) center center no-repeat;
      background-size: cover;
    }
  }
}

.BannerBox.TrainingBox.pastWebinar {
  .sixteen-nine {
    &::before {
      content: "";
      background: url(../images/past-webinar.jpg) center center no-repeat;
      background-size: cover;
    }
  }
}

.BannerBox.TrainingBox.upComeingWebinar {
  .sixteen-nine {
    &::before {
      content: "";
      background: url(../images/upcoming-webinar.jpeg) center center no-repeat;
      background-size: cover;
    }
  }
}

.careerMain {
  position: relative;

  &::before {
    content: "";
    background: url(../images/team.jpg) bottom center no-repeat;
    position: absolute;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}
.Blogbox.careerBox {
  * {
    color: #414042;
  }
  .card {
    padding: 0rem 2rem 2rem;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    @include bp(sm) {
      margin-top: 0rem;
    }

    .card-body {
      padding: 1.25rem 0rem 0rem;
      .card-text {
        font-family: $font-family;
        // font-size: 1.25rem;
        text-align: justify;
        @include bp(sm) {
          font-size: 1rem;
        }
      }
    }

    .dateBox {
      background: $blue;
      padding: 1.5rem 0.7rem 0.2rem 0.7rem;

      @include bp(sm) {
        position: absolute;
        top: 0rem;
        right: 1rem;
      }
      * {
        color: $white;
      }
      h3 {
        font-family: $font-family;
        font-size: 1.625rem;
        @include bp(sm) {
          font-size: 1.75rem;
        }
      }
      h5 {
        font-size: 1.1875rem;
        @include bp(sm) {
          font-size: 1rem;
        }
      }
      h6 {
        background: #252525;
        font-size: 1.3125rem;
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;

        @include bp(sm) {
          padding: 0.2rem;
          font-size: 1rem;
        }
      }
    }

    .headBox {
      h2 {
        margin-top: 0rem;
        font-family: $font-family;
      }
      h5 {
        font-family: $font-family;
        svg {
          margin-right: 0.5rem;
          width: 20px;
          height: 20px;
        }
      }
    }

    .SpeakerBox {
      margin-top: 0rem;
      margin-bottom: 2.5rem;
      display: flex;
      align-items: flex-start;

      .Speaker {
        margin-left: 10%;
        margin-right: 2rem;
        flex: 1;
        img {
          width: 120px;
          height: 120px;
        }
        h4 {
          margin-top: 0rem;
          font-family: $font-family;
          color: inherit;
          font-size: 1.75rem;
          @include bp(sm) {
            font-size: 1.625rem;
          }
        }
        h5 {
          font-family: $font-family;
          color: inherit;
          padding-top: 1rem;
          font-size: 1.675rem;
          margin-bottom: 0rem;
          @include bp(sm) {
            font-size: 1.3125rem;
          }
        }
      }
      .headBox {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h4 {
          margin: 0;
        }
        h2 {
          margin-top: 0rem;
          font-family: $font-family;
        }
        h5 {
          font-family: $font-family;
          svg {
            margin-right: 0.5rem;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .CheckButton {
    * {
      color: $white;
    }
    margin-top: 40px;

    font-size: $header-font-size;
    text-transform: capitalize;
    background: $blue;
    padding: 0.7rem 2rem;
    border-radius: 10px;
    // transition: 200ms;
    font-family: $font-family;
    margin: 2rem auto 5rem auto;
    display: table;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.2);

    @include bp(md) {
      // padding:0.2rem 1rem;
      // font-size:0.875rem;
    }
  }

  .checkBtnSchedule {
    font-size: 1.5rem;
    border-radius: 45px;
    padding: 0.7rem 3rem;
    @include bp(md) {
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @include bp(md) {
      font-size: 1.25rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.careerMain.eventMain {
  &::before {
    display: none;
  }

  .imgBoxtraining {
    height: 110px;
    width: 100%;
    background: #ececec;
    border-radius: 20px 20px 0px 0px;
    img {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}
.formControl {
  min-width: 200px;
  // margin-right: var(
  //   --theme-spacing-2
  // ); /* Adjust this as per your theme spacing */
  position: relative;
  display: flex;
  align-items: center;

  @include bp(md) {
    min-width: 90px;
    // border: 1px solid black;
  }
}

.select {
  width: 100%;
  padding: 10px 30px 10px 10px;

  border-color: #064e89;
  border-width: 2px;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: #064e89;
  @include bp(md) {
    font-size: 10px;
    padding: 10px 60px 10px 60px;
  }
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #064e89;
  @include bp(md) {
    right: 2px;
  }
}

.filter {
  // position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  font-size: 1rem;
  gap: 50px;
  @media (min-width: 300px) and (max-width: 450px) {
      gap: 0px;
    }
}

.upcomingEvent {
  .container {
    max-width: 98%;
  }
}
.modeFilter {
  // margin-right: 0.1rem;
  // margin-left: 1rem;
  // margin-left: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  @media (min-width: 300px) and (max-width: 350px) {
    margin-right: 0.8rem;
    margin-left: 0;
    padding: 2px;
  }
  @media (min-width: 351px) and (max-width: 400px) {
    margin-right: 1rem;
    margin-left: 0;
    padding: 2px;
  }
  @media (min-width: 401px) and (max-width: 500px) {
    margin-right: 1rem;
    margin-left: 0;
    padding: 2px;
  }
  svg {
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1.2rem;
    }
  }
  span {
    margin-left: 2rem;
    text-align: center;
    @media (min-width: 300px) and (max-width: 349px) {
      font-size: 0.8rem;
      margin-left: 1rem;
    }
    @media (min-width: 350px) and (max-width: 960px) {
      font-size: 0.8rem;
      margin-left: 1.5rem;
    }
  }
}
.languageFilter {
  // margin-left: 0.6rem;

  .langSvg {
    font-size: 1.8rem;
    @include bp(md) {
      font-size: 1.4rem;
    }
  }
  svg {
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1.2rem;
    }
  }
  span {
    margin-left: 0.3rem;
    @include bp(md) {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      margin-right: 1rem;
      margin-left: 0.1rem;

      // margin-right: 2rem;
    }
  }
}

.upcomingWeb {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .custom-col-spacing {
    margin-bottom: -1rem;
    @include bp(md) {
      margin-bottom: 1rem;
    }
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 18px;
    padding-left: 3.8rem;
    margin-top: 3rem;
    @include bp(md) {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 2rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
    @include bp(sm) {
      margin-top: 1rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 95%;
    @include bp(md) {
      width: 100%;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;

      // background-color: #ececec;
      margin-top: 2.2rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }
      .mode {
        position: absolute;
        margin-top: -3.5rem;
        margin-bottom: 1.8rem;
        margin-left: -0.1rem;
        width: 100%;
        max-width: 84%;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        // border-radius: 2%;
        color: white;
        font-weight: bold;
        border-top-left-radius: 4%;
        border-bottom-left-radius: 4%;
        @media (min-width: 300px) and (max-width: 349px) {
          // margin-left: 1rem;
          margin-left: -0.5rem;
          max-width: 100%;
          font-size: 0.7rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          max-width: 90%;
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 450px) {
          max-width: 88%;
          font-size: 0.7rem;
        }
        @media (min-width: 451px) and (max-width: 550px) {
          font-size: 0.7rem;
        }
        @media (min-width: 750px) and (max-width: 900px) {
          max-width: 78%;
        }
        @media (min-width: 901px) and (max-width: 1000px) {
          max-width: 75%;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
        }
      }
      h4 {
        text-align: center;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 30%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 1rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;

        @media (min-width: 300px) and (max-width: 349px) {
          width: 70px;
          height: 70px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }
        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 1rem;
        overflow: hidden;
        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: -0.7rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      // @include bp(md) {
      //   font-size: 1rem;
      //   margin-left: -0.2rem;
      //   border: 1px solid black;
      //   width: 110%;
      // }
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.8rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    .topic {
      font-size: 1.1rem;
      line-height: 1.2;
      word-break: break-word;
      font-weight: bold;
      height: 3.5em;
      padding-right: 2px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.8rem;
        width: 5%;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
        // padding: 2px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
  }

  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1rem;
    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .registered {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      // padding-left: 1rem; // Default larger size
      // padding-right: 1rem; // Default larger size

      span {
        margin-left: 0.2rem;
        svg {
          font-size: 1.4rem;
          color: rgb(88, 198, 88);
          font-weight: bold;
        }
      }
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
        span {
          margin-left: 0.2rem;
          svg {
            font-size: 1rem;
            color: rgb(88, 198, 88);
            font-weight: bold;
          }
        }
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      // padding-left: 1rem; // Default larger size
      // padding-right: 1rem; // Default larger size
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        // padding-top: 0.38rem;
        // padding-bottom: 0.38rem;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // font-size: 0.54rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .readmore:disabled {
      background-color: #2f5d82; 
      color: #e0e0e0; 
      cursor: not-allowed;
      border-color: #b0b0b0; 
      opacity: 0.6; 
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      left: 24vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 350px) and (max-width: 359px) {
        padding: 0rem;
        margin-left: 5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 360px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        // padding: 0rem;
        margin-left: 50%;
        // font-size: 0rem;
        // margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: 55%;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.5rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 6.8rem;
        font-size: 0rem;
        margin-top: 0.2rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
    }
  }

  .boxFeedback {
    margin-top: 1rem;
    left: 0;
    right: 0;
    .readmore {
      margin: -2rem auto 4rem auto;
      margin-left: 5rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: white;
      color: $black;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.75rem;
        padding: 0.2rem 1rem;
        margin-left: 2rem;
      }
    }
    .CheckButton {
      margin: -2rem auto 4rem auto;
      margin-right: 3rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: #035eab;
      color: $white;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.8rem;
        padding: 0.2rem 1rem;
        margin-right: 2rem;
      }
    }
  }
}

// .upcomingWeb {
//   .row {
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     margin-right: -8px;
//     margin-left: -8px;

//     @include bp(md) {
//       margin-right: -15px;
//       margin-left: -15px;
//     }
//   }
//   .custom-col-spacing {
//     margin-bottom: -1rem;
//     @include bp(md) {
//       margin-bottom: 1rem;
//     }
//   }
//   .col,
//   .col-1,
//   .col-10,
//   .col-11,
//   .col-12,
//   .col-2,
//   .col-3,
//   .col-4,
//   .col-5,
//   .col-6,
//   .col-7,
//   .col-8,
//   .col-9,
//   .col-auto,
//   .col-lg,
//   .col-lg-1,
//   .col-lg-10,
//   .col-lg-11,
//   .col-lg-12,
//   .col-lg-2,
//   .col-lg-3,
//   .col-lg-4,
//   .col-lg-5,
//   .col-lg-6,
//   .col-lg-7,
//   .col-lg-8,
//   .col-lg-9,
//   .col-lg-auto,
//   .col-md,
//   .col-md-1,
//   .col-md-10,
//   .col-md-11,
//   .col-md-12,
//   .col-md-2,
//   .col-md-3,
//   .col-md-4,
//   .col-md-5,
//   .col-md-6,
//   .col-md-7,
//   .col-md-8,
//   .col-md-9,
//   .col-md-auto,
//   .col-sm,
//   .col-sm-1,
//   .col-sm-10,
//   .col-sm-11,
//   .col-sm-12,
//   .col-sm-2,
//   .col-sm-3,
//   .col-sm-4,
//   .col-sm-5,
//   .col-sm-6,
//   .col-sm-7,
//   .col-sm-8,
//   .col-sm-9,
//   .col-sm-auto,
//   .col-xl,
//   .col-xl-1,
//   .col-xl-10,
//   .col-xl-11,
//   .col-xl-12,
//   .col-xl-2,
//   .col-xl-3,
//   .col-xl-4,
//   .col-xl-5,
//   .col-xl-6,
//   .col-xl-7,
//   .col-xl-8,
//   .col-xl-9,
//   .col-xl-auto {
//     position: relative;
//     width: 100%;
//     min-height: 1px;
//     padding-right: 8px;
//     padding-left: 8px;
//     margin-top: 4rem;
//     @include bp(md) {
//       padding-right: 15px;
//       padding-left: 15px;
//       margin-top: 2rem;
//       &:first-of-type {
//         margin-top: 2rem;
//       }
//     }
//     @include bp(sm) {
//       margin-top: 1rem;
//       &:first-of-type {
//         margin-top: 2rem;
//       }
//     }
//   }
//   padding-bottom: 0rem;
//   margin-top: 0rem;
//   h1 {
//     margin-top: 1rem;
//     font-family: $font-family;
//     color: $h2color;
//     font-size: $head-size;
//     // top: 3rem;
//     // bottom: 3rem;
//     @include bp(md) {
//       font-size: $head-md;
//       top: 0rem;
//     }
//     @include bp(sm) {
//       font-size: $head-small;
//       top: 3rem;
//     }
//   }

//   // .SpeakerBox {
//   //   margin-top: 0rem;
//   //   margin-bottom: 3rem;
//   //   display: flex;
//   //   align-items: flex-start;
//   //   flex: 1;
//   //   .Speaker {
//   //     margin-left: 10%;
//   //     margin-right: 3rem;
//   //     flex: 1;
//   //     img {
//   //       width: 120px;
//   //       height: 120px;
//   //       border-radius: 50%;
//   //     }
//   //     h4 {
//   //       margin-top: 0rem;
//   //       font-family: $font-family;
//   //       color: inherit;
//   //       font-size: 1.75rem;
//   //       @include bp(sm) {
//   //         font-size: 1.625rem;
//   //       }
//   //     }
//   //     .headBox {
//   //       margin-top: 1rem;
//   //       display: flex;
//   //       flex-direction: column;
//   //       align-items: flex-start;
//   //       h4 {
//   //         margin: 0;
//   //       }
//   //       h2 {
//   //         margin-top: 0rem;
//   //         font-family: $font-family;
//   //       }
//   //       h5 {
//   //         font-family: $font-family;
//   //         svg {
//   //           margin-right: 0.5rem;
//   //           width: 20px;
//   //           height: 20px;
//   //         }
//   //       }
//   //     }
//   //   }
//   // }
//   .card {
//     position: relative;
//     padding: 0rem;
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
//     margin-top: 0rem;
//     display: flex;
//     flex-direction: column;
//     height: 100%;

//     .card-body {
//       &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 34%;
//         height: 100%;
//         background-color: #ececec;
//         @media (max-width: 350px) and (min-width: 300px) {
//           width: 43%;
//         }
//         @media (max-width: 400px) and (min-width: 351px) {
//           width: 39%;
//         }
//         @media (max-width: 600px) and (min-width: 450px) {
//           width: 30%;
//         }
//         @media (max-width: 850px) and (min-width: 700px) {
//           width: 32%;
//         }
//         @media (max-width: 1020px) and (min-width: 851px) {
//           width: 30%;
//         }
//         @media (max-width: 1100px) and (min-width: 1021px) {
//           width: 40%;
//         }
//         // @include bp(sm) {
//         //   width: 39%;
//         // }
//       }
//     }
//     // img {position:absolute; z-index:0;}
//     .SpeakerBox {
//       margin-top: 0.2rem;
//       margin-bottom: 0rem;
//       margin-right: 3rem;
//       display: flex;
//       align-items: flex-start;
//       width: 100%;
//       figure {
//         width: 50%;
//       }
//       .Speaker {
//         // background-color: #ececec;
//         margin-top: 1rem;
//         margin-bottom: 1rem;
//         margin-right: 3rem;
//         margin-left: 2%;
//         // width: 50%;
//         // background-color: #ececec;
//         height: 100%;
//         @include bp(md) {
//           margin-bottom: 1rem;
//           margin-left: 1%;
//         }

//         p {
//           margin-top: 0.5rem;
//           font-family: $font-family;
//           font-weight: 550;

//           @include bp(sm) {
//             font-size: 0.8rem;
//           }
//         }
//         h4 {
//           margin-top: 0.5rem;
//           margin-left: 0.5rem;
//           width: 90%;
//           font-family: $font-family;
//           color: inherit;
//           font-weight: 550;
//           font-size: 1rem;

//           @include bp(sm) {
//             font-size: 0.8rem;
//           }
//         }
//         .imgBox {
//           width: 140px;
//           height: 140px;
//           @include bp(md) {
//             width: 90px;
//             height: 90px;
//           }

//           margin: 0 auto;
//           img {
//             height: 100%;
//             width: 100%;
//             border-radius: 50%;
//           }
//         }
//       }

//       .table {
//         width: 100%;
//         margin-top: 1rem;
//         font-family: $font-family;
//         font-size: 1.2rem;
//         @include bp(md) {
//           font-size: 1rem;
//         }
//       }
//       th {
//         font-weight: 100;
//       }
//       th,
//       td {
//         border: none;
//         padding: 7px;
//         text-align: left;
//         line-height: 1;
//         @include bp(md) {
//           font-size: 0.8rem;
//           padding: 5px;
//         }
//       }

//       th:first-child {
//         width: 10%;
//         padding-right: 5px;
//       }

//       td:first-child {
//         padding-right: 10px;
//       }

//       @include bp(md) {
//         font-size: 0.8rem;
//       }
//       .topic-row {
//         td {
//           padding-bottom: 1rem; // Adjust this value as needed
//         }
//         .topic-key {
//           line-height: 1.5;
//         }
//         .topic-value {
//           line-height: 1.5;
//           word-break: break-word;
//         }
//       }
//     }

//     @include bp(sm) {
//       margin-top: 1.5rem;
//       margin-bottom: 0.2rem;
//     }

//     .headBox {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       margin-top: 1rem;
//       margin-bottom: 3rem;
//       flex: 1;
//       .info {
//         display: flex;
//         justify-content: flex-start;
//         align-items: stretch;
//       }
//       span {
//         margin: 0;
//         font-family: $font-family;
//         font-size: 1rem;
//         max-width: 400px;
//         text-align: left;
//         // text-align-last: justify;
//         margin-left: 0.2rem;
//         margin-top: -0.1rem;
//         display: flex;

//         @include bp(md) {
//           font-size: 0.8rem;
//         }
//       }

//       h4 {
//         margin: 0;
//         font-family: $font-family;
//         font-size: 1rem;
//         max-width: 400px;

//         @include bp(md) {
//           font-size: 0.8rem;
//         }
//       }
//     }
//   }

//   .readmore {
//     margin: -1.2rem auto 4rem auto;
//     border-radius: 10px;
//     padding: -0.1rem 2rem;
//     text-transform: capitalize;
//     background: transparent;
//     color: $black;
//     font-family: "gotham rounded medium";
//     @include bp(md) {
//       margin-bottom: 0rem;
//     }
//   }
//   .boxFeedback {
//     margin-top: 1rem;
//     left: 0;
//     right: 0;
//     .readmore {
//       margin: -2rem auto 4rem auto;
//       margin-left: 5rem;
//       border-radius: 35px;
//       padding: 0.2rem 2rem;
//       background: white;
//       color: $black;
//       text-transform: capitalize;
//       font-family: "gotham rounded medium";
//       font-size: 1rem;
//       @include bp(md) {
//         font-size: 0.75rem;
//         padding: 0.2rem 1rem;
//         margin-left: 2rem;
//       }
//     }
//     .CheckButton {
//       margin: -2rem auto 4rem auto;
//       margin-right: 3rem;
//       border-radius: 35px;
//       padding: 0.2rem 2rem;
//       background: #035eab;
//       color: $white;
//       text-transform: capitalize;
//       font-family: "gotham rounded medium";
//       font-size: 1rem;
//       @include bp(md) {
//         font-size: 0.8rem;
//         padding: 0.2rem 1rem;
//         margin-right: 2rem;
//       }
//     }
//   }
// }
.pastTrain {
  .container {
    // border: 1px solid black;
    max-width: 98%;
    @include bp(md) {
      max-width: none;
    }
  }
}
.formControlTrain {
  min-width: 200px;
  // margin-right: var(
  //   --theme-spacing-2
  // ); /* Adjust this as per your theme spacing */
  position: relative;
  display: flex;
  align-items: center;

  @include bp(md) {
    min-width: 90px;
    // border: 1px solid black;
  }
}

.selectTrain {
  width: 100%;
  padding: 10px 30px 10px 10px;

  border-color: #064e89;
  border-width: 2px;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  color: #064e89;
  @include bp(md) {
    font-size: 10px;
    padding: 10px 60px 10px 60px;
  }
  @media (min-width: 300px) and (max-width: 500px) {
    font-size: 10px;
    padding: 10px 38px 10px 60px;
  }
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #064e89;
  @include bp(md) {
    right: 2px;
  }
}

.filter {
  // position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  font-size: 1rem;
}
.yearFilterTrain {
  // margin-right: 0.1rem;
  // // margin-left: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  @media (min-width: 300px) and (max-width: 500px) {
    margin-right: 1rem;
    margin-left: 0;
    padding: 2px;
  }


  svg {
    @include bp(md) {
      font-size: 1.2rem;
    }
  }
  span {
    margin-left: 2rem;
    text-align: center;
    @media (min-width: 300px) and (max-width: 349px) {
      font-size: 0.8rem;
      margin-left: 1rem;
    }
    @media (min-width: 350px) and (max-width: 960px) {
      font-size: 0.8rem;
      margin-left: 1.5rem;
    }
  }
}
.PastTraining {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .custom-col-spacing {
    margin-bottom: -1rem;
    @include bp(md) {
      margin-bottom: 1rem;
    }
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 18px;
    padding-left: 3.8rem;
    margin-top: 3rem;
    @include bp(md) {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 2rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
    @include bp(sm) {
      margin-top: 1rem;
      &:first-of-type {
        margin-top: 2rem;
      }
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 95%;
    @include bp(md) {
      width: 100%;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;
      // border: 1px solid black;
      // background-color: #ececec;
      margin-top: 1.3rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }

      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;
        @media (min-width: 300px) and (max-width: 349px) {
          width: 70px;
          height: 70px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }
        @include bp(md) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 0.9rem;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 400px) {
          font-size: 0.7rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 0.2rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.8rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    @include bp(md) {
      font-size: 0.8rem;
    }
    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;
      height: 3.5em;
      padding-right: 2px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.8rem;
        width: 5%;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
        // padding: 2px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
  }

  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.5rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }

      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.7rem;
      }
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      left: 24vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 350px) and (max-width: 359px) {
        padding: 0rem;
        margin-left: 5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 360px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        // padding: 0rem;
        margin-left: 50%;
        // font-size: 0rem;
        // margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: 55%;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.5rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 6.8rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0.1rem;
      }
    }
  }

  .boxFeedback {
    margin-top: 1rem;
    left: 0;
    right: 0;
    .readmore {
      margin: -2rem auto 4rem auto;
      margin-left: 5rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: white;
      color: $black;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.75rem;
        padding: 0.2rem 1rem;
        margin-left: 2rem;
      }
    }
    .CheckButton {
      margin: -2rem auto 4rem auto;
      margin-right: 3rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: #035eab;
      color: $white;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.8rem;
        padding: 0.2rem 1rem;
        margin-right: 2rem;
      }
    }
  }
}

.Blogbox.careerBox.EventBox {
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  .custom-col-spacing {
    margin-bottom: -5rem;
    @include bp(md) {
      margin-bottom: 1rem;
    }
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
    margin-top: 6rem;
    @include bp(md) {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 2rem;
      &:first-of-type {
        margin-top: 5rem;
      }
    }
    @include bp(sm) {
      margin-top: 1rem;
      &:first-of-type {
        margin-top: 5rem;
      }
    }
  }

  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }
  .card {
    padding: 0rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin-top: 0rem;
    .dateBox {
      position: absolute;
      z-index: 1;
      border-radius: 6px 0px 0px 0px;
    }
    // img {position:absolute; z-index:0;}
    .SpeakerBox {
      margin-bottom: 0rem;
      .Speaker {
        margin-top: 1rem;
        h4 {
          margin-top: 0.8rem;
          font-size: 1rem;
          @include bp(md) {
            font-size: 0.6rem;
          }
        }
        .imgBox {
          width: 120px;
          height: 100px;

          margin: 0 auto;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .dateBox {
      padding-top: 0.5rem;
      h3 {
        font-size: 1.625rem;
      }
      h5 {
        font-size: 0.9375rem;
      }
      h6 {
        font-size: 1.0625rem;
      }

      @include bp(sm) {
        right: inherit;
      }
    }
    @include bp(sm) {
      margin-top: 1.5rem;
    }

    .headBox {
      h4 {
        font-family: $font-family;
        font-size: 1.175rem;
        @include bp(md) {
          font-size: 0.7rem;
        }
      }
      h5 {
        font-size: 1.125rem;
        svg {
          * {
            color: $blue;
          }
        }
      }
    }
    .card-body {
      padding: 0rem 0rem 0.5rem;
    }
    .card-footer {
      padding: 0rem 0rem 1rem;
      background: none;
      border: none;
    }
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .CheckButton,
    .readmore {
      margin: 0 5px;
      padding: 0.3rem 1rem;
      font-size: 0.8rem;
      @include bp(md) {
        font-size: 0.6rem;
      }
    }

    .CheckButton {
      margin-right: 8px;
    }
    .readmore {
      * {
        color: $black;
      }
      margin-left: 8px;
      text-transform: capitalize;
      background: $white;
      border: 1px solid $black;
      border-radius: 10px;
      // transition: 200ms;
      font-family: $font-family;
    }
  }
}
.pastTrainMain {
  background-image: url(../images/webinar-bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 1rem;
  @include bp(md) {
    padding-top: 1rem;
  }
  .table {
    width: 100%;
    // margin-top: 1rem;
    margin-left: -1rem;
    // font-family: $font-family;

    @include bp(md) {
      font-size: 0.8rem;

      margin-left: 0rem;
    }
  }

  td {
    border: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    // margin-bottom: 1rem;
    padding-top: 0.5rem;
    // font-weight: 500;
    text-align: left;
    // line-height: 1;
    img {
      width: 30px;
    }
    svg {
      font-size: 30px;
    }
    @media (min-width: 300px) and (max-width: 320px) {
      font-size: 0.7rem;
      img {
        width: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    @media (min-width: 321px) and (max-width: 400px) {
      font-size: 0.8rem;
      img {
        width: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    @media (min-width: 401px) and (max-width: 750px) {
      font-size: 0.9rem;
      img {
        width: 25px;
      }
      svg {
        font-size: 25px;
      }
    }
    @media (min-width: 950px) and (max-width: 1050px) {
      font-size: 1rem;

      img {
        width: 28px;
      }
      svg {
        font-size: 28px;
      }
    }
  }

  .fixed-content {
    font-size: 10px;
  }
  .secondcard {
    border: none;
    width: 50%;
    @include bp(md) {
      width: 100%;
    }
  }
  h1 {
    background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 2.625rem;
    font-weight: 700;
    padding-top: 0.5rem;

    @include bp(md) {
      font-size: 2rem;
      margin-top: 1rem;
      text-align: center;
      font-size: 1.5rem;
    }
  }
  .speakerName {
    @include bp(md) {
      text-align: center;
    }
  }
  h5 {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    font-weight: 500;
  }
  h4,
  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;

    max-width: 90%;
    text-align: justify;
    // font-family: Poppins;
    @include bp(md) {
      max-width: 100%;
    }
    @media (min-width: 300px) and (max-width: 750px) {
      font-size: 1rem;
    }
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0rem;
  }
  ul {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    height: 15px;
    margin: 1rem 0;
    @include bp(md) {
      display: flex;
      justify-content: center;
    }
    li {
      width: 15px;
      height: 2px;
      background: #056aff;
      position: relative;
      display: inline-block;
      margin-left: -5px;

      &:nth-child(odd) {
        transform: rotate(-45deg);
        &::before {
          left: 5px;
        }
      }

      &:nth-child(even) {
        transform: rotate(45deg);
        &::before {
          right: 5px;
        }

        &::after {
          left: 5px;
        }
      }

      &::before {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        top: -5px;
        background: #056aff;
      }

      &::after {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        // top: -5px;
        // background: #056aff;
      }
    }
  }
  .svgBox {
    // background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .webVideo {
    .player-wrapper {
      position: relative;
      height: 100%;
      padding-top: 60.25%;

      overflow: hidden;

      @include bp(sm) {
        padding-top: 70.25%;
      }
    }
    // img {
    //   width: 30%;
    // }
  }
  .timeBox {
    padding-bottom: 5rem;
    @include bp(md) {
      padding-bottom: 2rem;
    }
    * {
      font-family: $font-family;
    }
    [class*="col-"] {
      padding-top: 5rem;
      @include bp(md) {
        padding-top: 2rem;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: #000;
      font-weight: bold;
      margin-top: 3.75rem;
      margin-bottom: 1.5625rem;
      @include bp(md) {
        margin-top: 2rem;
      }
    }
    h4 {
      color: #39374e;
      font-size: 1.125rem;
    }
  }
}

.headBoxRegister {
  font-family: $font-family;
  color: #6c6d71;
  margin-top: 3rem;
  font-size: 2.125rem;

  @include bp(sm) {
    font-size: 1.565rem;
  }
}

.customFooter {
  margin-top: -6rem;
  @include bp(md) {
    margin-top: -3rem;
  }
}

// dialouge

.careerForm {
  * {
    color: #7e7f82;
    font-family: $font-family;
  }
  svg {
    font-size: 3.688rem;
    top: -1rem;
  }
  .MuiFormControl-root {
    border-radius: 40px;
    border: none;
    background: #ebebeb;
    display: flex;
  }
  .MuiInputLabel-root {
    font-family: $font-family;
    padding-left: 1.5rem;
    font-size: 1.25rem;
    top: -4px;
    @include bp(sm) {
      font-size: 1.165rem;
    }
  }
  .colorBox {
    color: #fd7f2b;
  }
  .MuiFilledInput-root {
    border-radius: 40px;
    border: none;
    background: #ebebeb;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
  .MuiFilledInput-input {
    padding-top: 25px;
    padding-left: 1.8rem;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-left: 2rem;
  }
  .MuiSelect-select:focus {
    background: none;
  }
  label + .MuiInput-formControl {
    line-height: 1.8em;
    margin-top: 16px;
  }
  .CheckButton {
    * {
      color: $white;
    }
    margin-top: 40px;

    font-size: 1.5rem;
    text-transform: capitalize;
    background: $blue;
    padding: 0.7rem 2rem;
    border-radius: 35px;
    // transition: 200ms;
    font-family: $font-family;
    margin: 0rem auto 2rem auto;
    display: table;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.2);

    @include bp(sm) {
      font-size: 1.165rem;
    }

    @include bp(md) {
      // padding:0.2rem 1rem;
      // font-size:0.875rem;
    }
  }
  .MuiButton-root:hover {
    background: $light-blue;
    color: $white;
  }
}
.buttonTrContainer {
  .actionTraining {
    display: flex;

    @media (min-width: 300px) and (max-width: 400px) {
      // justify-content: space-between;
    }
    .bookmark {
      padding: 8px;
      // border: 3px solid #064e89;
      color: #064e89;
      margin-right: 1.2rem;
      @media (min-width: 300px) and (max-width: 330px) {
        margin-right: 0.5rem;
        padding: 1.2px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.2px;
          svg {
            font-size: 18px;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 400px) {
        margin-right: 0.5rem;
        padding: 1.4px;

        font-size: 0rem;
        margin-top: 0rem;

        span {
          padding: 1.4px;
          svg {
            font-size: 20px;
          }
        }
      }
      @media (min-width: 401px) and (max-width: 700px) {
        margin-right: 0.5rem;
        padding: 1.2px;

        button {
          padding: 1.2px;
          svg {
            font-size: 0.1rem;
          }
        }
      }
      @media (min-width: 701px) and (max-width: 1050px) {
        margin-right: 0.5rem;
        button {
          padding: -5px;
          svg {
            font-size: 0.1rem;
          }
        }
      }
    }
  }
  // .RatingDialog-ratingContainer-21 {
  //   font-size: 1rem;
  //   text-transform: uppercase;
  //   border: 2px solid black;
  //   padding: 0.4rem 0.8rem;
  //   border-radius: 5px;
  //   font-family: "gotham rounded medium";

  //   color: $black;
  //   @media (min-width: 300px) and (max-width: 330px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;
  //     display: flex;
  //     align-items: center;
  //     svg {
  //       font-size: 1rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 331px) and (max-width: 365px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.2rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 366px) and (max-width: 400px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.25rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  //   @media (min-width: 401px) and (max-width: 700px) {
  //     padding-top: 0.2rem;
  //     padding-bottom: 0.2rem;
  //     padding-left: 0.1rem;
  //     padding-right: 0.1rem;
  //     margin-right: 0.8rem;
  //     text-align: center;

  //     svg {
  //       font-size: 1.4rem;
  //       margin-bottom: 0.1rem;
  //     }
  //   }
  // }
  .rating {
    font-size: 1rem;
    text-transform: uppercase;
    border: 2px solid black;
    padding: 0.4rem 0.8rem;
    border-radius: 5px;
    font-family: "gotham rounded medium";

    color: $black;
    @media (min-width: 300px) and (max-width: 330px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;
      display: flex;
      align-items: center;
      svg {
        font-size: 1rem;
        margin-bottom: 0.1rem;
      }
    }
    @media (min-width: 331px) and (max-width: 365px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
      }
    }
    @media (min-width: 366px) and (max-width: 400px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
      }
    }
    @media (min-width: 401px) and (max-width: 700px) {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      margin-right: 0.8rem;
      text-align: center;

      svg {
        font-size: 1.4rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}
.share {
  margin-top: -2.1rem;
  // margin-left: 5.8rem;
  // margin-right: 1rem;

  @media (min-width: 300px) and (max-width: 330px) {
    margin-top: -1.8rem;
    margin-right: 0.7rem;
  }
  @media (min-width: 331px) and (max-width: 400px) {
    margin-right: 0.7rem;
  }
  @media (min-width: 401px) and (max-width: 500px) {
    margin-left: 0;
    margin-right: 0.5rem;
    padding-bottom: 2rem;
    svg {
      font-size: 0.9rem;
      width: 28px;
    }
  }

  @media (min-width: 701px) and (max-width: 1050px) {
    margin-right: 1rem;
    margin-left: 0;
    padding-bottom: 2rem;
    svg {
      font-size: 0.4rem;
    }
  }
}
.pastBack {
  margin-top: 3.5rem;

  button {
    font-size: 1rem;
    padding: 0.3rem 1rem;
    border: 1px solid black;
    background: #035eab;
    color: $white;

    &:hover {
      background: #035eab;
      color: $white;
    }
  }
  @media (min-width: 300px) and (max-width: 400px) {
    margin-top: 3rem;
    button {
      font-size: 0.7rem;
      padding: 0.2rem 0.2rem;
    }
  }
  @media (min-width: 401px) and (max-width: 500px) {
    margin-top: 1rem;
    button {
      font-size: 0.7rem;
      padding: 0.2rem 0.2rem;
    }
  }
  @media (min-width: 501px) and (max-width: 700px) {
    margin-top: 2.8 rem;
    button {
      font-size: 0.7rem;
      padding: 0.3rem 0.3rem;
    }
  }
}
.careerForm.feedBackForm {
  .textAreaBox {
    background: #ebebeb;
    height: 17rem !important;
    min-height: 17rem;
    border: none;
    border-radius: 40px;
    padding: 2rem;
    font-size: 1.25rem;

    @include bp(sm) {
      font-size: 1.165rem;
    }
  }
  .CheckButton {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

.Blogbox.careerBox.EventBox {
  .CheckButton {
    box-shadow: none;
  }
}

.checkboxIcon {
  svg {
    font-size: 1.5rem;
  }
}

.dialougemain {
  .boxMain {
    border-radius: 30px !important;
  }
}

.pastrainingGallery {
  align-items: center;
  justify-content: center;
  .card {
    padding-top: 0rem;
  }
  .TestBox {
    .carousel {
      position: relative;

      .carousel-inner {
        border-radius: 30px;
        box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.15);
        @media (min-width: 300px) and (max-width: 370px) {
          max-width: 90%;
          margin-left: 1rem;
        }
        @media (min-width: 370px) and (max-width: 500px) {
          max-width: 90%;
          margin-left: 1.2rem;
        }
        @media (min-width: 501px) and (max-width: 700px) {
          max-width: 90%;
          margin-left: 1.5rem;
        }

        .carousel.item {
          min-height: auto;
        }
        .card {
          border: none;
          padding-top: 0rem;
          // &::before{
        }
      }
      .ArrowLeft {
        position: relative;
        left: -5rem;
        color: #636363;
        font-size: 5rem;
        @media (min-width: 300px) and (max-width: 500px) {
          font-size: 2rem;
          left: -2rem;
          margin-left: 1rem;
        }
        @media (min-width: 501px) and (max-width: 700px) {
          font-size: 4rem;
          left: -2rem;
          margin-left: 1rem;
        }
      }
      .ArrowRight {
        position: relative;
        right: -5rem;
        color: #636363;
        font-size: 5rem;

        @media (min-width: 300px) and (max-width: 500px) {
          font-size: 2rem;
          right: -2rem;
          margin-right: 1rem;
        }
        @media (min-width: 501px) and (max-width: 700px) {
          font-size: 4rem;
          right: -2rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
.careerMain .trainingCard {
  margin-top: 1rem;
}

.workshopsTable{
  width: 100%;
  margin-top: 2rem;
  display: flex ;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
  img{
    width: 90%;
  }
}

.register-webinar-modal-header-mob{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.register-webinar-modal-close-btn{
  width: 24px !important;
  height: 17px !important;
  display: flex !important;
  margin-right: 10px !important;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 500px) {
  .register-webinar-modal-close-btn{
    width: 24px !important;
    height: 17px !important;
    display: flex !important;
    margin-right: 10px !important;
  }
  .register-webinar-modal-header-mob-txt{
    width: 80% !important;
  }
}

.upcoming-webinar-no-result-text{
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  font-size: larger !important;
  color: #064e89 !important;
  padding-top: 20px !important;
  padding-left: 10px !important;
}

.faq-no-result-text{
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  font-size: larger !important;
  color: #064e89 !important;
  padding-left: 10px !important;
  margin-top: -15px !important;
  margin-bottom: 10px !important;
}