.pwa-nav-container {
    position: fixed;    
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 1;

        .nav-icon {
            font-size: 20px;
            color: #555;
            margin-bottom: 4px;
        }

        .nav-label {
            font-size: 14px;
            color: #555;
        }

        &:hover {

            .nav-icon,
            .nav-label {
                color: #007bff;
            }
        }
    }
}
  
.pwa-nav-container .MuiBottomNavigation-root{
    width: 100% !important;
    max-width: 100% !important;
}

.pwa-nav-container .MuiBottomNavigation-root.MuiBottomNavigationAction-root{
    min-width: 0 !important;
}
.MuiBottomNavigationAction-label{
    // min-height: 36px !important;
    color: #035fabdc !important;
    font-family: gotham rounded medium !important;
    font-size: 0.75rem !important;
}
.pwa-nav-icon-image{
    width: 30px !important;
    height: 30px !important;
}
.pwa-nav-more-icon-image{
    width: 30px !important;
    height: 30px !important;
    color: #035fabdc !important;
}
.pwa-nav-menu-child-label{
    display: flex !important;
    gap: 5px !important;
}

.pwa-nav-text-main{
    font-size: 16px !important;
    font-family: gotham rounded medium !important;
    // color: #035fabdc !important;
    margin-top: 5px !important;
    text-align: center !important;
}

.pwa-nav-container.MuiBottomNavigationAction-root.Mui-selected {
    color: blue !important;
    position: relative;
  }
  
  .pwa-nav-container .MuiBottomNavigationAction-root.Mui-selected::after {
    content: "";
    display: block !important;
    width: 70% !important;
    height: 5px !important;
    background-color: #035fabdc !important;
    position: absolute !important;
    top: -12px !important;
    left: 13px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  