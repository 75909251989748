@import "global";
@import url("https://fonts.googleapis.com/css2?family=Teko");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;700&display=swap");
.heading {
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: $font-family;
  color: #064e89;
  @include bp(md) {
    margin-top: 1rem;
  }
}
.pastTrainAchieveSection {
  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 2rem;
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1rem;
    }
  }
  .container {
    position: relative;
    margin-bottom: 1.5rem;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-left: 7px;
    // border: 1px solid black;
    @include bp(md) {
      // border: 1px solid black;
    }
  }
  .prevButton {
    position: absolute;
    top: 50;
    left: 0;

    margin-top: -10rem;
    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
  }
  .singlePrevButton {
    position: absolute;
    top: 50;
    left: 18rem;

    margin-top: -10rem;
    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -8rem;
      left: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -8rem;
      left: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8rem;
      left: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      // margin-top: 0;
      // left: -0.1rem;
      // top: 9rem;
      left: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      left: 14rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50;
    right: 0;
    margin-left: 1rem;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .singleNextButton {
    position: absolute;
    top: 50;
    right: 18rem;
    margin-left: 1rem;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -8rem;
      right: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -8rem;
      right: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8rem;
      right: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      right: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      right: 14rem;
    }
  }
}
.pastWebAchieveSection {
  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 2rem;
    font-size: 1.5rem;
    @include bp(md) {
      font-size: 1rem;
    }
  }
  .container {
    position: relative;
    border-top: 1px solid #064e89;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-left: 7px;
    // border: 1px solid black;
    @include bp(md) {
      // border: 1px solid black;
    }
  }
  .prevButton {
    position: absolute;
    top: 50;
    left: 0;

    margin-top: -10rem;
    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
  }
  .singlePrevButton {
    position: absolute;
    top: 50;
    left: 18rem;

    margin-top: -10rem;
    color: #064e89;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -8rem;
      left: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -8rem;
      left: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8rem;
      left: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      // margin-top: 0;
      // left: -0.1rem;
      // top: 9rem;
      left: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      left: 14rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50;
    right: 0;
    margin-left: 1rem;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .singleNextButton {
    position: absolute;
    top: 50;
    right: 18rem;
    margin-left: 1rem;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    @media (min-width: 300px) and (max-width: 350px) {
      padding: 4px;
      margin-top: -8rem;
      right: 0.2rem;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -8rem;
      right: 0.3rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8rem;
      right: 0.3rem;
    }
    @media (min-width: 601px) and (max-width: 1000px) {
      right: -0.1rem;
    }
    @media (min-width: 1001px) and (max-width: 1050px) {
      right: 14rem;
    }
  }
}
.pastWebAchieve {
  margin-top: 2rem;
  .custom-col-spacing {
    margin-bottom: -1rem;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (min-width: 300px) and (max-width: 349px) {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;
      // border: 1px solid black;
      // background-color: #ececec;
      margin-top: 1.3rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }

      h4 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 20%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 0.8rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;

        @media (min-width: 300px) and (max-width: 349px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 0.9rem;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 450px) {
          font-size: 0.7rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 0.2rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      height: 70%;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      vertical-align: top;
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.7rem;
        line-height: 1;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    @include bp(md) {
      font-size: 0.8rem;
    }
    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;

      height: 3.2em;
      padding-right: 2px !important;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.7rem;
        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
    // img {position:absolute; z-index:0;}
  }

  .actionWebAchieve {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    justify-content: flex-end;
    position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;

    // margin-bottom: -1.5rem;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
  }
}
.certificate {
  .username {
    position: absolute;
    top: 32.2rem;
    margin-top: 2rem;
    left: 16rem;

    // border: 2px solid black;
    p {
      font-family: "Teko";
      font-size: 2.8rem;
      color: #282e73;
    }
  }
  .schoolName {
    position: absolute;
    top: 36.1rem;
    margin-top: 2rem;
    left: 19.4rem;
    min-width: 46%;
    max-width: 46%;
    overflow: hidden;
    width: 46%;

    white-space: nowrap;
    text-overflow: ellipsis;
    p {
      font-family: "Teko";
      font-size: 2.8rem;
      color: #282e73;
    }
  }
  .eventTitle {
    position: absolute;
    top: 41rem;
    margin-top: 2rem;

    margin-left: 0.1rem;

    width: 100%;
    p {
      text-align: center;
      font-size: 2.1rem;
      font-family: "Baloo Bhai 2";
      font-weight: bold;
      color: #282e73;
    }
  }
  .trainingTick {
    position: absolute;
    top: 40rem;
    margin-top: 6.8rem;
    left: 35.2rem;
    svg {
      font-size: 4.2rem;
      font-weight: bold;
      color: green;
    }
  }
  .webinarTick {
    position: absolute;
    top: 40rem;
    margin-top: 6.8rem;
    left: 45.2rem;
    margin-left: 8.6rem;
    svg {
      font-size: 4.2rem;
      font-weight: bold;
      color: green;
    }
  }
  .date {
    position: absolute;
    top: 45.2rem;
    margin-top: 8rem;
    left: 19.8rem;
    p {
      font-family: "Teko";
      font-size: 2.4rem;
      color: #282e73;
    }
  }
  .time {
    position: absolute;
    top: 45.2rem;
    margin-top: 8rem;
    left: 37rem;
    p {
      font-family: "Teko";
      font-size: 2.4rem;
      color: #282e73;
    }
  }
  .location {
    position: absolute;
    top: 45.2rem;
    margin-top: 8rem;
    left: 60rem;
    margin-left: 5.5rem;
    p {
      font-family: "Teko";
      font-size: 2.4rem;
      color: #282e73;
    }
  }
}
