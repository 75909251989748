@import "global";
$size: 22rem;
$size1: 24rem;
// $size2: 10rem;

// @keyframes rotate {
//     from {
//       transform: rotate(0deg)
//     }
//     to {
//       transform: rotate(90deg)
//     }
//   }

.signNav {
  // background: url(../images/wave.png) top left no-repeat;
  // background-size: cover;
  // padding-bottom: 1rem;
  // transition: 0.1s;
  // top: 6rem;

  display: none;

  // @include bp(lg) {
  //   padding-bottom: 0rem;
  // }
}

.phone_number {
  display: flex;
}

.sticky-top.sign-light {
  background: url(../images/wave.png) top left no-repeat;
  background-size: cover;
  padding-bottom: 1rem;
  transition: 0.7s;
  // top:6rem;
  display: none;

  @include bp(lg) {
    padding-bottom: 0rem;
  }
}

.logInBox {
  display: flex;
  position: relative;
  margin: auto;
  box-sizing: border-box;

  .mainImage {
    height: 100%;
    width: 75%;
    margin: auto;


    img {
      margin-top: 5rem;
      height: fit-content;
      width: 100%;
      // margin: auto;

    }

    .row {
      align-items: center;
    }

    @include bp(md) {
      display: flex;
      justify-content: center;

      img {
        width: 90%;
        margin: auto;
      }
    }
  }

  .mainBox {
    // padding-right: 15rem;
    // padding: 0 10rem;
    padding: 1.7rem;

    h2 {
      font-family: $font-family;
      color: #064e89;
    }

    .buttonTabs {
      display: flex;
      // justify-content: space-between;

      width: 40%;
      gap: 10px;

      @include bp(md) {
        width: 100%;
        justify-content: flex-start;
        gap: 5px;
      }

      .button {
        background-color: white;
        color: #064e89;
        font-family: $font-family;
        padding: 5px 15px;
        border: 1px solid #064e89;
        border-radius: 5px;
      }

      .active {
        background-color: #064e89;
        color: white;
      }


    }

    // .top {
    //   height: $size;
    //   width: $size * 2;
    //   border-top-left-radius: $size * 2;
    //   border-top-right-radius: $size * 2;
    //   background: #f8f6f1;
    //   display: inline-block;
    //   position: relative;

    //   .signMainbox {
    //     position: relative;
    //     &::before {
    //       content: "";
    //       position: absolute;
    //       height: 1.2rem;
    //       width: 1.2rem;
    //       border-radius: 100%;
    //       background: #25cae7;
    //       left: 12rem;
    //       top: 3rem;
    //     }
    //     &::after {
    //       content: "";
    //       position: absolute;
    //       height: 1rem;
    //       width: 1rem;
    //       border-radius: 100%;
    //       background: #887419;
    //       right: 0.5rem;
    //       bottom: 1rem;
    //       z-index: 2;
    //     }

    //     @include bp(sm) {
    //       p {
    //         font-size: 0.875rem;
    //       }
    //     }
    //   }

    //   h2 {
    //     font-family: $font-family;
    //     font-size: 2rem;
    //     margin-top: 1rem;
    //     margin-bottom: 1rem;
    //   }
    //   .blueButton {
    //     background: #5196f4;
    //     color: $white;
    //     font-size: 1.125rem;
    //     min-height: 40px;
    //     min-width: 47%;
    //     margin-bottom: 8px;
    //     @include bp(md) {
    //       margin-bottom: 0px;
    //     }
    //   }
    // }

    .formMainBox {
      box-sizing: border-box;
      padding-bottom: 2rem;
      background: $white;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      margin: 2rem auto;
      min-height: 460px;

      .heading {
        font-size: larger;
        font-weight: 900;
        text-wrap-mode: nowrap;
        text-align: center;
        margin-bottom: 1rem;
        font-family: $font-family;
        color: #064e89;
        @include bp(md) {
          margin-top: 1rem;
        }
      }

      .check .MuiIconButton-label {
        margin-left: 10px;
      }

      .check .MuiSvgIcon-root {
        fill: #064e89;
      }

      .MuiButton-label {
        font-size: 16px;
      }

      .MuiTypography-body1 {
        font-size: 14px;
      }

      .MuiInput-root .MuiButton-label {
        font-size: 8px;
      }

      .kep-login-facebook {
        padding: 0.5rem;
        font-size: 0.875rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
          rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        float: right;
        width: 100%;
      }

      .orBox {
        font-family: $font-family;
      }

      .googleBox {
        width: 100%;
      }

      @include bp(md) {
        margin-top: 0;
        // height: auto;

        .MuiGrid-grid-xs-3 {
          flex-basis: auto;
        }

        .heading {
          text-wrap-mode: wrap;
        }
      }

      .MuiInput-underline.Mui-disabled:before {
        border-bottom-style: solid;
      }

      // .inputPin {}

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        // -webkit-text-fill-color: green;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        border-radius: 0px;
        border-bottom: 0.5px solid #5196f4;
        transition: background-color 5000s ease-in-out 0s;
        background-color: none;
      }
    }

    .tabBox {
      padding-right: 2rem;
    }

    .PrivateTabIndicator-colorSecondary-13 {
      background: none;
    }

    .MuiTabs-scroller {
      position: absolute;
      bottom: 0rem;
    }

    .MuiTabs-flexContainer {
      justify-content: space-between;
      padding: 5px 3px 0rem;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #5196f4;
    }

    .MuiCheckbox-colorSecondary {
      &:hover {
        background: none;
      }

      &.Mui-checked {
        color: #5196f4;
      }
    }

    .MuiInputAdornment-root {
      display: none;
    }

    .MuiCheckbox-root {
      padding-left: 0rem;
    }

    .MuiButton-containedPrimary {
      background: #064e89;
      border-radius: 0rem;
      min-height: 40px;
      box-shadow: none;
      border-radius: 5px;
      font-size: 1.125rem;

      &:hover {
        background: $blue;
      }
    }

    .MuiButton-outlinedPrimary {
      border: 1px solid #064e89;
      border-radius: 0rem;
      min-height: 40px;
      box-shadow: none;
      border-radius: 5px;
      font-size: 1.125rem;
    }
  }

  .passwordBox {
    h6 {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    .small-screen-input {
      padding: 13px;
      font-size: 30px;
      /* Adjust as needed */
    }
  }

  .MuiOutlinedInput-input {
    padding: 0px 0px;
    font-size: 30px;
    text-align: center;
  }

  @include bp(md) {
    margin-top: 2rem;

    .mainBox {
      padding: 1.7rem 1.7rem;
      margin: 0;
    }

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    .outLine {
      &::before {
        width: 100%;
        left: 0rem;
        display: none;
      }

      &::after {
        display: none;
      }
    }

    .mainBox {
      &.outLine {
        padding-left: 1rem;
      }

      .tabBox {
        padding-left: 1.4rem;

        .formMainBox {
          padding: 0rem 15px 2rem 15px;
          margin-top: 0rem;
        }
      }

      .top {
        width: 100%;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        .signMainbox {
          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}