@import "global";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,800;1,300&display=swap");
.aboutBox {
  background-image: url(../images/webinar-bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 5rem;
  @include bp(md) {
    padding-top: 1rem;
  }
  img {
    margin: 10px auto auto 10px;

    width: 80%;
    height: 80%;
    @include bp(md) {
      width: 40%;
      height: 70%;
    }
  }

  .speakerInfo {

    h4 {
      margin-left: 2.5rem;
      font-size: 1rem;
    }
  }
}

.register {
  .CheckButton1 {
    font-size: 1.3125rem;
    text-transform: uppercase;
    background: #254afa;
    padding: 0.7rem 2rem;
    border-radius: 35px;
    font-family: "gotham rounded medium";
    margin: 2rem 2rem 5rem;
    display: table;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.2);
    color: $white;

    &:hover {
      background: $blue;
      color: $white;
    }

    @include bp(md) {
      margin-top: 0rem;
      margin-bottom: 1rem;
      margin-left: 0rem;
      margin-right: 0.5rem;
    }

    @include bp(sm) {
      margin-top: 0rem;
      margin-bottom: 1rem;
      margin-left: 0rem;
      margin-right: 0rem;
      min-width: 18rem;
    }
  }
}

.joinWebinar {
  background-color: #0e3ff4;
  position: relative;
  background-image: linear-gradient(90deg, #9a00ff 0%, #1483ff 100%);
  padding-top: 17rem;
  padding-bottom: 26rem;

  @include bp(md) {
    padding-top: 12rem;
    padding-bottom: 22rem;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTI4MCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyODAgMTAwOyIgIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMHB4Ij4KICA8ZyBmaWxsPSIjZmZmZmZmIj4gCjxwYXRoICBkPSJNMCw4Mi43YzAsMCwyODAuMyw1MCw2MTItMzEuM3M2NjgsMCw2NjgsMFYwSDBWODIuN3oiLz4KICA8L2c+Cjwvc3ZnPg==);
    height: 150px;
    background-size: 100% 150px;
    z-index: 0;
    top: 0rem;
    width: 100%;
    background-position: top -0.2px right -0.1px;
  }
  &::after {
    content: "";
    position: absolute;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTI4MCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyODAgMTAwOyIgIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMHB4Ij4KICA8ZyBmaWxsPSIjZmZmZmZmIj4gCjxwYXRoICBkPSJNMCw4Mi43YzAsMCwyODAuMyw1MCw2MTItMzEuM3M2NjgsMCw2NjgsMFYwSDBWODIuN3oiLz4KICA8L2c+Cjwvc3ZnPg==);
    height: 100px;
    background-size: 100% 100px;
    z-index: 0;
    width: 100%;
    bottom: 0rem;
    bottom: -1px;
    transform: rotateX(180deg);
  }

  .webinarBox {
    * {
      color: $white;
    }
    h1 {
      font-size: 3rem;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      margin-bottom: 2.5rem;

      @include bp(md) {
        font-size: 2rem;
      }
    }
    .iconCheck {
      background: #2ecc71;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      padding: 1rem;
      position: relative;
      margin-right: 1.25rem;
      .check {
        top: 0rem;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;
        margin: auto;
        /* background: green; */
        border-radius: 100%;
        /* padding: 1rem; */
        width: 20px;
        height: 20px;
        /* font-size: 0.5rem; */
        position: absolute;
        text-align: center;
      }
    }

    .d-flex {
      margin-top: 3.5rem;
      @include bp(md) {
        margin-top: 0.5rem;
      }
    }
    h3 {
      font-size: 1.25rem;
      font-family: $font-family;
      font-weight: bold;
    }
  }
}
.upcomingWebMain {
  background-image: url(../images/webinar-bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 1rem;
  margin-top:80px;
  @include bp(md) {
    padding-top: 1rem;
  }
  .table {
    width: 100%;
    // margin-top: 1rem;
    margin-left: -1rem;
    // font-family: $font-family;
    font-size: 1.2rem;
    @include bp(md) {
      font-size: 0.8rem;

      margin-left: 0rem;
    }
  }

  td {
    border: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    // margin-bottom: 1rem;
    padding-top: 0.5rem;
    // font-weight: 500;
    text-align: left;
    // line-height: 1;
    img {
      width: 30px;
    }
    svg {
      font-size: 30px;
    }
    @media (min-width: 300px) and (max-width: 320px) {
      font-size: 0.6rem;
      img {
        width: 15px;
      }
      svg {
        font-size: 15px;
      }
    }
    @media (min-width: 321px) and (max-width: 400px) {
      font-size: 0.7rem;
      img {
        width: 18px;
      }
      svg {
        font-size: 18px;
      }
    }
    @media (min-width: 401px) and (max-width: 750px) {
      font-size: 0.8rem;
      img {
        width: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    @media (min-width: 950px) and (max-width: 1050px) {
      font-size: 1rem;

      img {
        width: 25px;
      }
      svg {
        font-size: 25px;
      }
    }
  }

  .fixed-content {
    font-size: 10px;
  }
  .secondcard {
    border: none;
    width: 50%;
    @include bp(md) {
      width: 100%;
    }
  }
  h1 {
    background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 2.625rem;
    font-weight: 700;
    padding-top: 0.5rem;

    @include bp(md) {
      font-size: 2rem;
      margin-top: 1rem;
      text-align: center;
      font-size: 1.5rem;
    }

    @include bp(sm) {
      margin-top: 1rem;
    }
  }
  .speakerName {
    @include bp(md) {
      text-align: center;
    }
  }
  h5 {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    font-weight: 500;
  }
  h4,
  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;

    max-width: 90%;
    text-align: justify;
    // font-family: Poppins;
    @include bp(md) {
      max-width: 100%;
    }
    @media (min-width: 300px) and (max-width: 750px) {
      font-size: 1rem;
    }
  }
  p a {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;

    max-width: 90%;
    text-align: justify;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0rem;
  }

  ul {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    height: 15px;
    margin: 1rem 0;
    @include bp(md) {
      display: flex;
      justify-content: center;
    }
    li {
      width: 15px;
      height: 2px;
      background: #056aff;
      position: relative;
      display: inline-block;
      margin-left: -5px;

      &:nth-child(odd) {
        transform: rotate(-45deg);
        &::before {
          left: 5px;
        }
      }

      &:nth-child(even) {
        transform: rotate(45deg);
        &::before {
          right: 5px;
        }

        &::after {
          left: 5px;
        }
      }

      &::before {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        top: -5px;
        background: #056aff;
      }

      &::after {
        content: "";
        width: 15px;
        height: 2px;
        position: absolute;
        // top: -5px;
        // background: #056aff;
      }
    }
  }
  .svgBox {
    // background-image: linear-gradient(90deg, #00d7ff 0%, #5f00ff 100%);
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .webVideo {
    .player-wrapper {
      position: relative;
      height: 100%;
      padding-top: 60.25%;

      overflow: hidden;

      @include bp(sm) {
        padding-top: 70.25%;
      }
    }
    .register {
      span {
        svg {
          margin-left: 0.2rem;
          font-size: 1.4rem;
          color: rgb(88, 198, 88);
          font-weight: bold;
        }
      }
      @media (min-width: 300px) and (max-width: 750px) {
        button {
          font-size: 0.7rem;
          span {
            svg {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    // img {
    //   width: 30%;
    // }
  }
  .timeBox {
    padding-bottom: 5rem;
    @include bp(md) {
      padding-bottom: 2rem;
    }
    * {
      font-family: $font-family;
    }
    [class*="col-"] {
      padding-top: 5rem;
      @include bp(md) {
        padding-top: 2rem;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: #000;
      font-weight: bold;
      margin-top: 3.75rem;
      margin-bottom: 1.5625rem;
      @include bp(md) {
        margin-top: 2rem;
      }
    }
    h4 {
      color: #39374e;
      font-size: 1.125rem;
    }
  }
}
.buttonContainer {
  margin-top: 4rem;
  // border: 1px solid black;
  .action {
    display: flex;
    justify-content: space-between;
  }
  .bookmark {
    margin-top: -1rem;

    // border: 3px solid #064e89;
    color: #064e89;
    font-size: 0.2rem;
    padding: 8px;

    @media (min-width: 300px) and (max-width: 400px) {
      padding: 1px;
    }
    @media (min-width: 401px) and (max-width: 750px) {
      padding: 3px;
    }
    @media (min-width: 751px) and (max-width: 900px) {
      padding: 4px;
      margin-top: 0rem;
    }
  }
  .shareSection {
    margin-top: -2.1rem;
  }
  .back {
    margin-top: 3rem;
    @include bp(md) {
      margin-top: 3rem;
      font-size: 0.7rem;
    }
  }
}

.Testimonial.speakBox {
  margin-top: -20rem;
  h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin-bottom: 2.5rem;
    color: $white;
    @include bp(md) {
      font-size: 2rem;
      margin-bottom: 0rem;
    }
  }
  .card-deck {
    margin-right: -30px;
    margin-left: -30px;
    .card {
      margin-right: 30px;
      margin-left: 30px;
      border-radius: 8%;
      margin-top: 2rem;
      box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.15);
      @include bp(md) {
        margin-left: inherit;
        margin-right: inherit;
      }
      img {
        margin-top: 1.5rem;
        height: 80px;
        width: 80px;
        border-radius: 100%;
      }
      .card-title {
        font-size: 1rem;
      }
      .card-body {
        padding: 2.25rem;
        .card-text {
          color: rgb(161, 161, 161);
        }
      }
      .list-inline-item {
        margin-right: 0rem;
        svg {
          color: #ffd51f;
          font-size: 1rem;
        }
      }
    }

    @include bp(md) {
      flex-direction: column;

      width: 75%;
      margin: 0 auto;
      @include bp(sm) {
        width: 100%;
      }
    }
  }
}
.webinar {
  .CheckButtonWebinar {
    * {
      color: $white;
    }
    margin-top: 40px;

    font-size: 2.0625rem;
    border-radius: 45px;
    padding: 0.7rem 3rem;
    text-transform: capitalize;
    background: #035eab;
    padding: 0.7rem 2rem;
    border-radius: 35px;
    font-family: "gotham rounded medium";
    margin: 2rem auto 5rem auto;
    display: table;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.2);

    &:hover {
      background: $light-blue;
    }

    @include bp(md) {
      padding: 0.2rem 1rem;
      font-size: 0.875rem;
      margin-top: 0rem;
    }
  }
}

// .TestBox{
//     .webImage{
//         width:180px;
//         height:180px;
//         border-radius:100%;
//         img{
//             width:100%;
//             height:100%;
//         }
//     }
// }


.register-webinar-detailpage-modal-close-btn{
  display: none !important;
}

@media (min-width: 320px) and (max-width: 500px) {
  .register-webinar-detailpage-modal-head{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .register-webinar-detailpage-modal-close-btn{
    width: 24px !important;
    height: 17px !important;
    display: flex !important;
    margin-right: 10px !important;
  }
}