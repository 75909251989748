@import "global";

.MuiAppBar-colorPrimary.customBar1 {
  background: url(../images/wave.png) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-position: 50% 50%;
  padding-bottom: 1rem;
  transition: 0.7s;
  box-shadow: none;
  margin-bottom: -15px;
  background:white;

  //  box-shadow:none;
  .logoBox {
    margin-top: 1rem;
  }

  // background:none; box-shadow:none;
  @include bp(md) {
    background: none;
    box-shadow: none;
    .logoBox {
      max-width: 7rem;
      width: auto;
      /* margin-left: 4rem; */
      position: absolute;
      left: 0rem;
      right: 0rem;
      /* width: 100%; */
      margin: 0 auto;
      display: table;
      top: 0.7rem;
      z-index: 1;
    }
  }
  @include bp(md) {
    background: none;
    box-shadow: none;
    .logoBoxMobile {
      max-width: 7rem;
      width: auto;
      /* margin-left: 4rem; */
      position: absolute;
      left: 0rem;
      // right: 0rem;
      /* width: 100%; */
      margin: 0 auto;
      display: table;
      top: 0.7rem;
      z-index: 1;
    }
  }

  .MuiTypography-h3 {
    text-transform: uppercase;
    color: $white;
    font-family: "gotham rounded medium";
    font-size: 2.5rem;
    margin-left: 6rem;

    @include bp(sm) {
      display: block;
      margin-left: 0rem;
      font-size: 1.5rem;
    }
  }
  .MuiToolbar-regular {
    // margin-top:1rem;
    // margin-bottom:10px;
    @include bp(sm) {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
  .MuiButton-root {
    color: $blue;
    background: $white;
    border-radius: 2rem;
    // margin-left:1rem;
    padding: 0rem 1rem;
    font-weight: 600;
    font-family: "gotham rounded medium";
    height: 40px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-top: 1.4rem;
    // width: 90px;
    @include bp(md) {
      min-width: auto;
      width: 30px;
      height: 30px;
      margin-top: 0.5rem;
      svg {
        font-size: 1rem;
      }
    }
  }
  .notificationBox {
    background: $white;
    border-radius: 100%;
    margin-left: 0.5rem;
    width: 40px;
    height: 40px;
    color: $blue;
    margin-top: 1.4rem;
    line-height: 30px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .MuiIconButton-root {
      padding: 8px;
    }
    .notificationPaper {
      position: absolute;
      right: 0;
      top: 70px;
      width: 500px;
      // height: 500px;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: auto;
      max-height: 500px;
      .eventContainer {
        cursor: pointer;
        display: flex;
        .eventIcon {
          margin-right: 0.5rem;
          svg {
            color: #064e89;
            font-size: 1.8rem;
          }
        }
      }
      .eventInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        h4,
        p {
          font-family: $font-family;
          font-size: 1rem;
        }

        .updates {
          display: flex;
          justify-content: flex-end;
          margin-top: auto;
          span {
            font-family: $font-family;
            color: rgb(185, 184, 184);
            text-align: end;
            font-size: 0.8rem;
          }
        }
      }
      //   .updates {
      //     display: flex;
      //     justify-content: flex-end;
      //     margin-top: -0.8rem;
      //     border: 1px solid black;
      //     flex: content;
      //     span {
      //       font-family: $font-family;
      //       color: rgb(185, 184, 184);
      //       text-align: end;
      //       font-size: 0.8rem;
      //     }
      //   }
      @media (min-width: 300px) and (max-width: 400px) {
        top: 55px;
        width: 290px;
        .eventContainer {
          .eventIcon {
            svg {
              font-size: 1.5rem;
            }
          }
        }
        .eventInfo {
          h4,
          p {
            font-size: 0.8rem;
          }
          h4 {
            margin-top: 0.2rem;
          }
          .updates {
            span {
              margin-top: -0.3rem;
              font-size: 0.6rem;
            }
          }
        }
      }
      @media (min-width: 400px) and (max-width: 500px) {
        top: 55px;
        width: 310px;
        .eventContainer {
          .eventIcon {
            svg {
              font-size: 1.5rem;
            }
          }
        }
        .eventInfo {
          h4,
          p {
            font-size: 0.9rem;
          }
          h4 {
            margin-top: 0.2rem;
          }
          .updates {
            span {
              margin-top: -0.3rem;
              font-size: 0.7rem;
            }
          }
        }
      }
      @media (min-width: 500px) and (max-width: 600px) {
        top: 55px;
        width: 400px;
      }
    }
    // .MuiBadge-colorSecondary{background:$blue;}
    @include bp(md) {
      width: 30px;
      height: 30px;
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      svg {
        font-size: 1rem;
      }
      .MuiBadge-badge {
        min-width: 15px;
        height: 15px;
        font-size: 0.5rem;
      }
    }
  }
  .callBox {
    background: $blue;
    color: $white;
    border-radius: 100%;
    padding: 5px;
    font-size: 1.5rem;
  }
}

.profileMenu {
  .MuiPopover-paper {
    border-radius: 20px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  top: 50px !important;
  font-weight: bold;
  font-family: $font-family;
  li {
    font-family: $font-family;
    .exitIcon {
      color: $blue;
    }
  }
  .logoutImg{
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}

.profileMobileMenu {
  top: 40px !important ;
  .MuiButton-root {
    color: $blue;
    background: $white;
    border-radius: 2rem;
    margin-left: 0rem;
    padding: 0rem 1.5rem;
    font-weight: 600;
    font-family: "gotham rounded medium";
    height: 40px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .notificationBox {
    background: white;
    border-radius: 100%;
    margin-left: 0rem;
    width: 40px;
    height: 40px;
    color: $blue;
    line-height: 30px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .MuiIconButton-root {
      padding: 8px;
    }
    // .MuiBadge-colorSecondary{background:$blue;}
  }
}

.mobileSticky {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 1020  !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  @include bp(lg) {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1020;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background: $white;
  }
}
