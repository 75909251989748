@import "global";

.newsShare {
  // @include bp(md) {
  //   max-width: 120px;
  // }
}
.sharebutton {
  margin-left: 5px;
  // @include bp(md) {
  //   font-size: 1px;
  //   margin-left: 1px;
  // }
  @media (min-width: 300px) and (max-width: 330px) {
    margin-left: 2px;

    button {
      svg {
        width: 21px;
        font-size: 0.5rem;
      }
    }
  }
  @media (min-width: 331px) and (max-width: 365px) {
    margin-left: 2px;

    button {
      svg {
        width: 23px;
        font-size: 0.5rem;
      }
    }
  }
  @media (min-width: 366px) and (max-width: 400px) {
    margin-left: 2px;

    button {
      svg {
        width: 26px;
        font-size: 0.5rem;
      }
    }
  }
  @media (min-width: 401px) and (max-width: 450px) {
    margin-left: 2px;

    button {
      svg {
        width: 28px;
        font-size: 0.2rem;
      }
    }
  }
  @media (min-width: 351px) and (max-width: 350px) {
    margin-left: 2px;
    width: 20px;
  }
}
