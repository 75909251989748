@import "global";
.BannerBox.newsBanner {
  .sixteen-nine:before {
    //background:url(../images/articles.png) center center no-repeat;
    background: url(../images/news-banner.jpg) center center no-repeat;
    //padding-top: 39.25%;
    padding-top: 23%;
    background-size: cover;
  }
}
.articleNews {
  h2 {
    margin-top: 1rem;
    font-family: $font-family;
    color: #064e89;
    @include bp(md) {
      font-size: 1.5rem;
    }
  }
  .card {
    // box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
    overflow: visible;
    height: 100%;
    margin-bottom: 1rem;
    border: none;
    background: transparent;
    // @media (min-width: 300px) and (max-width: 349px) {
    //   width: 100%;
    // }
    // @media (min-width: 350px) and (max-width: 380px) {
    //   width: 100%;
    // }

    // @media (min-width: 381px) and (max-width: 400px) {
    //   width: 75%;
    // }
    // @media (min-width: 401px) and (max-width: 450px) {
    //   width: 100%;
    // }
    .mainInfo {
      display: flex;
      justify-content: space-between;

      position: relative;
      .mainImage {
        width: 160px;
        height: 120px;
        img {
          //   border-radius: 5%;
          height: auto;
          height: 100%;
          width: 100%;
        }
        @media (min-width: 300px) and (max-width: 349px) {
          img {
            height: 60%;
            width: 60%;
            // left: -28%;
          }
        }
        @media (min-width: 350px) and (max-width: 400px) {
          img {
            height: 70%;
            width: 70%;
            // left: -22%;
          }
        }
        @media (min-width: 400px) and (max-width: 700px) {
          img {
            height: 75%;
            width: 75%;
            // left: -22%;
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 0.5rem;
        // margin-top: -2rem;
        .topic {
          height: 5rem;
          display: flex;

          flex: 3;
          h4 {
            font-family: $font-family;
            font-size: 1rem;
            text-align: justify;
            font-weight: bolder;

            span {
              font-size: 0.8rem;
              color: #064e89;
            }
          }
        }
        @media (min-width: 300px) and (max-width: 349px) {
          margin-left: -3.5rem;
          max-width: 110%;
          // border: 1px solid black;
          .topic {
            flex: 0.9;

            h4 {
              font-size: 0.6rem;
              span {
                font-size: 0.6rem;
              }
            }
          }
        }
        @media (min-width: 350px) and (max-width: 400px) {
          margin-left: -2.5rem;
          max-width: 110%;
          // border: 1px solid black;
          .topic {
            flex: 1.2;
            h4 {
              font-size: 0.7rem;
              span {
                font-size: 0.5rem;
              }
            }
          }
        }
        @media (min-width: 400px) and (max-width: 700px) {
          margin-left: -2rem;
          max-width: 110%;
          // border: 1px solid black;
          .topic {
            flex: 1.4;
            h4 {
              font-size: 0.75rem;
              span {
                font-size: 0.6rem;
              }
            }
          }
        }
        .date {
          flex: 1;
          //   position: absolute;
          h4 {
            font-family: $font-family;
            font-size: 1rem;
            color: gray;
            @media (min-width: 300px) and (max-width: 349px) {
              font-size: 0.5rem;
            }
            @media (min-width: 350px) and (max-width: 400px) {
              font-size: 0.6rem;
            }
            @media (min-width: 401px) and (max-width: 700px) {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }
}
