@import "global";

.BannerBox.circularBanner {
  .sixteen-nine {
    &::before {
      background: url(../images/govt-circular-banner.jpg) center center
        no-repeat;
      //padding-top: 39.25%;
      padding-top: 23%;
      background-size: cover;
    }
  }
}
.mainCircularSection {
  .container {
    max-width: 98%;
    // border: 1px solid black;
    @include bp(md) {
      max-width: none;
    }
  }
  h1 {
    margin-top: 1rem;
    margin-bottom: -0.5rem;
    font-family: $font-family;
    color: #064e89;
    font-size: 2rem;
  }
  .grYearFilter {
    // margin-right: 0.1rem;
    // // margin-left: 1rem;
    margin-left: 2.8rem;
    span {
      margin-left: 2.5rem;
    }
  }
  .grLanguageFilter {
    // margin-left: 0.6rem;
    .langSvg {
      font-size: 1.8rem;
    }
    span {
      margin-left: 0.1rem;
    }
  }
}
.circularSection {
  .container {
    // border: 1px solid black;
    min-width: 20%;
    @include bp(md) {
      max-width: none;
    }
  }
}
.circularMain {
  .row {
    // display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    @include bp(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .custom-col-spacing {
    margin-bottom: -1rem;
    @include bp(md) {
      margin-bottom: 1rem;
    }
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    // border: 1px solid black;
    padding-right: 18px;
    padding-left: 3.5rem;
    margin-top: 4rem;
    @include bp(md) {
      padding-right: 15px;
      padding-left: 30px;
      margin-top: 2rem;
      margin-left: 5rem;
    }
  }

  .card {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    overflow: visible; /* Ensure the image can overflow */
    height: 100%;
    display: flex;
    flex-direction: column;
    // width: 95%;
    // @media (min-width: 300px) and (max-width: 349px) {
    //   width: 100%;
    // }
    // @media (min-width: 350px) and (max-width: 380px) {
    //   width: 100%;
    // }

    // @media (min-width: 381px) and (max-width: 400px) {
    //   width: 75%;
    // }
    // @media (min-width: 401px) and (max-width: 450px) {
    //   width: 100%;
    // }
  }

  .card-body {
    display: flex;
    align-items: flex-start;
  }

  .mainImage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    width: 140px;
    height: 140px;
    img {
      border: 3px solid #064e89;
      border-radius: 5%;
      height: auto; /* Adjust as needed */
      height: 110%;
      width: 110%;
      object-fit: cover;
      position: relative;
      transform: translate(0, 1%);
      left: -30%; /* Move image 20% to the left */
    }
    @media (min-width: 300px) and (max-width: 349px) {
      img {
        height: 75%;
        width: 65%;
        left: -28%;
      }
    }
    @media (min-width: 350px) and (max-width: 400px) {
      img {
        height: 80%;
        width: 75%;
        // left: -22%;
      }
    }
    @media (min-width: 400px) and (max-width: 700px) {
      img {
        height: 85%;
        width: 85%;
        // left: -22%;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: -1.8rem;
    .topic {
      min-height: 6rem;
      display: flex;
      flex: 3;
      // border: 1px solid black;
      h4 {
        font-family: $font-family;
        font-size: 1.1rem;
        color: #064e89;
        font-weight: bold;
        height: 2rem !important;
      }
    }
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -4.8rem;

      .topic {
        min-height: 4rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          // text-overflow: ellipsis;
          font-size: 0.7rem;
          flex: 2;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          height: 2rem !important;
        }
      }
    }
    @media (min-width: 350px) and (max-width: 400px) {
      margin-left: -4rem;

      .topic {
        min-height: 4.3rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 0.8rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          height: 2rem !important;
        }
      }
    }
    @media (min-width: 400px) and (max-width: 500px) {
      margin-left: -3rem;

      // border: 1px solid black;
      .topic {
        min-height: 4.5rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 0.87rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          height: 2rem !important;
        }
      }
    }
    @media (min-width: 501px) and (max-width: 700px) {
      margin-left: -3rem;

      // border: 1px solid black;
      .topic {
        min-height: 4.5rem;
        max-width: 100% !important;
        word-break: break-word !important;
        h4 {
          font-size: 1rem;
          max-width: 100% !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          height: 2rem !important;
        }
      }
    }
    .date {
      // margin-top: 1rem;
      h4 {
        font-family: $font-family;
        font-size: 1rem;
        color: gray;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.5rem;
        }
        @media (min-width: 350px) and (max-width: 400px) {
          font-size: 0.6rem;
        }
        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 0.7rem;
        }
        @media (min-width: 501px) and (max-width: 700px) {
          font-size: 0.8rem;
        }
      }
    }
  }
  .actionGrMain {
    display: flex;
    //   justify-content: space-between;
    align-items: center;
    margin-left: 8.2rem;
    margin-top: -4.52rem;
    margin-bottom: 2.5em;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-bottom: 2em;
      margin-top: -7.1rem;
      margin-left: 5.2rem;
    }
    @media (min-width: 350px) and (max-width: 400px) {
      margin-bottom: 2em;
      margin-top: -6.9rem;
      margin-left: 6rem;
    }
    @media (min-width: 400px) and (max-width: 700px) {
      margin-bottom: 2em;
      margin-top: -6.5rem;
      margin-left: 7rem;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      @media (min-width: 350px) and (max-width: 400px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      // @media (min-width: 371px) and (max-width: 400px) {
      //   padding-top: 0.38rem;
      //   padding-bottom: 0.38rem;
      //   padding-left: 0.5rem;
      //   padding-right: 0.5rem;
      //   font-size: 0.54rem;
      // }
      @media (min-width: 750px) and (max-width: 900px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      @media (min-width: 501px) and (max-width: 700px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.58rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;

      padding: 1.5px;
      margin-left: 21vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 330px) {
        padding: 0rem;
        margin-left: 4.5rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      @media (min-width: 331px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 361px) {
        padding: 0rem;
        margin-left: 5.5rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }
      @media (min-width: 362px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 6.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        margin-left: 29rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        margin-left: 36rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: 9.5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        padding: 0rem;
        margin-left: 7rem;
        font-size: 0rem;
        margin-top: -0.1rem;
        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
      @media (min-width: 501px) and (max-width: 700px) {
        padding: 0rem;
        margin-left: 14.5rem;
        font-size: 0rem;
        margin-top: -0.1rem;
        span {
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
