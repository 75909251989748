@import "global";

.Newsletter {
  margin-top: 5rem;
  background-image: linear-gradient(-90deg, #3933e9, #4c6ce8);
  padding-top: 1rem;
  padding-bottom: 1rem;
  // color:$white;
  @include bp(md) {
    margin-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  h3 {
    color: $white;
    font-family: $font-family;
    font-size: 1.5625rem;
  }
  p {
    font-family: $font-family;
  }
  .CustomForm {
    position: relative;
    .TextBox {
      min-width: 28rem;
      margin-top: 0.7rem;
      @include bp(md) {
        //  min-width:75%;
        min-width: 60%;
      }

      .MuiFilledInput-root {
        border-radius: 40px 0px 0px 40px;
        border: none;
        background: #ebebeb;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      .MuiFilledInput-input {
        padding-left: 29px;
      }
      .MuiFormLabel-root {
        color: #2f2f2f;
        margin-left: 1rem;
        font-weight: bold;
      }
      .MuiFilledInput-input {
        padding-top: 30px;
      }
    }

    .MuiButton-contained {
      position: absolute;
      z-index: 2;
      left: 22rem;
      box-shadow: none;
      background: white;
      border-radius: 40px;
      /* padding: 1rem; */
      padding: 0rem 2rem;
      height: 59px;
      margin-top: 0.7rem;
      font-weight: 600;

      @include bp(md) {
        left: 9.5rem;
      }
    }
  }
}

.FooterBox {
  background: url("../images/footer.jpg") no-repeat;
  background-size: cover;
  padding: 0rem 0rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
  // color:$white;
  h4 {
    color: $white;
    font-family: $font-family;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-size: 1.3125rem;
    @include bp(md) {
      font-size: 1rem;
    }
  }
  .nav-link {
    color: $white;
    padding-left: 0rem;
    font-family: $font-family;
    &:hover {
      color: $white;
    }
  }
  .MuiFormControl-root {
    background: $white;
    margin-top: 0.5rem;
    height: 45px;
    display: flex;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiInputLabel-formControl {
      top: -4px;
      color: #414042;
      font-family: $font-family;
    }
    .MuiInputLabel-formControl.Mui-focused {
      top: 7px;
    }
    .MuiInputLabel-formControl.MuiFormLabel-filled {
      top: 7px;
    }
  }
  .submitBox {
    box-shadow: none;
    background: #f26522;
    margin-top: 0.5rem;
    border-radius: 0px;
    display: block;
    font-family: $font-family;
  }
  .socialContainer {
    h4 {
      @include bp(md) {
        margin-top: -1rem;
        text-align: center;
      }
    }
    @include bp(md) {
      margin-left: -1rem;
    }
  }
  .navneetLogo {
    display: flex;
    @include bp(md) {
      justify-content: center;
      margin-left: 1rem;
    }
    .firstLogo {
      max-width: 40%;
      margin-bottom: 0.8rem;
      img {
        width: 90%;
      }
      @include bp(md) {
        max-width: 30%;

        img {
          width: 90%;
        }
      }
    }
  }
  .SocialBox {
    display: flex;

    align-items: center;
    @include bp(md) {
      text-align: center;
      justify-content: center;
    }
    .facebook {
      width: 40px;
      height: 40px;
      background: white;
      color: $blue;
      margin-right: 0.5rem;
      @include bp(md) {
        width: 35 px;
        height: 35px;

        svg {
          font-size: 1.5rem;
        }
      }
    }
    .twitter {
      width: 40px;
      height: 40px;
      background: white;
      color: $blue;
      margin-right: 0.5rem;
      @include bp(md) {
        width: 35 px;
        height: 35px;

        svg {
          font-size: 1.5rem;
        }
      }
    }
    .instagram {
      width: 40px;
      height: 40px;
      background: white;
      color: $blue;
      margin-right: 0.5rem;
      @include bp(md) {
        width: 35 px;
        height: 35px;

        svg {
          font-size: 1.5rem;
        }
      }
    }
    .linkedIn {
      width: 40px;
      height: 40px;
      background: white;
      color: $blue;
      @include bp(md) {
        width: 35 px;
        height: 35px;

        svg {
          font-size: 1.5rem;
        }
      }
    }

    // button {
    //   background: white;
    //   padding: 2px;

    //   margin-right: 10px;
    //   svg {
    //     font-size: 1.4rem;
    //   }
    //   @media (min-width: 300px) and (max-width: 400px) {
    //     padding-top: 1px;
    //     padding-bottom: 1px;
    //     padding-left: 2px;
    //     padding-right: 2px;
    //     width: 8%;
    //     svg {
    //       font-size: 0.9rem;
    //       margin-bottom: 0.3rem;
    //     }
    //   }
    //   @media (min-width: 401px) and (max-width: 700px) {
    //     padding-top: 1px;
    //     padding-bottom: 1px;
    //     padding-left: 2px;
    //     padding-right: 2px;
    //     width: 8%;
    //     svg {
    //       font-size: 1rem;
    //       margin-bottom: 0.2rem;
    //     }
    //   }
    // }
  }
  .navneetLogo {
    display: flex;
    @include bp(md) {
      justify-content: center;
    }

    .secondLogo {
      max-width: 40%;
      img {
        margin-right: 3rem;

        width: 100%;
      }
      @include bp(md) {
        max-width: 30%;
        img {
          width: 90%;
        }
      }
    }
  }
  .quickLinkGrid {
    @include bp(md) {
    }
  }
  .linkContainer {
    @include bp(md) {
      margin-left: 1rem;
    }
  }
  .helpGrid {
    margin-top: 0.6rem;
    @include bp(md) {
      margin-left: 0rem;
    }
  }
  .quickLinks {
    margin-top: -1.8rem;
  }
  .help {
    margin-top: -1.8rem;

    @include bp(md) {
      width: 90%;
    }
  }
  .listItem {
    width: 50%;
    padding: 0;
    color: white;
    cursor: "pointer";
    @include bp(md) {
      width: 70%;
    }
    font-family: $font-family;
    a {
      padding: 1px;
    }
    span {
      padding: 0;
      font-family: $font-family;
      @include bp(md) {
        font-size: 0.71rem;
      }
    }
  }
  .listCollapseItem {
    padding: 1px;
    color: white;
    font-family: $font-family;
    a {
      padding: 0;
      font-family: $font-family;
    }
    span {
      padding: 0;
      font-size: 0.9rem;
      font-family: $font-family;
      @include bp(md) {
        font-size: 0.7rem;
      }
    }
  }
}
.FooterBottom {
  background: #191f5e;
  color: $white;
  padding-top: 1rem;
}
.FooterBottomPwaMob {
  background: #191f5e;
  color: $white;
  padding-top: 1rem;
  padding-bottom: 5rem;
}
.textAreaBox {
  width: 100%;
  &::placeholder {
    color: #414042;
    font-family: $font-family;
  }
}
.textLable {
  #outlined-basic {
    padding-left: 1rem;
    padding-top: 1rem;
    color: #272727;
    font-weight: 400;
    padding-left: 15px;
    padding-top: 0.5rem;
    margin-bottom: -8px;
  }
}

.custom-footer {
  .Newsletter {
    margin-top: 0rem;
  }
}
