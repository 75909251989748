/* WhatsNew.scss */
@import "global";
.whatsnew {
  margin-top: 2rem;
  h2 {
    font-family: $font-family;
    color: #064e89;

    @include bp(md) {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  }
  .row {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-right: 0px !important;
    .button {
      min-width: 20px;
      font-weight: bold;
      margin: 0 4px;
      // border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      background-color: white;
      color: black;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      font-size: 0.7rem;
      font-family: $font-family;
      @media (min-width: 300px) and (max-width: 330px) {
        font-size: 0.5rem;
      }
      @media (min-width: 331px) and (max-width: 500px) {
        font-size: 0.6rem;
      }
    }
    .active {
      background-color: #064e89;
      color: white;
    }
  }
}

.button:hover {
  background-color: #f0f0f0;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 8px; // Add space between buttons
}
