@import "global";

.otherSection {
  h2 {
    font-family: $font-family;
    color: #064e89;
    margin-top: 4rem;
    @include bp(md) {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  }
  .container {
    // border: 1px solid black;
    position: relative;
    @include bp(md) {
      // border: 1px solid black;
    }
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 12px;
    margin-left: 12px;
    // border: 1px solid black;
    @include bp(md) {
      // border: 1px solid black;
    }
  }
  .prevButton {
    position: absolute;
    top: 50;
    left: 0;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -9rem;
    }

    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8.5rem;
      margin-left: 0.2rem;
    }
  }
  .nextButton {
    position: absolute;
    top: 50;
    right: 0;
    margin-top: -10rem;
    color: #064e89;
    background: rgb(234, 232, 232);
    @media (min-width: 300px) and (max-width: 400px) {
      padding: 5px;
      margin-top: -9rem;
    }
    @media (min-width: 401px) and (max-width: 600px) {
      padding: 5px;
      margin-top: -8.5rem;
      margin-right: 0.2rem;
    }
  }
}
.otherWeb {
  .custom-col-spacing {
    margin-bottom: -1rem;
    // @include bp(md) {
    //   margin-bottom: 1rem;
    // }
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1rem;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (min-width: 300px) and (max-width: 349px) {
      padding-right: 16px;
      padding-left: 16px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 350px) and (max-width: 400px) {
      padding-right: 16px;
      padding-left: 16px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 400px) and (max-width: 500px) {
      padding-right: 16px;
      padding-left: 16px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  padding-bottom: 0rem;
  margin-top: 0rem;
  h1 {
    font-family: $font-family;
    color: $h2color;
    font-size: $head-size;
    top: 3rem;
    @include bp(md) {
      font-size: $head-md;
      top: 0rem;
    }
    @include bp(sm) {
      font-size: $head-small;
      top: 3rem;
    }
  }

  .card {
    position: relative;
    padding: 0rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-body {
      height: 100%;
    }
    .Speaker {
      position: relative;
      // border: 1px solid black;
      // background-color: #ececec;
      margin-top: 1.3rem;
      // margin-bottom: 1rem;
      // margin-right: 3rem;
      margin-left: -1.2rem;
      // width: 50%;
      // background-color: #ececec;
      height: 100%;
      @media (min-width: 300px) and (max-width: 349px) {
        margin-left: -0.8rem;
      }

      h4 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        width: 20%;
        font-family: $font-family;
        color: inherit;
        font-weight: 550;
        font-size: 0.8rem;

        @include bp(sm) {
          font-size: 0.8rem;
        }
      }
      .imgBox {
        width: 130px;
        height: 130px;
        // position: absolute;

        @media (min-width: 300px) and (max-width: 349px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 350px) and (max-width: 500px) {
          width: 80px;
          height: 80px;
        }
        @media (min-width: 501px) and (max-width: 750px) {
          width: 100px;
          height: 100px;
        }
        @media (min-width: 751px) and (max-width: 1000px) {
          width: 130px;
          height: 130px;
        }
        @media (min-width: 1001px) and (max-width: 1050px) {
          width: 90px;
          height: 90px;
        }

        margin: 0 auto;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 4px solid #035eab;
        }
      }
      p {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        margin-top: 0.5rem;
        text-align: center;
        font-family: $font-family;
        font-weight: 550;
        height: 100%;
        font-size: 0.9rem;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 349px) {
          font-size: 0.6rem;
        }
        @media (min-width: 350px) and (max-width: 370px) {
          font-size: 0.7rem;
        }
        @media (min-width: 371px) and (max-width: 450px) {
          font-size: 0.7rem;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 0.2rem;
      margin-left: -1.5rem;
      font-family: $font-family;
      font-size: 1.1rem;
      height: 70%;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 1rem;
        margin-left: -0.2rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 1rem;
        margin-left: -0.8rem;
        // border: 1px solid black;
        width: 115%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 1rem;
        margin-left: -1rem;

        width: 115%;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        margin-left: -2rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 1rem;
        margin-left: -1rem;
        // border: 1px solid black;
        width: 110%;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 1.5rem;
        margin-left: -1.1rem;
        // border: 1px solid black;
        width: 110%;
      }
    }

    th {
      font-weight: 100;
    }
    th,
    td {
      vertical-align: top;
      border: none;
      padding: 4px;
      text-align: left;
      line-height: 1.3;
      @media (min-width: 300px) and (max-width: 349px) {
        font-size: 0.7rem;
        line-height: 1;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }

    th:first-child {
      width: 10%;
      padding-right: 5px;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;

        width: 5%;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        // font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        // font-size: 1rem;
        // padding: 4px;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
      }
      @media (min-width: 451px) and (max-width: 550px) {
      }
    }

    // td:first-child {
    //   padding-right: 10px;
    // }

    @include bp(md) {
      font-size: 0.8rem;
    }
    .topic {
      font-size: 1.1rem;
      line-height: 1.2 !important;
      word-break: break-word;
      font-weight: bold;

      height: 3.2em;
      padding-right: 2px !important;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 2px;
        font-size: 0.7rem;
        width: 5%;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        font-size: 0.8rem;
        // padding: 2px;

        // width: 5%;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 401px) and (max-width: 500px) {
        font-size: 0.8rem;
        // font-size: 1rem;
        // padding: 4px;
        max-width: 100px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      @media (min-width: 750px) and (max-width: 900px) {
      }
      @media (min-width: 901px) and (max-width: 1000px) {
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
      }
      @media (min-width: 401px) and (max-width: 450px) {
        font-size: 0.8rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        font-size: 0.8rem;
      }
    }
    // img {position:absolute; z-index:0;}
  }

  .action {
    display: flex;
    //   justify-content: space-between;
    // align-items: center;
    // position: relative;
    // border: 1px solid black;
    margin-left: -1.2rem;
    margin-top: -1rem;
    // margin-bottom: -1.5rem;
    width: 100%;
    @media (min-width: 300px) and (max-width: 349px) {
      margin-left: -0.2rem;
    }
    @media (min-width: 350px) and (max-width: 370px) {
      margin-left: -0.6rem;
    }
    @media (min-width: 371px) and (max-width: 400px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 750px) and (max-width: 900px) {
    }
    @media (min-width: 901px) and (max-width: 1000px) {
      margin-left: -1.6rem;
    }

    @media (min-width: 1001px) and (max-width: 1050px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }

    @media (min-width: 401px) and (max-width: 450px) {
      margin-top: -0.5rem;
      margin-left: -0.8rem;
    }
    @media (min-width: 451px) and (max-width: 550px) {
      margin-top: -0.5rem;
      margin-left: -1rem;
    }
    .readmore {
      border: 1px solid #064e89;
      color: white;
      // font-size: 0.6rem; /* Decrease font size */
      font-family: $font-family;
      background: #064e89;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      @media (min-width: 300px) and (max-width: 349px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 0.43rem;
      }
      @media (min-width: 350px) and (max-width: 370px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 0.48rem;
      }
      @media (min-width: 371px) and (max-width: 400px) {
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 0.5rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 0.5rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 0.5rem;
      }
      @media (min-width: 750px) and (max-width: 900px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.54rem;
      }
      //   @include bp(md) {
      //     padding-top: -1rem;
      //     padding-bottom: -1rem;
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     font-size: 0.4rem;
      //   }
    }
    .bookmark {
      border: 2px solid #064e89;
      // border-radius: 50%;
      // width: 1rem;
      // height: 1rem;
      // position: absolute;
      padding: 1.5px;
      // margin-left: 17vw;
      font-size: 1px;
      margin-top: 0.4rem;
      color: #064e89;
      // margin-left: auto;
      @media (min-width: 300px) and (max-width: 349px) {
        padding: 0rem;
        margin-left: 2.8rem;
        font-size: 0rem;
        margin-top: 0rem;
        span {
          svg {
            font-size: 1.4rem;
          }
        }
      }
      @media (min-width: 350px) and (max-width: 359px) {
        padding: 0rem;
        margin-left: 5rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 360px) and (max-width: 400px) {
        padding: 0rem;
        margin-left: 4rem;
        font-size: 0rem;
        margin-top: 0rem;
      }

      @media (min-width: 750px) and (max-width: 900px) {
        padding: 0rem;
        margin-left: 12rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 901px) and (max-width: 1000px) {
        padding: 0rem;
        margin-left: 10rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1001px) and (max-width: 1050px) {
        padding: 0rem;
        margin-left: -0.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1050px) and (max-width: 1150px) {
        padding: 0rem;
        margin-left: -0.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 1150px) and (max-width: 1250px) {
        padding: 0rem;
        margin-left: -0.2rem;
        font-size: 0rem;
        margin-top: 0rem;
      }
      @media (min-width: 401px) and (max-width: 450px) {
        padding: 0rem;
        margin-left: 5.8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
      @media (min-width: 451px) and (max-width: 550px) {
        padding: 0rem;
        margin-left: 8rem;
        font-size: 0rem;
        margin-top: -0.1rem;
      }
    }
  }
  // .readmore {
  //   margin: -1.2rem auto 4rem auto;
  //   border-radius: 10px;
  //   padding: -0.1rem 2rem;
  //   text-transform: capitalize;
  //   background: transparent;
  //   color: $black;
  //   font-family: "gotham rounded medium";
  //   @include bp(md) {
  //     margin-bottom: 0rem;
  //   }
  // }

  .boxFeedback {
    margin-top: 1rem;
    left: 0;
    right: 0;
    .readmore {
      margin: -2rem auto 4rem auto;
      margin-left: 5rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: white;
      color: $black;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.75rem;
        padding: 0.2rem 1rem;
        margin-left: 2rem;
      }
    }
    .CheckButton {
      margin: -2rem auto 4rem auto;
      margin-right: 3rem;
      border-radius: 35px;
      padding: 0.2rem 2rem;
      background: #035eab;
      color: $white;
      text-transform: capitalize;
      font-family: "gotham rounded medium";
      font-size: 1rem;
      @include bp(md) {
        font-size: 0.8rem;
        padding: 0.2rem 1rem;
        margin-right: 2rem;
      }
    }
  }
}
